import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import * as _ from 'underscore';
import { CommonProvider } from '../../shared/common';
import { Apiurl } from '../../shared/route';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  displayedColumns1: string[] = [
    'type',
    'title',
    'relatedCustomer',
    'relatedTransaction',
    'relatedContact',
    'startDate',
    'startTime',
    'endTime',
    'status',
    'salesRep'
  ];
  mainDataSouce: any = [];
  showFilter: boolean = false;
  @ViewChild(MatSort) sort: any;
  panelOpenState = false;
  dataSource: any;
  todayDate = new Date();
  Type: any;
  Status: any;
  searchText: any = '';
  listLookups: any = [];
  salesRepId = 0;

  constructor(
    private router: Router,
    private commonService: CommonProvider,
    private SpinnerService: NgxSpinnerService
  ) {}
  ngOnInit(): void {
    this.getAllActivityList();
    this.getAllLookups();
  }

  openFilter() {
    this.showFilter = !this.showFilter;
  }

  GoToSubActivity(getType: string) {
    // localStorage.setItem('ActivityType', getType);
    // this.router.navigate(["/sub-activity"]);
    let param: NavigationExtras = {
      state: {
        type: getType
      }
    };
    this.router.navigate(['/sub-activity'], param);
  }

  getAllActivityList() {
    this.SpinnerService.show();
    this.commonService
      .GetMethod(Apiurl.GetAllActivities, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          console.log('res', res);
          this.mainDataSouce = res;
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.sort = this.sort;
          this.SpinnerService.hide();
        }
      });
  }
  getAllLookups() {
    this.listLookups = [];
    this.commonService
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading..')
      .then((res: any) => {
        this.listLookups = res;
      });
  }
  inputEvent(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterTypeChange(filterValue);
  }
  selection(value: any) {
    let param: NavigationExtras = {
      state: {
        type: value.type,
        activity_id: value.id
      }
    };
    // this.router.navigate(['/sub-activity'], param);
    this.router.navigate(['/activity-details'], param);
  }
  filterTypeChange(from: any) {
    this.dataSource = new MatTableDataSource(this.mainDataSouce);

    if (this.searchText !== '') {
      this.dataSource.filter = this.searchText.trim().toLowerCase();
    }

    if (this.Type) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, { type: this.Type })
      );
    }
    if (this.Status) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, { status: this.Status })
      );
    }
    if (this.salesRepId) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, { salesRepId: this.salesRepId })
      );
    }
    this.dataSource.sort = this.sort;
  }
  clearSearch() {
    this.Type = null;
    this.Status = null;
    this.salesRepId = 0;
    this.dataSource = new MatTableDataSource(this.mainDataSouce);
  }
  clear() {
    this.searchText = '';
    this.getAllActivityList();
  }
}
