import { LayoutService } from 'src/app/features/layout/layout.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { isThisQuarter } from 'date-fns';
import { dateFormat, getDeferredAnimation } from 'highcharts';
import { CommonProvider } from 'src/app/shared/common';
import Swal from 'sweetalert2';
import { Apiurl } from './../../shared/route';
import * as _ from 'underscore';
import { Subject } from 'rxjs';
import {
  CalendarView,
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent
} from 'angular-calendar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarSchedulerViewComponent,
  SchedulerDateFormatter
} from 'angular-calendar-scheduler';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};
interface eventTypeList {
  value: any;
}
@Component({
  selector: 'app-conacts',
  templateUrl: './conacts.component.html',
  styleUrls: ['./conacts.component.scss']
})
export class ConactsComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  ContactForm: any;
  searchdropdown: any;
  CompanyList: any[] = [];
  RoleList: any[] = [];
  SubsidiariesList: any[] = [];
  StateList: any[] = [];
  CountryList: any[] = [];
  accountId: any;
  companyId: any;
  netSuiteId: any;
  openBlock: any = 1;
  ContactId: any = 0;
  companyDetails: any = [];
  IsEditClick: boolean = false;
  StateName: any;
  CountryName: any;
  phoneLength = 0;
  phoneData: any;
  postCodeLength = 0;
  postCodeData: any;
  filterTypeList: any = [];
  selectedView: any = 'Agenda';
  dataSource: Array<any> = [];
  companyListSearch: any;
  companyType: any;
  agendaList: any = [];
  agendaTableColumn: string[] = [
    'type',
    'title',
    'relatedCustomer',
    'relatedTransaction',
    'relatedContact',
    'startDate',
    'startTime',
    'endTime'
    // 'phoneNumber'
  ];
  mainagendaList: any = [];
  relatedCompanyList: any = [];
  relatedContactList: any = [];
  //serching & filteration
  eventList: eventTypeList[] = [
    { value: 'Event' },
    { value: 'Phone' },
    { value: 'Task' },
    { value: 'String' }
  ];
  searchText: any = '';
  statusType: any;
  eventType: any;
  eventTitle: any;
  relatedCompany: any;
  relatedTransaction: any;
  relatedContact: any;
  //scheduler
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: any = 'Agenda';
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  relatedcompanies: any;
  listLookups: any = [];

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();
  showFilter: boolean = false;
  events: CalendarEvent[];
  filterType: any;
  activeDayIsOpen: boolean = true;
  searchInput: any;
  statusList: any = [];

  @ViewChild(CalendarSchedulerViewComponent)
  calendarScheduler: CalendarSchedulerViewComponent;
  constructor(
    private formBuilder: FormBuilder,
    public common: CommonProvider,
    public router: Router,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private layoutService: LayoutService
  ) {
    this.ContactForm = this.formBuilder.group({
      firstName: [
        '',
        Validators.compose([
          Validators.pattern(
            '^[A-Za-zs ! @#$%^&*()_+=-`~\\][{}|;:/.,? >< A-Za-z ]*$'
          ),
          Validators.required
        ])
      ],
      lastName: [
        '',
        Validators.compose([
          Validators.pattern(
            '^[A-Za-zs ! @#$%^&*()_+=-`~\\][{}|;:/.,? >< A-Za-z ]*$'
          ),
          Validators.required
        ])
      ],
      companyId: ['', Validators.compose([Validators.required])],
      phone: [
        '',
        Validators.compose([
          Validators.maxLength(10),
          Validators.pattern('[0-9]{10}')
        ])
      ],
      email: [
        '',
        Validators.compose([
          Validators.maxLength(30),
          Validators.pattern(
            '^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,3})$'
          )
        ])
      ],
      jobTitle: [''],
      roleId: [''],
      subsidiaryId: [6],
      dateCreated: [''],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      idRes: [''],
      id: [''],
      stateId: [''],
      postCode: [
        '',
        Validators.compose([
          Validators.maxLength(5),
          Validators.pattern('[0-9]{5}')
        ])
      ],
      countryId: [1],
      ContactId: [''],
      relatedCompanyIds: [''],
      companyType: ['']
    });
  }
  customer_id: any;

  ngOnInit(): void {
    this.FillDropDowns();
    this.getAllCompaniesList();

    this.getCalenderEvent();
    if (history.state.navigationId != 1) {
      if (history && history.state) {
        if (
          history.state.Contact_id != null &&
          history.state.Contact_id != undefined
        ) {
          this.ContactId = history.state.Contact_id;
          this.IsEditClick = this.ContactId == 0 ? true : false;
          if (this.ContactId != 0) {
            if (
              history.state.Company_id &&
              history.state.from &&
              history.state.from == 'add-company'
            ) {
              this.customer_id = history.state.Company_id;
              this.getContactDetailBynewCustomerId();
            } else {
              this.getAllLookups();
            }
          }
          if (this.ContactId == 0) {
            this.clearContactFormData();
          }
        }

        if (history.state.activity_id && history.state.Contact_id) {
          this.getCalenderEvent();
          this.view = CalendarView.Month;
        }
      }
    } else {
      this.router.navigate(['dashboard']);
      setTimeout(() => {
        this.layoutService.activeRoute = this.router.url;
        this.layoutService.getcurrentActivateRoute(
          this.layoutService.activeRoute
        );
      }, 50);
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  statusTypeChange(event: any) { }
  filterTypeChange(event: any) {
    this.agendaList = new MatTableDataSource(this.mainagendaList);
    this.agendaList.sort = this.sort;
    // this.agendaList.sort.matSortChange;

    if (this.searchText !== '') {
      this.agendaList.filter = this.searchText.trim().toLowerCase();
    }
    if (this.eventType) {
      this.agendaList = new MatTableDataSource(
        _.filter(this.agendaList.filteredData, {
          type: this.eventType
        })
      );
    }
    if (this.relatedcompanies) {
      this.getContactList(event.id);
      this.agendaList = new MatTableDataSource(
        _.filter(this.agendaList.filteredData, {
          relatedCustomer: this.relatedcompanies
        })
      );
    }
    if (this.relatedContact) {
      this.agendaList = new MatTableDataSource(
        _.filter(this.agendaList.filteredData, {
          relatedContact: this.relatedContact
        })
      );
    }

    this.agendaList.sort = this.sort;
  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.showFilter = false;
  }
  clearSearch() {
    this.searchInput = '';
    this.filterType = null;
    this.statusType = null;
    this.eventType = null;
    this.relatedcompanies = null;
    this.relatedTransaction = null;
    this.relatedContact = null;
    this.agendaList = new MatTableDataSource(this.mainagendaList);
    this.relatedContactList = [];
  }
  clearContactFormData() {
    (this.netSuiteId = 0),
      (this.accountId = 0),
      this.ContactForm.patchValue({
        firstName: '',
        lastName: '',
        companyId: 0,
        email: '',
        phone: '',
        jobTitle: '',
        subsidiaryId: '',
        roleId: '',
        netSuiteId: '',
        accountId: '',
        contactId: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        stateId: '',
        postCode: '',
        countryId: '',
        idRes: '',
        id: '',
        relatedCompanyIds: ''
      });
    if (history.state.id && history.state.id) {
      this.ContactForm.controls['companyId'].setValue(history.state.id);
    }
  }
  CompanyDDLChange() {
    this.accountId = this.CompanyList.filter(
      x => x.id == this.ContactForm.value.companyId
    ).map(x => x.accountId)[0];
    this.netSuiteId = this.CompanyList.filter(
      x => x.id == this.ContactForm.value.companyId
    ).map(x => x.netSuiteId)[0];
  }
  search(event: any, from: any) {
    if (from == 'phone') {
      if (event.target.value.length <= 10) {
        this.phoneLength = event.target.value.length;
        this.phoneData = event.target.value;
        return;
      }
      this.ContactForm.controls['phone'].setValue(this.phoneData);
    }
    if (from == 'postCode') {
      if (event.target.value.length <= 10) {
        this.postCodeLength = event.target.value.length;
        this.postCodeData = event.target.value;
        return;
      }
      this.ContactForm.controls['postCode'].setValue(this.postCodeData);
    }
  }
  async FillDropDowns() {
    await this.common
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading..')
      .then((resp: any) => {
        //
        this.CompanyList = [];
        this.companyListSearch = [];
        if (resp.length != 0) {
          this.CompanyList = resp;

          this.companyListSearch = resp;

          if (history.state.id) {
            let data: any = _.filter(this.CompanyList, data => {
              if (data.id == history.state.id) {
                return data;
              }
            });
            // let data = _.findWhere(this.CompanyList, { id: history.state.id });
            //
            console.log('datasert', data[0].legalName);
            this.ContactForm.controls['companyType'].setValue(
              data[0].legalName
            );
            this.ContactForm.controls['companyId'].setValue(data[0].id);
          }
        }
      });
    await this.common
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading..')
      .then((resp: any) => {
        //
        if (resp.contact != undefined && resp.contact.roles.length > 0) {
          this.RoleList = resp.contact.roles;
        }
        if (resp.company != undefined && resp.company.subsidiaries.length > 0) {
          this.SubsidiariesList = resp.company.subsidiaries;
        }
        if (resp.address != undefined && resp.address.states.length > 0) {
          this.StateList = resp.address.states;
        }
        if (resp.address != undefined && resp.address.countries.length > 0) {
          this.CountryList = resp.address.countries;
          if (this.CountryList.length == 1) {
            this.ContactForm.patchValue({
              countryId: this.CountryList[0].id
            });
          }
        }
      });
  }
  submitbtnClick(): any {
    if (!this.ContactForm.valid) {
      this.ContactForm.markAllAsTouched();
      return false;
    } else {
      //
      let param = {
        id: Number(this.ContactForm.value.id),
        netSuiteId: this.netSuiteId,
        companyId: Number(this.ContactForm.value.companyId),
        firstName: this.ContactForm.value.firstName,
        lastName: this.ContactForm.value.lastName,
        name:
          this.ContactForm.value.firstName +
          ' ' +
          this.ContactForm.value.lastName,
        email: this.ContactForm.value.email,
        jobTitle: this.ContactForm.value.jobTitle,
        phone: this.ContactForm.value.phone,
        subsidiaryId: Number(this.ContactForm.value.subsidiaryId),
        dateCreated: new Date().toISOString(),
        roleId: Number(this.ContactForm.value.roleId),
        accountId: this.accountId,
        relatedCompanyIds: [0],
        residentialAddress: {
          id: Number(this.ContactForm.value.idRes),
          contactId: this.ContactId > 0 ? Number(this.ContactId) : 0,
          addressLine1: this.ContactForm.value.addressLine1,
          addressLine2: this.ContactForm.value.addressLine2,
          city: this.ContactForm.value.city,
          stateId: Number(this.ContactForm.value.stateId),
          postCode: Number(this.ContactForm.value.postCode),
          countryId: Number(this.ContactForm.value.countryId)
        },
        createBy: '1',
        createDate: new Date().toISOString(),
        updatedBy: '1',
        updatedDate: new Date().toISOString()
      };
      if (this.ContactId > 0) {
        this.common
          .PutMethod(
            Apiurl.getContactList + '/' + this.ContactId,
            param,
            true,
            'Loading..',
            null
          )
          .then((resp: any) => {
            if (resp > 0) {
              Swal.fire(
                'Success',
                'Contact updated successfully',
                'success'
              ).then(() => {
                if (history && history.state.id) {
                  let id = history.state.id;
                  if (history.state.from == 'company-detail') {
                    id = history.state.id;
                  }
                  let qoute_id = null;
                  if (history.state.qoute_id) {
                    qoute_id = history.state.qoute_id;
                  }
                  let param: NavigationExtras = {
                    state: {
                      id: id,
                      contact_id: this.ContactId,
                      qoute_id: qoute_id,
                      edit: true
                    }
                  };
                  if (history.state.from == 'qoute') {
                    this.router.navigate(['add-quotes'], param);
                  }
                  if (history.state.from == 'company-detail') {
                    this.router.navigate(['companies-details'], param);
                  }
                } else {
                  this.router.navigate(['contacts-list']);
                }
              });
            } else {
              Swal.fire(resp.code, resp.message, 'error');
            }
          });
      } else {
        this.common
          .PostMethod(Apiurl.getContactList, param, true, 'Loading..', null)
          .then((resp: any) => {
            if (resp > 0) {
              Swal.fire(
                'Success',
                'Contact saved successfully',
                'success'
              ).then(() => {
                if (history && history.state.id) {
                  let id = history.state.id;
                  if (history.state.from == 'company-detail') {
                    id = history.state.id;
                  }
                  let qoute_id = null;
                  if (history.state.qoute_id) {
                    qoute_id = history.state.qoute_id;
                  }
                  let param: NavigationExtras = {
                    state: {
                      id: id,
                      contact_id: resp,
                      qoute_id: qoute_id
                    }
                  };
                  if (history.state.from == 'qoute') {
                    this.router.navigate(['add-quotes'], param);
                  }
                  if (history.state.from == 'company-detail') {
                    this.router.navigate(['companies-details'], param);
                  }
                } else {
                  this.router.navigate(['contacts-list']);
                }
              });
            } else {
              Swal.fire(resp.code, resp.message, 'error');
            }
          });
      }
    }
  }
  isRequired(controlName: string) {
    if (!controlName) {
      return false;
    }
    return this.ContactForm.controls[controlName].validator('').required;
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.ContactForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  async ViewContact() {
    let obj = {
      title: 'Are you sure you want to Cancel?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    await this.common.showConfirm(obj).then((resp: any) => {
      if (resp.isConfirmed) {
        if (history && history.state.id) {
          let id = history.state.id;
          console.log(id, 'idddd');

          this.router.navigate(['companies-details'], {
            queryParams: { id: id }
          });
        } else {
          this.router.navigate(['contacts-list']);
        }
      }
    });
  }

  goToPage() {
    let data = {
      id: this.companyDetails.id,
      netsuitid: this.companyDetails.netSuiteId,
      from: 'contacts'
    };

    let id: NavigationExtras = {
      state: {
        id: data
      }
    };
    this.router.navigate(['add-company'], id);
  }
  getAllLookups() {
    this.listLookups = [];
    this.common
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading..')
      .then((res: any) => {
        this.listLookups = res;
        this.getContactDetailById();
      });
  }
  async getContactDetailById() {
    await this.common
      .GetMethod(
        Apiurl.getContactList + '/' + this.ContactId,
        null,
        true,
        'Loading'
      )
      .then((res: any) => {
        this.companyDetails = [];
        this.companyDetails = res;
        if (res) {
          (this.netSuiteId = res.netSuiteId),
            (this.accountId = res.accountId),
            this.ContactForm.patchValue({
              firstName: res.firstName,
              lastName: res.lastName,
              companyType: res.companyName,
              companyId: res.companyId,
              email: res.email,
              phone: res.phone,
              jobTitle: res.jobTitle,
              subsidiaryId: res.subsidiaryId,
              roleId: res.roleId,
              netSuiteId: res.netSuiteId,
              accountId: res.accountId,
              contactId: res.residentialAddress.contactId,
              addressLine1: res.residentialAddress.addressLine1,
              addressLine2: res.residentialAddress.addressLine2,
              city: res.residentialAddress.city,
              stateId: res.residentialAddress.stateId,
              postCode:
                res.residentialAddress.postCode == 0
                  ? null
                  : res.residentialAddress.postCode,
              countryId: res.residentialAddress.countryId,
              idRes: res.residentialAddress.id,
              id: res.id,
              relatedCompanyIds: res.relatedCompanyIds
            });
          this.relatedCompany = [];
          if (res.relatedCompanyIds.length != 0) {
            for (let i = 0; i < res.relatedCompanyIds.length; i++) {
              let data = _.findWhere(this.CompanyList, {
                id: res.relatedCompanyIds[i]
              });
              if (data) {
                let stage = _.findWhere(this.listLookups?.stage?.stage, {
                  id: data.stageId
                });
                data.stageType = stage.stageDescription;
                this.relatedCompany.push(data);
              }
            }
          }
        }
      });
  }
  getContactDetailBynewCustomerId() {
    this.common
      .GetMethod(
        Apiurl.getContactList + '/' + this.ContactId,
        null,
        true,
        'Loading'
      )
      .then((res: any) => {
        res.relatedCompanyIds.push(history.state.Company_id);
        this.common
          .PutMethod(
            Apiurl.getContactList + '/' + this.ContactId,
            res,
            true,
            'Loading..',
            null
          )
          .then((resp: any) => {
            if (resp) {
              this.getAllLookups();
            }
          });
      });
  }
  viewFilterField() {
    this.showFilter = !this.showFilter;
  }
  OpenBlock(id: any) {
    this.openBlock = id;
    this.StateName = this.StateList.filter(
      x => x.id == this.companyDetails.residentialAddress.stateId
    ).map(x => x.stateDescription)[0];
    this.CountryName = this.CountryList.filter(
      x => x.id == this.companyDetails.residentialAddress.countryId
    ).map(x => x.countryDescription)[0];
  }
  EditClick() {
    this.IsEditClick = true;
  }
  AddNewContact() {
    this.ContactForm.reset();
    this.IsEditClick = true;
    this.ContactId = 0;
  }
  viewAgenda() {
    this.view = 'Agenda';
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  gotoActivities() {
    let param: NavigationExtras = {
      state: {
        type: 'Event',
        from: 'contact',
        Contact_id: history.state.Contact_id,
        id: history.state.id
      }
    };
    this.router.navigate(['sub-activity'], param);
  }
  searchCompany(event: any) {
    if (event.target.value != '') {
      this.companyListSearch = this.CompanyList.filter(
        (s: any) =>
          s.legalName
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) !== -1
      );
    } else {
      this.companyListSearch = this.CompanyList;
    }
  }
  setCompany(obj: any) {
    this.ContactForm.controls['companyId'].setValue(obj.id);
    this.accountId = this.CompanyList.filter(
      x => x.id == this.ContactForm.value.companyId
    ).map(x => x.accountId)[0];
    this.netSuiteId = this.CompanyList.filter(
      x => x.id == this.ContactForm.value.companyId
    ).map(x => x.netSuiteId)[0];
  }

  getCalenderEvent() {
    this.common
      .GetMethod(Apiurl.getAllActivties, null, true, 'Loading..')
      .then((res: any) => {
        this.mainagendaList = [];
        this.events = [];
        for (let index = 0; index < res.length; index++) {
          if (res[index].relatedContactId == history.state.Contact_id) {
            this.mainagendaList.push(res[index]);
            let end: any;
            if (
              new Date(res[index].endDate).setHours(0, 0, 0, 0) ==
              new Date().setHours(0, 0, 0, 0)
            ) {
              end = null;
            } else {
              end = new Date(res[index].endDate);
            }
            this.events.push({
              start: new Date(res[index].startDate),
              end: end,
              title: res[index].title,
              actions: this.actions,
              allDay: true,
              resizable: {
                beforeStart: true,
                afterEnd: true
              },
              draggable: true
            });
          }
        }
        this.agendaList = new MatTableDataSource(this.mainagendaList);
        this.agendaList.sort = this.sort;
        //
        // this.agendaList.sort.matSortChange;
      });
  }

  gotoCompany(item: any) {
    let param: NavigationExtras = {
      state: {
        id: item.id
      }
    };
    this.router.navigate(['companies-details'], param);
    setTimeout(() => {
      this.layoutService.activeRoute = this.router.url;
      this.layoutService.getcurrentActivateRoute(
        this.layoutService.activeRoute
      );
    }, 50);
  }

  setSelectedcompany(item: any) {
    this.relatedCompany.push(item);
    this.common
      .GetMethod(
        Apiurl.getContactList + '/' + this.ContactId,
        null,
        true,
        'Loading'
      )
      .then((res: any) => {
        res.relatedCompanyIds.push(item.id);
        this.common
          .PutMethod(
            Apiurl.getContactList + '/' + this.ContactId,
            res,
            true,
            'Loading..',
            null
          )
          .then((resp: any) => {
            if (resp) {
              this.getAllLookups();
            }
          });
      });
  }

  searchParent(event: any) {
    if (event.target.value != '') {
      this.companyListSearch = this.CompanyList.filter(
        (s: any) =>
          s.legalName
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) !== -1
      );
    } else {
      this.companyListSearch = this.CompanyList;
    }
  }
  //searchin and filteration
  //comopany & contact list
  getAllCompaniesList() {
    this.common
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.relatedCompanyList = res;
        }
      })
      .catch((error: any) => { });
  }

  getContactList(id: any) {
    this.common
      .GetMethod(Apiurl.getContactList, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            if (res[i].companyId == id) {
              this.relatedContactList.push(res[i]);
            }
          }
        }
      });
  }

  inputEvent(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterTypeChange(filterValue);
  }

  clear() {
    this.searchText = '';
    this.getCalenderEvent();
  }
  clearCompanyDetails() {
    this.searchdropdown = '';
    this.companyListSearch = this.CompanyList;
  }
}
