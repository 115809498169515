import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from './layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})

export class LayoutComponent implements OnInit {
  sideBarOpen = true;
  activeRoute: any;
  miniSidemenu: boolean = false;

  constructor(private router: Router, private layoutService: LayoutService) {
    this.layoutService.setObservable().subscribe((data: any) => {
      this.activeRoute = data
    })

    this.layoutService.setObservableforFlag().subscribe((data: any) => {
      if (data != undefined && data != null) {
        if (data == true) {
          this.miniSidemenu = true;
        } else {
          this.miniSidemenu = false;
        }
      }
    })
  }

  ngOnInit(): void {
    //code for auth login response
    if (this.router.url.indexOf('signin-oidcaccess_token=') >= 0) {
      var url = this.router.url;
      if (url.split("=").length > 0) {
        this.layoutService.LoginToken = url.split("=")[1].split("&")[0];
        localStorage.setItem("LoginToken", this.layoutService.LoginToken)
        this.router.navigate(['dashboard'])
      } else {
        this.router.navigate(['login'])
      }
    } else {
      this.layoutService.LoginToken = localStorage.getItem("LoginToken")
      if (this.layoutService.LoginToken == null) {
        this.router.navigate(['login'])
      } else if (this.router.url == "/") {
        this.router.navigate(['dashboard'])
        setTimeout(() => {
          window.location.reload();
        }, 50);
      }
    }

    if (this.layoutService.activeRoute != undefined && this.layoutService.activeRoute != null) {
      this.activeRoute = this.layoutService.activeRoute
    } else {
      this.activeRoute = this.router.url
    }
  }

  sideBarToggler(event: any) {
    this.sideBarOpen = !this.sideBarOpen;
  }

}
