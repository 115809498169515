const RoutePath = 'https://bepoz-api-dev.nexware.app/v1/';

export const Apiurl = {
  RoutePath: RoutePath,

  //quotes methods
  getAllQuotes: 'Quotes/v1/Quotes',
  getAllTemplates: 'product-template/v1/ProductTemplate',
  getAllItems: 'Items/v1/Items',

  //company methods
  getAllCompanies: 'companies/v1/Company',

  //save Company
  saveCompany: 'companies/v1/Company',

  //lookup
  getAllLookup: 'lookup/v1/Lookup',
  //Contact List
  getContactList: 'contact/v1/Contacts',

  //opportunities
  getAllOpportunityList: 'Opportunities/v1/Opportunity',

  //activities

  getAllActivties: 'activity/v1/Activity',

  //save activities
  saveActivity: 'activity/v1/Activity',

  //GetAllActivities
  GetAllActivities: 'activity/v1/Activity'
};
