<!-- <app-header></app-header> -->
<div class="content-header">
    <div class="col-lg-8 col-md-8 col-sm-12">
        <div class="cc-header-wrapper">
            <div style="margin-top: 12px;">
                <h2 class="m-0">Companies</h2>
                <!-- <p class="m-0">{{TodayDate}}</p> -->
            </div>
            <div class="w-50 pl-17">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-icon matPrefix>search</mat-icon>
                    <mat-label class="f-12">Search</mat-label>
                    <input matInput (keyup)="inputEvent($event)" [(ngModel)]="searchText" />
                    <mat-icon matSuffix (click)="clear()">close</mat-icon>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 text-right f-12" *ngIf="dataSource?.filteredData?.length">
        {{ dataSource?.filteredData?.length || 0 }} Records Found
        <button mat-stroked-button class="me-2 c-btn green-btn" (click)="openFilter()">
            <!-- <span class="material-icons me-2"> filter_list </span> -->
            Filter
        </button>
        <button mat-raised-button class="theme-bg c-btn" (click)="goToAddContact()">
            <mat-icon matPrefix>add</mat-icon> &nbsp;Add
        </button>
    </div>
</div>
<div>
    <div class="align-items-center row c-cad">
        <div class="row text-center" *ngIf="showFilter">
            <div class="col-lg-5">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Stage</mat-label>
                            <mat-select [(ngModel)]="stageType" [ngModelOptions]="{ standalone: true }">
                                <mat-option *ngFor="let obj of stageList" [value]="obj.stageDescription" (click)="filterTypeChange(obj)">
                                    {{ obj.stageDescription }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Status</mat-label>
                            <mat-select [(ngModel)]="statusType" [ngModelOptions]="{ standalone: true }">
                                <mat-option *ngFor="let obj of statusList" [value]="obj.leadStatusDescription" (click)="filterTypeChange(obj)">
                                    {{ obj.leadStatusDescription }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Market / Indusrty</mat-label>
                            <mat-select [(ngModel)]="marketType" [ngModelOptions]="{ standalone: true }">
                                <mat-option *ngFor="let obj of marketList" [value]="obj.marketIndustryDescription" (click)="filterTypeChange(obj)">
                                    {{ obj.marketIndustryDescription }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Sales Rep</mat-label>
                            <mat-select [(ngModel)]="salesRepType" [ngModelOptions]="{ standalone: true }">
                                <mat-option *ngFor="let obj of salesRepList" [value]="obj.salesRepDescription" (click)="filterTypeChange(obj)">
                                    {{ obj.salesRepDescription }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col-lg-2" style="margin-top:8px">
                <div class="row">
                    <div class="col-md-6 p-0">
                        <button mat-raised-button color="warn" class="c-btn
                            w-100" (click)="clearSearch()">
                            Clear All
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-content">
    <mat-card class="w-100">
        <table mat-table [dataSource]="dataSource" class="w-100
            contact-list_main-table" matSort style="cursor: pointer" (click)="test()">
            <ng-container matColumnDef="stage">
                <th class="c-table-th" mat-header-cell *matHeaderCellDef mat-sort-header>Stage</th>
                <td mat-cell *matCellDef="let element">{{ element.stage }}</td>
            </ng-container>
            <ng-container matColumnDef="legalName">
                <th class="c-table-th" mat-header-cell *matHeaderCellDef mat-sort-header>Legal Name</th>
                <td mat-cell *matCellDef="let element">{{ element.legalName }}</td>
            </ng-container>

            <ng-container matColumnDef="tradingName">
                <th class="c-table-th" mat-header-cell *matHeaderCellDef mat-sort-header>Trading Name</th>
                <td mat-cell *matCellDef="let element">{{ element.tradingName }}</td>
            </ng-container>

            <ng-container matColumnDef="leadStatus">
                <th class="c-table-th" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.leadStatus }}
                </td>
            </ng-container>
            <ng-container matColumnDef="phone">
                <th class="c-table-th" mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
                <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th class="c-table-th" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
            </ng-container>
            <ng-container matColumnDef="leadSource">
                <th class="c-table-th" mat-header-cell *matHeaderCellDef mat-sort-header>Lead Source</th>
                <td mat-cell *matCellDef="let element">{{ element.leadSource }}</td>
            </ng-container>
            <ng-container matColumnDef="marketIndustry">
                <th class="c-table-th" mat-header-cell *matHeaderCellDef mat-sort-header>
                    Market/Industry
                </th>
                <td mat-cell *matCellDef="let element">{{ element.marketIndustry }}
                </td>
            </ng-container>
            <ng-container matColumnDef="tier">
                <th class="c-table-th" mat-header-cell *matHeaderCellDef mat-sort-header>Tier</th>
                <td mat-cell *matCellDef="let element">{{ element.tier }}</td>
            </ng-container>
            <ng-container matColumnDef="salesRep">
                <th class="c-table-th" mat-header-cell *matHeaderCellDef mat-sort-header>Sales Rep</th>
                <td mat-cell *matCellDef="let element">{{ element.salesRep }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let
                element" (click)="showDetails(element.id)"></tr>
        </table>
    </mat-card>
</div>