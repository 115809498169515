<div class="content-header">
    <div class="col-lg-6 col-md-6 col-sm-12">
        <!-- <h1 class="m-0">Add Contact</h1> -->
        <p class="m-0">Today 10th April 2021</p>
    </div>
    <!-- <div class="col-lg-6 col-md-6 col-sm-12">
    <h1 class="m-0">
      {{ activityDetails.firstName }} {{ activityDetails.lastName }}
    </h1>
    <p class="m-0">
      {{ activityDetails.phone }} . {{ activityDetails.email }}
    </p>
  </div> -->
    <div class="col-lg-6 col-md-6 col-sm-12 text-right">
        <button mat-raised-button class="green-btn   c-btn" (click)="EditClick()">
      <span class="material-icons">
        edit
      </span>
      Edit
    </button>
        <!-- <button
      mat-raised-button
      class="theme-bg c-btn"
      style="margin-left: 1em;"
      (click)="AddNewContact()"
    >
      <span class="material-icons add-btn">
        add
      </span>
      Add Contacts
    </button> -->
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-right"></div>
</div>

<div class="opportunities-content">
    <div class="row">
        <div class="col-lg-12">
            <div class="w-100">
                <mat-card class="related-contact-card ">
                    <mat-tab-group mat-align-tabs="start" class="content">
                        <!-- tab 1 Primary Information -->
                        <mat-tab label="Primary Information">
                            <table class=" w-100">
                                <tr style="font-size: small;color: grey;">
                                    <td>
                                        <mat-label>Type</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Title</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Start Date</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Start Time</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>End Time</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-label>{{ activityDetails.type }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ activityDetails.title }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ activityDetails.startDate | date: "dd MMM YYYY" }}
                                        </mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ activityDetails.startTime | date: "HH:mm" }}
                                        </mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ activityDetails.endTime | date: "HH:mm" }}
                                        </mat-label>
                                    </td>
                                </tr>
                                <br />
                            </table>
                        </mat-tab>
                        <!-- tab 2  Classification -->
                        <mat-tab label="Details">
                            <table class=" w-100">
                                <tr style="font-size: small;color: grey;">
                                    <td>
                                        <mat-label>Related Contact</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Related Company</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Sales Rep</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-label>{{ activityDetails.relatedContact }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ activityDetails.relatedCustomer }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ activityDetails.salesRep }}</mat-label>
                                    </td>
                                </tr>
                            </table>
                        </mat-tab>
                        <!-- tab 3  Address -->
                        <!-- <mat-tab label="Address">
              <p>Address</p>
              <table class=" w-100">
                <tr style="font-size: small;color: grey;">
                  <td>
                    <mat-label>Address Line 1</mat-label>
                  </td>
                  <td>
                    <mat-label>Address Line 2</mat-label>
                  </td>
                  <td>
                    <mat-label>City or Suburb</mat-label>
                  </td>
                  <td>
                    <mat-label>State</mat-label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-label>{{
                      activityDetails.residentialAddress.addressLine1
                    }}</mat-label>
                  </td>
                  <td>
                    <mat-label>{{
                      activityDetails.residentialAddress.addressLine2
                    }}</mat-label>
                  </td>
                  <td>
                    <mat-label>{{
                      activityDetails.residentialAddress.city
                    }}</mat-label>
                  </td>

                  <td>
                    <mat-label>{{ this.StateName }}</mat-label>
                  </td>
                </tr>
                <br />
                <tr style="font-size: small;color: grey;">
                  <td>
                    <mat-label>Post Code</mat-label>
                  </td>
                  <td>
                    <mat-label>Country</mat-label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <mat-label>{{
                      activityDetails.residentialAddress.postCode
                    }}</mat-label>
                  </td>
                  <td>
                    <mat-label>{{ this.CountryName }}</mat-label>
                  </td>
                </tr>
              </table>
            </mat-tab> -->
                    </mat-tab-group>
                </mat-card>
            </div>
            <mat-card class="mt-4">
                <div class="row mb-4">
                    <div class="col-md-4">
                        <div class="d-flex align-items-center pt-2 pb-4 flex-wrap" *ngIf="view != 'Agenda'">
                            <h3 class="m-0 d-flex align-items-center me-2 calendar-text">
                                <span class="material-icons me-2 secondary-text">
                  date_range </span>{{ viewDate | calendarDate: view + "ViewTitle":"en" }}
                            </h3>
                            <div class="calendar-btn-group  d-flex">
                                <button mat-stroked-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                  <div class="material-icons">
                    chevron_left
                  </div>
                </button>
                                <button mat-stroked-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                  <div class="material-icons">
                    chevron_right
                  </div>
                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8 text-right ">
                        <div class="d-flex align-items-center justify-content-end flex-wrap">
                            <div class="me-2">
                                {{ events && events.length ? events.length : "0" }} Records Found
                            </div>
                            <div class="mat-select-wrapper  mb-2">
                                <mat-form-field appearance="outline">
                                    <mat-select [(ngModel)]="selectedView">
                                        <mat-option value="Agenda">
                                            <div (click)="viewAgenda()" [class.active]="view == 'Agenda'">
                                                Agenda
                                            </div>
                                        </mat-option>
                                        <mat-option value="Month">
                                            <div (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                                                Month
                                            </div>
                                        </mat-option>
                                        <mat-option value="Week">
                                            <div (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                                                Week
                                            </div>
                                        </mat-option>
                                        <mat-option value="Day">
                                            <div (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                                                Day
                                            </div>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="d-flex ms-2 mb-2">
                                <!-- <button
                  mat-stroked-button
                  class="me-2 c-btn"
                  (click)="viewFilterField()"
                >
                  <span class="material-icons me-2"> filter_list </span>Filter
                </button> -->
                                <!-- <button
                  (click)="gotoActivities()"
                  mat-raised-button
                  class="c-btn theme-bg"
                  mwlCalendarPreviousView
                  [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()"
                >
                  <span class="material-icons add-btn">
                    event
                  </span>
                  &nbsp;New Event
                </button> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center" *ngIf="showFilter">
                    <div class="col-md-2"></div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Type</mat-label>
                            <mat-select [(ngModel)]="filterType" [ngModelOptions]="{ standalone: true }" (change)="filterTypeChange($event)">
                                <mat-option *ngFor="let obj of filterTypeList" [value]="obj.value">
                                    {{ obj.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Status</mat-label>
                            <mat-select [(ngModel)]="statusType" [ngModelOptions]="{ standalone: true }" (change)="statusTypeChange($event)">
                                <mat-option *ngFor="let obj of statusList" [value]="obj.id">
                                    {{ obj.statusDescription }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <button mat-stroked-button class="col-md-2 c-btn green-btn  btn-height" (click)="clearSearch()">
            Clear All
          </button>
                    <div class="col-md-4"></div>
                </div>
                <div class="row text-center">
                    <div class="col-md-12"></div>
                </div>

                <div [ngSwitch]="view">
                    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-month-view>
                    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-week-view>
                    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-day-view>
                    <div *ngSwitchCase="'Agenda'" class="agenda-div">
                        <div class="no-content" *ngIf="events && events.length == 0">
                            <h2 class="primary-text c-h2">No Related Record Found</h2>
                        </div>
                        <div *ngIf="dataSource">
                            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 companies-table w-100" matSort style="cursor:pointer">
                                <ng-container matColumnDef="eventType">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Event Type
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.type || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="eventTitle">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Event Title
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.title || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="relatedCompany">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Related Company
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.relatedCustomer || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="relatedTransaction">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Related Transaction
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.relatedTransaction || "-" }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="relatedContact">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Related Contact
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.relatedContact || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="startDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Start Date
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.createDate | date: "dd MMM YYYY" || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="startTime">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Start Time
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.startTime | date: "HH:mm" || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="endTime">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        End Time
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.endTime | date: "HH:mm" || "-" }}
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->
                                <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>