import { Component, OnInit, ViewChild } from '@angular/core';
import { Apiurl } from './../../shared/route';
import { CommonProvider } from 'src/app/shared/common';
import { Router, NavigationExtras } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { map, filter } from 'underscore';
import * as _ from 'underscore';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-contactlist',
  templateUrl: './contactlist.component.html',
  styleUrls: ['./contactlist.component.scss']
})
export class ContactlistComponent implements OnInit {
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'companyName',
    'phone',
    'email',
    'jobTitle',
    'role'
  ];
  searchText: any = '';
  dataSource: any;
  @ViewChild(MatSort) sort: any;
  panelOpenState = false;
  ContactList: any[] = [];
  companyList: any = [];
  roleList: any = [];
  showFilter: boolean = false;
  Company: any;
  TodayDate: any = new Date().toDateString();
  Role: any;
  mainDataSouce: any = [];
  constructor(public common: CommonProvider, public router: Router, private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getContactList();
    this.getAllStages();
    this.getAllRole();
  }

  getAllStages() {
    this.common
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading..')
      .then((res: any) => {

        this.companyList = [];
        if (res.length != 0) {
          let companyName = _.map(res, function (company) {
            return company;
          });
          this.companyList = companyName;
          this.SpinnerService.hide();
        }
      })
      .catch(error => {

        this.SpinnerService.hide();
      });;
  }
  
  getAllRole() {
    this.common
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading..')
      .then((res: any) => {
        this.roleList = res.contact.roles;
      });
  }

  async getContactList() {
    this.SpinnerService.show();
    this.common
      .GetMethod(Apiurl.getContactList, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.mainDataSouce = res;
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.sort = this.sort;
          this.SpinnerService.hide();
        }
      });

  }
  AddNewContact() {
    let param: NavigationExtras = {
      state: {
        Contact_id: 0
      }
    };
    this.router.navigate(['contacts'], param);
  }
  selection(value: any) {
    let param: NavigationExtras = {
      state: {
        Contact_id: value.residentialAddress.contactId,
        id: value.companyId
      }
    };
    this.router.navigate(['contacts'], param);
  }
  openFilter() {
    this.showFilter = !this.showFilter;
  }

  inputEvent(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  clear() {
    this.searchText = '';
    this.getContactList();
  }
  filterTypeChange(event: any, from: any) {
    if (this.dataSource.filteredData.length === 0) {
      this.dataSource = new MatTableDataSource(this.mainDataSouce);
    }
    if (this.searchText !== '') {
      this.dataSource.filter = this.searchText.trim().toLowerCase();
    }

    if (from == 'company') {
      this.dataSource = new MatTableDataSource(this.mainDataSouce);
      // this.Role = null;
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, { companyName: this.Company })
      );
    }
    if (from == 'role') {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, { role: this.Role })
      );
    }
    this.dataSource.sort = this.sort;
  }
  clearSearch() {
    this.Company = null;
    this.Role = null;
    this.dataSource = new MatTableDataSource(this.mainDataSouce);
  }
}
