import { SpeedDialFabPosition } from './../../../shared/speed-dial-fab/speed-dial-fab.component';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CommonProvider } from 'src/app/shared/common';
import { Subject } from 'rxjs';
import { Apiurl } from 'src/app/shared/route';
import { LayoutService } from './../../layout/layout.service';
import {
  CalendarView,
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent
} from 'angular-calendar';
import * as _ from 'underscore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';

import {
  CalendarSchedulerViewComponent,
  SchedulerDateFormatter
} from 'angular-calendar-scheduler';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
interface eventTypeList {
  value: any;
}

@Component({
  selector: 'app-companies-details',
  templateUrl: './companies-details.component.html',
  styleUrls: ['./companies-details.component.scss']
})
export class CompaniesDetailsComponent implements OnInit {
  speedDialFabButtons = [
    {
      icon: 'timeline',
      tooltip: 'Task'
    },
    {
      icon: 'view_headline',
      tooltip: 'Event'
    },
    {
      icon: 'room',
      tooltip: 'Call'
    }
  ];

  SpeedDialFabPosition = SpeedDialFabPosition;
  speedDialFabColumnDirection = 'column';
  speedDialFabPosition = SpeedDialFabPosition.Top;
  speedDialFabPositionClassName = 'speed-dial-container-top';

  onPositionChange(position: SpeedDialFabPosition) {
    switch (position) {
      case SpeedDialFabPosition.Bottom:
        this.speedDialFabPositionClassName = 'speed-dial-container-bottom';
        this.speedDialFabColumnDirection = 'column-reverse';
        break;
      default:
        this.speedDialFabPositionClassName = 'speed-dial-container-top';
        this.speedDialFabColumnDirection = 'column';
    }
  }

  onSpeedDialFabClicked(btn: any) {
    console.log(btn);
    this.gotoActivities(btn.tooltip)
  }


  @ViewChild(CalendarSchedulerViewComponent)
  calendarScheduler: CalendarSchedulerViewComponent;
  filterTypeList: any = [];
  statusList: any = [];
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  view: any = 'Agenda';
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  events: CalendarEvent[];
  refresh: Subject<any> = new Subject();
  quoteData: any = [];
  oppertunityData: any = [];
  companyList: any = [];
  addressData: any
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];
  selectedView: any = 'Agenda';
  searchInput: any;
  showFilter: boolean = false;
  milestone_flag: any = 1;
  activeDayIsOpen: boolean = true;
  value: any;
  display_data: any;
  companydata: Array<any> = [];
  id: any;
  contactId: any;
  filterType: any;
  dataSource: Array<any> = [];
  companymetadata: any = []
  displayedColumns = [
    'firstName',
    'lastName',
    'jobTitle',
    'role',
    'email',
    'phone'
  ];

  shippingdetails = [
    'addressLine1',
    'addressLine2',
    'city',
    'postCode',
    'stateId',
    'countryId'
  ];
  productinfo = ['productCategory', 'software'];
  classicInfo = ['marketIndustry', 'tier', 'productCategory'];
  communiactionInfo = ['phone', 'email', 'subsidary'];
  primaryInfo = [
    'stage',
    'legalName',
    'parentName',
    'tradingName',
    'leadSource',
    'leadStatus',
    'salesRep',

    'comments'
  ];
  contact_id: any;
  agendaList: any = [];
  agendaTableColumn: string[] = [
    'type',
    'title',
    'relatedCustomer',
    'relatedTransaction',
    'relatedContact',
    'startDate',
    'startTime',
    'endTime'
    // 'phoneNumber'
  ];
  mainagendaList: any = [];
  relatedCompanyList: any = [];
  relatedContactList: any = [];

  //serching & filteration
  eventList: eventTypeList[] = [
    { value: 'Event' },
    { value: 'Phone' },
    { value: 'Task' },
    { value: 'String' }
  ];
  @ViewChild(MatSort) sort: any;
  searchText: any = '';
  statusType: any;
  eventType: any;
  eventTitle: any;
  relatedCompany: any;
  relatedTransaction: any;
  relatedContact: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonProvider,
    private modal: NgbModal,
    private layoutService: LayoutService,
    private SpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getAllLookupList()
    this.getCalenderEvent();
    this.getAllCompaniesList();
    // this.getContactList()
    if (history.state.navigationId != 1) {
      this.getData();
      this.getAllQuotes();
      this.getAllOppertunities();
      console.log(history.state)
      this.id = history.state.id;
      if (this.id) {
        // console.log('***************');
        this.getCalenderEvent();
      }
      if (history.state.contact_id) {
        this.contact_id = history.state.contact_id;
      } else {
        this.contact_id = null;
      }
      if (history.state.activity_id) {
        this.getCalenderEvent();
        this.view = CalendarView.Month;
      }
      if (history.state.quote_id) {
        this.getAllQuotes();
      }
    } else {
      this.router.navigate(['dashboard']);
      setTimeout(() => {
        this.layoutService.activeRoute = this.router.url;
        this.layoutService.getcurrentActivateRoute(
          this.layoutService.activeRoute
        );
      }, 50);
    }
    this.filterTypeList = [
      {
        value: 1,
        name: 'Type'
      },
      {
        value: 1,
        name: 'Phone'
      },
      {
        value: 2,
        name: 'Event'
      },
      {
        value: 3,
        name: 'Task'
      },
      {
        value: 4,
        name: 'Note'
      }
    ];
    this.getStatusList();
  }

  //navigate to another page
  navigateTo(page: any) {
    let data = {
      id: this.companymetadata.id,
      netsuitid: this.companymetadata.netSuiteId,
      from: 'company-detail'
    };

    let param: NavigationExtras = {
      state: {
        id: data,
        Contact_id: 0,
        from: 'company-detail'
      }
    };
    switch (page) {
      case 'Contacts':
        this.router.navigate(['contacts'], param);
        break;
      case 'Quotes':
        this.router.navigate(['add-quotes'], param);
        setTimeout(() => {
          this.layoutService.activeRoute = this.router.url;
          this.layoutService.getcurrentActivateRoute(
            this.layoutService.activeRoute
          );
        }, 50);
        break;
      case 'Opportunities':
        this.router.navigate(['add-opportunities'], param);

        setTimeout(() => {
          this.layoutService.activeRoute = this.router.url;
          this.layoutService.getcurrentActivateRoute(
            this.layoutService.activeRoute
          );
        }, 50);
        break;

      default:
        break;
    }
  }

  getStatusList() {
    this.statusList = [];
    this.commonService
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading..')
      .then((res: any) => {
        if (res.length != 0) {
          this.statusList = res.activity.statuses;
        }
      });
  }

  async getData() {
    let data: any = await this.getAllCompanies();
    this.commonService
      .GetMethod(Apiurl.getAllCompanies + '/' + this.id, null, true, 'Loading')
      .then(async (res: any) => {
        if (res) {
          console.log(res)
          this.dataSource.push(res);
          this.companymetadata = res
          // console.log(this.companymetadata)
          if (this.companymetadata.parentId != 0) {
            let name = _.findWhere(data, { id: res.parentId });
            this.companymetadata.parentName = name.legalName;
          } else {
            this.companymetadata.parentName = '';
          }
          if (this.contact_id != null && this.contact_id != undefined) {
            if (!res.relatedContactIds.includes(this.contact_id)) {
              res.relatedContactIds.push(this.contact_id);
            }
            this.commonService
              .PutMethod(
                Apiurl.saveCompany + '/' + this.id,
                res,
                true,
                'Loading',
                null
              )
              .then(async (resdata: any) => {
                if (resdata) {
                  if (res.relatedContactIds) {
                    this.SpinnerService.show();
                    let data: any = [];
                    let index;
                    for (
                      index = 0;
                      index < res.relatedContactIds.length;
                      index++
                    ) {
                      let resp = await this.commonService.GetMethod(
                        Apiurl.getContactList +
                        '/' +
                        res.relatedContactIds[index],
                        null,
                        false,
                        'Loading..'
                      );
                      data.push(resp);
                    }
                    this.companydata = data;
                    this.SpinnerService.hide();
                  }
                }
              })
              .catch(err => {
                // console.log(err);
              });
          } else {
            if (res.relatedContactIds) {
              this.SpinnerService.show();
              let data: any = [];
              let index;
              for (index = 0; index < res.relatedContactIds.length; index++) {
                let resp = await this.commonService.GetMethod(
                  Apiurl.getContactList + '/' + res.relatedContactIds[index],
                  null,
                  false,
                  'Loading..'
                );
                data.push(resp);
              }
              this.companydata = data;
              this.SpinnerService.hide();
            }
          }
          // console.log(this.companymetadata.billingAddress?.countryId)
          // console.log(this.companymetadata.billingAddress?.stateId)
          if (this.companymetadata.billingAddress) {
            // console.log('**************')
            let country = _.findWhere(this.addressData.countries, { id: this.companymetadata.billingAddress.countryId })
            let state = _.findWhere(this.addressData.states, { id: this.companymetadata.billingAddress.stateId })
            if (country != undefined) {
              this.companymetadata.billingAddress.countryname = country.countryDescription
            }
            else {
              this.companymetadata.billingAddress.countryname = ''
            }
            if (state != undefined) {
              this.companymetadata.billingAddress.stateName = country.stateDescription
            }
            else {
              this.companymetadata.billingAddress.stateName = ''
            }
            // console.log(state)
          }
          else {
            this.companymetadata.billingAddress.countryname = ''
            this.companymetadata.billingAddress.stateName = ''
          }
          //shipping Address
          if (this.companymetadata.shippingAddress) {
            // console.log('**************')
            let country = _.findWhere(this.addressData.countries, { id: this.companymetadata.shippingAddress.countryId })
            let state = _.findWhere(this.addressData.states, { id: this.companymetadata.shippingAddress.stateId })
            if (country != undefined) {
              this.companymetadata.shippingAddress.countryname = country.countryDescription
            }
            else {
              this.companymetadata.shippingAddress.countryname = ''
            }
            if (state != undefined) {
              this.companymetadata.shippingAddress.stateName = country.stateDescription
            }
            else {
              this.companymetadata.shippingAddress.stateName = ''
            }
            // console.log(state)
          }
          else {
            this.companymetadata.shippingAddress.countryname = ''
            this.companymetadata.shippingAddress.stateName = ''
          }
        }
      });
  }

  //addnew / edit details
  addNewDetail(status: any) {
    switch (status) {
      case 'editCurrent':
        this.router.navigate(['add-company'], {
          queryParams: { id: this.companymetadata.id }
        });
        break;
      case 'addNew':
        this.router.navigate(['add-company']);
        break;
      default:
        break;
    }
  }

  //schedular start
  viewFilterField() {
    this.showFilter = !this.showFilter;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        // color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.showFilter = false;
  }
  viewAgenda() {
    this.view = 'Agenda';
  }
  //schedular end

  filterTypeChange(event: any) {
    // console.log(event);
    this.agendaList = new MatTableDataSource(this.mainagendaList);
    this.agendaList.sort = this.sort;
    // this.agendaList.sort.matSortChange;
    // console.log(this.dataSource);
    if (this.searchText !== '') {
      this.agendaList.filter = this.searchText.trim().toLowerCase();
    }
    if (this.eventType) {
      this.agendaList = new MatTableDataSource(
        _.filter(this.agendaList.filteredData, {
          type: this.eventType
        })
      );
    }
    if (this.relatedCompany) {
      this.getContactList(event.id);
      this.agendaList = new MatTableDataSource(
        _.filter(this.agendaList.filteredData, {
          relatedCustomer: this.relatedCompany
        })
      );
    }
    if (this.relatedContact) {
      this.agendaList = new MatTableDataSource(
        _.filter(this.agendaList.filteredData, {
          relatedContact: this.relatedContact
        })
      );
    }

    this.agendaList.sort = this.sort;
  }

  statusTypeChange(event: any) { }

  clearSearch() {
    this.searchInput = '';
    this.filterType = null;
    this.statusType = null;
    this.eventType = null;
    this.relatedCompany = null;
    this.relatedTransaction = null;
    this.relatedContact = null;
    this.agendaList = new MatTableDataSource(this.mainagendaList);
    this.relatedContactList = [];
  }

  gotoActivities(whereToGo: any) {
    let param: NavigationExtras = {
      state: {
        type: whereToGo,
        id: history.state.id,
        from: 'company-detail'
      }
    };
    this.router.navigate(['sub-activity'], param);
  }

  getAllQuotes() {
    this.commonService
      .GetMethod(Apiurl.getAllQuotes, null, true, 'Loading')
      .then((res: any) => {
        // console.log(res, history.state.id)
        if (res) {
          this.quoteData = []
          for (let index = 0; index < res.length; index++) {
            if (res[index].customerId != 0) {
              if (res[index].customerId == history.state.id) {
                this.quoteData.push(res[index])
              }
            }
          }
        }
      });
  }
  getAllOppertunities() {
    this.commonService
      .GetMethod(Apiurl.getAllOpportunityList, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          let data = _.findWhere(res, { customerId: history.state.id });
          if (data) {
            for (let i = 0; i < data.length; i++) {
              this.oppertunityData.push(data[i]);
            }
          } else {
            this.oppertunityData = [];
          }
        }
      });
  }

  getCalenderEvent() {
    this.commonService
      .GetMethod(Apiurl.getAllActivties, null, true, 'Loading..')
      .then((res: any) => {
        // console.log(res);
        this.mainagendaList = [];
        this.events = [];
        for (let index = 0; index < res.length; index++) {
          if (res[index].relatedCustomerId == parseInt(history.state.id)) {
            this.mainagendaList.push(res[index]);
            let end: any;
            if (
              new Date(res[index].endDate).setHours(0, 0, 0, 0) ==
              new Date().setHours(0, 0, 0, 0)
            ) {
              end = null;
            } else {
              end = new Date(res[index].endDate);
            }
            this.events.push({
              start: new Date(res[index].startDate),
              end: end,
              title: res[index].title,
              actions: this.actions,
              allDay: true,
              resizable: {
                beforeStart: true,
                afterEnd: true
              },
              draggable: true
            });
          }
        }
        this.agendaList = new MatTableDataSource(this.mainagendaList);
        this.agendaList.sort = this.sort;
        // this.agendaList.sort.matSortChange;
        // console.log(this.agendaList);
      });
  }
  async getAllCompanies() {
    this.companyList = [];
    let data: any = await this.commonService.GetMethod(
      Apiurl.getAllCompanies,
      null,
      true,
      'Loading..'
    );
    return data;
    // .then((res: any) => {
    //   console.log(res)
    //   return res
    // });
  }

  //comopany & contact list
  getAllCompaniesList() {
    this.SpinnerService.show();
    this.commonService
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          // console.log(res);
          this.relatedCompanyList = res;
          // console.log(this.relatedCompanyList);
          this.SpinnerService.hide();
        }
      })
      .catch((error: any) => {
        console.log(error);
        this.SpinnerService.hide();
      });
  }

  getContactList(id: any) {
    // console.log(id);
    this.SpinnerService.show();
    this.commonService
      .GetMethod(Apiurl.getContactList, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          // console.log(res);
          for (let i = 0; i < res.length; i++) {
            if (res[i].companyId == id) {
              this.relatedContactList.push(res[i]);
            }
          }
          this.SpinnerService.hide();
        }
      });
  }

  inputEvent(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterTypeChange(filterValue);
  }

  clear() {
    this.searchText = '';
    this.getCalenderEvent();
  }
  gotoQuote(item: any) {
    console.log(item)
    let param: NavigationExtras = {
      state: {
        id: history.state.id,
        contact_id: history.state.contact_id,
        qoute_id: item.id,
        from: 'company-detail',
        isEdit: true,
        item
      }
    };
    this.router.navigate(['add-quotes'], param);
  }

  //get country and state data
  getAllLookupList() {
    this.commonService.GetMethod(Apiurl.getAllLookup, null, true, 'Loading').then((res: any) => {
      if (res) {
        this.addressData = res.address;
        // console.log(this.addressData)
      }
    })
  }
  gotoContact(data: any) {
    console.log(data)
    let param: NavigationExtras = {
      state: {
        Contact_id: data.id,
        id: data.companyId,
        from: 'company-detail'
      }
    };
    this.router.navigate(['contacts'], param);
  }
}
