import { Apiurl } from './../../shared/route';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonProvider } from 'src/app/shared/common';
import { NavigationExtras, Router } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import * as _ from 'underscore';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'name',
    'title',
    'date',
    'status',
    'probability',
    'salesRep',
    'opportunity',
    'forecastType'
  ];
  Company: any;
  QuoteStatus: any;
  companyList: any = [];
  QuoteStatusList: any = [];
  lookupList: any = [];
  searchText: any = '';
  dataSource: any;
  mainDataSouce: any = [];
  showFilter: boolean = false;

  datepicker: any = '';
  @ViewChild(MatSort) sort: any;
  panelOpenState = false;

  constructor(private commonService: CommonProvider, private router: Router) { }
  ngOnInit(): void {
    this.getAllQuotes();
    this.getAllStages();
    this.getAllLookupList();
    localStorage.removeItem("rowData")
  }

  getAllLookupList() {
    this.commonService
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.lookupList = res
          this.QuoteStatusList = res.quotes.quotesStatus;
        }
      });
  }

  getAllStages() {
    this.commonService
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading..')
      .then((res: any) => {
        this.companyList = [];
        if (res.length != 0) {
          let companyName = _.map(res, function (company) {
            return company;
          });
          this.companyList = companyName;
        }
      })
      .catch(error => { });
  }
  getAllQuotes() {
    this.commonService
      .GetMethod(Apiurl.getAllQuotes, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.commonService
            .GetMethod(Apiurl.getAllLookup, null, true, 'Loading')
            .then((resp: any) => {
              if (resp) {
                if (resp.quotes) {
                  for (let i = 0; i < res.length; i++) {
                    let data = _.findWhere(resp.quotes.forecastType, { id: res[i].forecastTypeId })
                    res[i].forecastType = data.forecastTypeDescription

                  }
                  this.mainDataSouce = res;
                  this.dataSource = new MatTableDataSource(res);
                  this.dataSource.sort = this.sort;
                }
                else {
                  this.mainDataSouce = res;
                  this.dataSource = new MatTableDataSource(res);
                  this.dataSource.sort = this.sort;
                }
              }
            });

        }
      });
  }
  addQuotes() {
    this.router.navigate(['add-quotes']);
  }
  inputEvent(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterTypeChange(filterValue);
  }
  openFilter() {
    this.showFilter = !this.showFilter;
  }
  viewRow(row: any) {
    var navigationExtras: NavigationExtras = {
      state: { isEdit: true, qoute_id: row.id }
    };
    this.router.navigate(['add-quotes'], navigationExtras);
  }
  filterTypeChange(event: any) {
    if (event?.target?.value) {
      this.datepicker = event.target.value;
    }
    this.dataSource = new MatTableDataSource(this.mainDataSouce);

    if (this.searchText !== '') {
      this.dataSource.filter = this.searchText.trim().toLowerCase();
    }
    if (this.dataSource.filteredData.length === 0) {
      this.dataSource = new MatTableDataSource(this.mainDataSouce);
    }
    if (this.Company) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, { name: this.Company })
      );
    }
    if (this.QuoteStatus) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, { status: this.QuoteStatus })
      );
    }
    if (this.datepicker) {
      console.log(moment(this.datepicker).format('DD MMM YYYY'));
      let x: any = [];
      _.map(this.dataSource.filteredData, function (res) {
        x.push({ ...res, date: moment(res.date).format('DD MMM YYYY') });
      });
      this.dataSource = new MatTableDataSource(x);

      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, {
          date: moment(this.datepicker).format('DD MMM YYYY')
        })
      );
    }
  }
  clearSearch() {
    this.Company = null;
    this.QuoteStatus = null;
    this.datepicker = null;
    this.dataSource = new MatTableDataSource(this.mainDataSouce);
  }

  sample() {
    alert('yes');
  }
}
