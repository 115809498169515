<div class="content-header">
    <div class="col-lg-6 col-md-6 col-sm-12">
        <h1 class="m-0">John Wayne</h1>
        <p class="m-0">Lead Of Degani Coffee</p>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-right">

        <button mat-raised-button class="green-btn c-btn" *ngIf="!isShowData" (click)="saveAndContinue(true)">
      <span class="material-icons me-2"> save </span>Save
    </button>
        <button mat-raised-button class="green-btn c-btn me-2" *ngIf="isShowData" (click)="editQuotes()">
      <span class="material-icons">
        edit
      </span>
      Edit
    </button>
        <button mat-raised-button class="green-btn c-btn me-2" *ngIf="!isShowData" style="margin-left: 1em;" (click)="cancelEditing()">
      <span class="material-icons me-2"> cancel </span>Cancel
    </button>
        <button mat-raised-button class="theme-bg c-btn" *ngIf="isShowData" (click)="addQuotes()">
      + Add Quotes
    </button>
    </div>
</div>
<div class="opportunities-content" *ngIf="isShowData">
    <div class="row">
        <div class="col-lg-8">
            <div class="w-100">
                <mat-card class="w-100 ">
                    <mat-tab-group mat-align-tabs="start" class="content">
                        <!-- 1 Primary Information -->
                        <mat-tab label="Primary Information">
                            <table class=" w-100">
                                <tr style="font-size: small;color: grey;">
                                    <td>
                                        <mat-label>Customer</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Title</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Date</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Due Date</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Status</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Probability</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- <mat-label>{{ rowData.company }}</mat-label> -->
                                        <mat-label>{{ rowData.companyName }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.title }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.date | date: "dd MMM YYYY" }}
                                        </mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.dueDate | date: "dd MMM YYYY" }}
                                        </mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.status }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.probability }}</mat-label>
                                    </td>
                                </tr>
                                <br />

                                <tr style="font-size: small;color: grey;">
                                    <td>
                                        <mat-label>Expected Close</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Memo</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-label>{{ rowData.expectedClose | date: "dd MMM YYYY" }}
                                        </mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.memo }}</mat-label>
                                    </td>
                                </tr>
                            </table>
                        </mat-tab>
                        <!-- 2 Forecast Information -->
                        <mat-tab label="Firecast Information">
                            <table class=" w-100">
                                <tr style="font-size: small;color: grey;">
                                    <td>
                                        <mat-label>Sales Rep</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Opportunity</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Lead source</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Forecast Type</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-label>{{ rowData.salesRep }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.opportunity }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.leadSource }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.forecastTypeId }}</mat-label>
                                    </td>
                                </tr>
                            </table>
                        </mat-tab>
                        <!-- 3 Customer Classification -->
                        <mat-tab label="Customer Classification">
                            <table class=" w-100">
                                <tr style="font-size: small;color: grey;">
                                    <td>
                                        <mat-label>Market/Industry</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Product Catagory</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Tiers</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-label>{{ rowData.marketIndustry }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.productCategory }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ rowData.tiers }}</mat-label>
                                    </td>
                                </tr>
                            </table>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
            </div>
            <div class="mt-4">
                <table mat-table [dataSource]="opportunityItems" class="mat-elevation-z8 w-100">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef>Quantity On Hand</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.quantity }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>Amount</th>
                        <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayOpportunityItemsInEdit"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayOpportunityItemsInEdit" (click)="selection.toggle(row)"></tr>
                </table>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="w-100">
                <mat-card class="summry-card">
                    <h1 class="primary-text card-heading m-0 mb-3"><b>Summary</b></h1>
                    <!--  // no data-found card -->

                    <!-- <div class="no-content">
                                <h2 class="primary-text c-h2">No Summry data</h2>
                                <p class="secondry-light-text">Please add data to see the summary</p>
                            </div> -->
                    <div class="border-bottom">
                        <div class="summery-content">
                            <p class="secondry-light-text m-0">Services</p>
                            <p class="primary-text m-0">{{ totalServiceAmount }}</p>
                        </div>
                        <div class="summery-content">
                            <p class="secondry-light-text m-0">Hardware</p>
                            <p class="primary-text m-0">{{ totalHardwareAmount }}</p>
                        </div>
                        <!-- <div class="summery-content">
                                        <p class="secondry-light-text m-0">Subscription</p>
                                        <p class="primary-text m-0">1,000.00 AUD</p>
                                    </div>
                                    <div class="summery-content">
                                        <p class="secondry-light-text m-0">Subscription</p>
                                        <p class="primary-text m-0">1,000.00 AUD</p>
                                    </div> -->
                    </div>
                    <div class="pt-2 pb-2 ">
                        <div class="summery-content">
                            <p class="primary-text m-0"><b>GRAND TOTAL</b></p>
                            <p class="green-text m-0">
                                <b>{{ totalServiceAmount + totalHardwareAmount }}</b>
                            </p>
                        </div>
                        <div class="summery-content">
                            <p class="secondry-light-text m-0">Subscription</p>
                            <p class="primary-text m-0">{{ totalSubscriptionAmount }}/wk</p>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="mt-4">
                <mat-card class="related-contact-card ">
                    <div class="d-flex justify-content-between align-items-center ">
                        <h1 class="primary-text card-heading m-0">
                            <b>Related Contacts</b>
                        </h1>
                        <button mat-raised-button *ngIf="isViewData" class="theme-bg c-btn">
              New
            </button>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-10">
                            <mat-form-field class="example-full-width w-100" appearance="outline">
                                <input matInput placeholder="Search" />
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2 h-100">
                            <button mat-raised-button class="btn-link">
                <span class="material-icons">
                  link
                </span>
              </button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center border-bottom pb-2 pt-4">
                        <div>
                            <h2 class="primary-text c-h2 m-0 mb-2">Ms Pam Adamss</h2>
                            <p class="secondry-light-text">venue manager</p>
                        </div>
                        <div class="material-icons">
                            chevron_right
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center border-bottom pb-2 pt-4">
                        <div>
                            <h2 class="primary-text c-h2 m-0 mb-2">Ms Pam Adamss</h2>
                            <p class="secondry-light-text">venue manager</p>
                        </div>
                        <div class="material-icons">
                            chevron_right
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="mt-4">
                <mat-card class="related-contact-card ">
                    <div class="d-flex justify-content-between align-items-center ">
                        <h1 class="primary-text card-heading m-0">
                            <b>Recent Activities </b>
                        </h1>
                        <button mat-raised-button *ngIf="isViewData" class="theme-bg c-btn">
              New
            </button>
                    </div>
                    <div class="d-flex align-items-center border-bottom pb-2 pt-4">
                        <div class="img-wrapper blue">
                            <img src="../../../assets/img/list_icon.svg" />
                        </div>
                        <div>
                            <h2 class="primary-text c-h2 m-0 mb-2">Update Product Pricing</h2>
                            <p class="secondry-light-text">10 Apr 2021 11:24</p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center border-bottom pb-2 pt-4">
                        <div class="img-wrapper orange">
                            <img src="../../../assets/img/call_icon.svg" />
                        </div>
                        <div>
                            <h2 class="primary-text c-h2 m-0 mb-2">Calling samantha</h2>
                            <p class="secondry-light-text">10 Apr 2021 11:24</p>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<div class="opportunities-content" *ngIf="!isShowData">
    <form [formGroup]="opportunitiesForm" (ngSubmit)="onSubmit(opportunitiesForm)">
        <div class="row">
            <div class="col-lg-8">
                <div class="w-100">
                    <mat-accordion class="example-headers-align" multi>
                        <mat-expansion-panel expanded class="border-bottom">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="primary-text">
                                    <b>Primary Information</b>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <!--Customer [formControlName="customerId"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Customer </mat-label>
                                        <mat-select formControlName="customerId">
                                            <mat-option *ngFor="let customer of listCompanies" [value]="customer.id">
                                                {{ customer.tradingName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!-- Title [ formControlName="title"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Title</mat-label>
                                        <input matInput type="text" formControlName="title" />
                                    </mat-form-field>
                                </div>
                                <!--  Date picker [ formControlName="date"]-->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Date</mat-label>
                                        <input formControlName="date" matInput [matDatepicker]="picker" (focus)="picker.open()" readonly />
                                        <mat-icon matSuffix>close</mat-icon>
                                    </mat-form-field>
                                </div>
                                <!--  Status [formControlName="statusId"]-->

                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Status</mat-label>
                                        <mat-select formControlName="statusId">
                                            <mat-option *ngFor="let status of listLookups?.opportunity?.opportunityStatus" [value]="status.id">
                                                {{ status.statusDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!--Probability [formControlName="probability"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Probability</mat-label>
                                        <input formControlName="probability" matInput type="number" />
                                        <mat-icon matSuffix>%</mat-icon>
                                    </mat-form-field>
                                </div>

                                <!--Opportunity Number [formControlName="OpportunityNumber"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Opportunity Number</mat-label>
                                        <input formControlName="OpportunityNumber" placeholder="Will be auto generated" matInput type="number" />
                                    </mat-form-field>
                                </div>

                                <!-- Subsidiary [ formControlName="subsidiary"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Subsidiary</mat-label>
                                        <mat-select formControlName="subsidiaryId">
                                            <mat-option *ngFor="
                          let subsidiary of listLookups?.opportunity?.subsidiaries
                        " [value]="subsidiary.id">
                                                {{ subsidiary.subsidiaryDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!-- win/loss Reason [ formControlName="winlossreason"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">WinLossReason</mat-label>
                                        <mat-select formControlName="winLossReasonId">
                                            <mat-option *ngFor="let winlossreason of listLookups?.opportunity?.winLoss" [value]="winlossreason.id">
                                                {{ winlossreason.winLossDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!-- Sales Rep [ formControlName="salesrep"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Sales Rep</mat-label>
                                        <mat-select formControlName="salesRepId">
                                            <mat-option *ngFor=" let salesrep of listLookups?.opportunity?.salesRep" [value]="salesrep.id">
                                                {{ salesrep.salesRepDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!-- Lead Source [ formControlName="leadsource"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Lead Source</mat-label>
                                        <mat-select formControlName="leadSourceId">
                                            <mat-option *ngFor="let leadsource of listLookups?.company?.leadSource" [value]="leadsource.id">
                                                {{ leadsource.leadTypeDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!--Memo [formControlName="memo"]  -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Memo</mat-label>
                                        <input formControlName="memo" matInput type="text" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel expanded>
                            <mat-expansion-panel-header>
                                <mat-panel-title class="primary-text">
                                    <b>Forecast</b>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Range - Low</mat-label>
                                        <input matInput formControlName="rangeLow" type="number" />
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Range - High</mat-label>
                                        <input matInput type="number" min="1" formControlName="rangeHigh" />
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Projected Total</mat-label>
                                        <input matInput formControlName="projectedTotal" type="number" />
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Weighted Total</mat-label>
                                        <input matInput type="number" min="1" formControlName="weightedTotal" />
                                    </mat-form-field>
                                </div>
                                <!-- Sales Rep [ formControlName="forecasttype"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Forecast Type</mat-label>
                                        <mat-select formControlName="forecastTypeId">
                                            <mat-option *ngFor="let forecast of listLookups?.opportunity?.forecastType" [value]="forecast.id">
                                                {{ forecast.forecastTypeDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!--  -->
                            </div>
                        </mat-expansion-panel>
                        <!--  -->
                        <mat-expansion-panel expanded>
                            <mat-expansion-panel-header>
                                <mat-panel-title class="primary-text">
                                    <b>Customer Classification</b>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <!--Market Industry [ formControlName="marketindustry"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Market Industry</mat-label>
                                        <mat-select formControlName="marketIndustryId">
                                            <mat-option *ngFor="let marketindustry of listLookups?.opportunity?.marketIndustry" [value]="marketindustry.id">
                                                {{ marketindustry.marketIndustryDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!--Product Catagory [ formControlName="productcatagory"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Product Catagory</mat-label>
                                        <mat-select formControlName="productCategoryId">
                                            <mat-option *ngFor="let productcatagory of listLookups?.opportunity?.productCategory" [value]="productcatagory.id">
                                                {{ productcatagory.productCategoryDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <!--Tier Catagory [ formControlName="tier"] -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Tier</mat-label>
                                        <mat-select formControlName="tiersId">
                                            <mat-option *ngFor="let tier of listLookups?.opportunity?.tiers" [value]="tier.id">
                                                {{ tier.tierDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="mt-4">
                    <mat-card class="item-card p-0">
                        <div class="border-bottom w-100 d-flex justify-content-between align-items-center p-3">
                            <h1 class="primary-text card-heading m-0">
                                <b>Item to be Purchased</b>
                            </h1>
                            <div class="material-icons">
                                close
                            </div>
                        </div>
                        <div class="w-100 ">
                            <div class="border-bottom w-100 pb-3 pt-3 mb-4 ps-3 d-flex justify-content-between" *ngIf="!showSavedItemsList">
                                <div class="tab-list-wrapper">
                                    <button mat-stroked-button (click)="goTo('templates')" [ngClass]="{ active: activeTab === 'templates' }">
                    Templates
                  </button>
                                    <button mat-stroked-button (click)="goTo('hardware')" [ngClass]="{ active: activeTab === 'hardware' }">
                    Hardware
                  </button>
                                    <button mat-stroked-button (click)="goTo('services')" [ngClass]="{ active: activeTab === 'services' }">
                    Services
                  </button>
                                    <button mat-stroked-button (click)="goTo('subscriptions')" [ngClass]="{ active: activeTab === 'subscriptions' }">
                    Subscriptions
                  </button>
                                    <div class="c-mat-select-wrapper mb-2">
                                        <mat-form-field appearance="outline">
                                            <mat-icon matPrefix>search</mat-icon>
                                            <mat-label>Search</mat-label>
                                            <input matInput>
                                            <mat-icon matSuffix>close</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="me-3">
                                    <button mat-raised-button (click)="saveAndContinue()" class="theme-bg c-btn">
                    <mat-icon matPrefix>chevron_right</mat-icon>
                    Save & Continue
                  </button>
                                </div>
                            </div>
                            <div class="border-bottom w-100 pb-3 pt-3 ps-3 d-flex justify-content-end" *ngIf="showSavedItemsList">
                                <button mat-stroked-button *ngIf="showSavedItemsList && !showDisCountPage" (click)="goBackToSelectionList()" class="green-btn">
                  <mat-icon matPrefix>chevron_left</mat-icon>
                  Back
                </button>
                                <button mat-stroked-button *ngIf="showDisCountPage" (click)="goBackToSavedItemsList()" class="green-btn">
                  <mat-icon matPrefix>chevron_left</mat-icon>
                  Back
                </button>
                                <button mat-raised-button color="primary" (click)="saveQuantityOfItems()" class="save-btn">
                  <mat-icon matPrefix>chevron_right</mat-icon>
                  Save & Continue
                </button>
                            </div>
                            <!-- <div class="border-bottom w-100 pb-3 pt-3 mb-4 ps-3" *ngIf="showSavedItemsList">
                                <button mat-stroked-button (click)="goBackToTabs()">
                                    <mat-icon matPrefix>chevron_left</mat-icon>
                                    Back
                                </button>
                                <button mat-raised-button class="theme-bg" (click)="saveQuantityOfItems()">
                                    <mat-icon matPrefix>chevron_right</mat-icon>
                                    Save & Continue
                                </button>
                            </div> -->
                            <div class="container" *ngIf="activeTab === 'templates' && !showSavedItemsList && !showDisCountPage">
                                <mat-accordion class="example-headers-align c-accordian" multi>
                                    <mat-expansion-panel *ngFor="let templates of listTemplates">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title class="primary-text">
                                                <mat-checkbox [checked]="templates.selected" matPrefix (change)="calculateTotalValue(templates)" (click)="$event.stopPropagation()" class="px-4">
                                                </mat-checkbox>
                                                {{ templates.templateName }}
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="container p-0">
                                            <mat-list class="p-0">
                                                <mat-list-item *ngFor="let item of templates.items" class="c-mat-list-item">
                                                    <div>{{ item.quantity }} x {{ item.itemName }}</div>
                                                    <div>{{ item.amount || 0.0 }}</div>
                                                </mat-list-item>
                                            </mat-list>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <div class="container" *ngIf="activeTab === 'hardware' && !showSavedItemsList && !showDisCountPage">
                                <table mat-table [dataSource]="listItems" class="mat-elevation-z8 quote-tab">
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="
                          selection.hasValue() && !isAllSelected()
                        " [aria-label]="checkboxLabel()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()" (change)="
                          selection.toggle(row); calculateHardwareItems(row)
                        " (click)="$event.stopPropagation()" [checked]="selection.isSelected(row) || row.selected" [aria-label]="checkboxLabel(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef>Name</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.name }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef>Quantity On Hand</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.name }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="basePrice">
                                        <th mat-header-cell *matHeaderCellDef>Base Price</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.basePrice }}
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
                                </table>
                            </div>
                            <div class="container" *ngIf="activeTab === 'services' && !showSavedItemsList && !showDisCountPage">
                                <table mat-table [dataSource]="listItems" class="mat-elevation-z8 quote-tab">
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="
                          selection.hasValue() && !isAllSelected()
                        " [aria-label]="checkboxLabel()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()" (change)="
                          selection.toggle(row); calculateServiceItems(row)
                        " (click)="$event.stopPropagation()" [checked]="selection.isSelected(row) || row.selected" [aria-label]="checkboxLabel(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef>Name</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.name }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef>Quantity On Hand</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.name }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="basePrice">
                                        <th mat-header-cell *matHeaderCellDef>Base Price</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.basePrice }}
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
                                </table>
                            </div>
                            <div class="container" *ngIf="activeTab === 'subscriptions' && !showSavedItemsList && !showDisCountPage">
                                <table mat-table [dataSource]="listItems" class="mat-elevation-z8 quote-tab">
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="
                          selection.hasValue() && !isAllSelected()
                        " [aria-label]="checkboxLabel()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()" (change)="
                          selection.toggle(row); calculateSubscriptionItems(row)
                        " (click)="$event.stopPropagation()" [checked]="selection.isSelected(row) || row.selected" [aria-label]="checkboxLabel(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef>Name</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.name }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef>Quantity On Hand</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.name }}
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="basePrice">
                                        <th mat-header-cell *matHeaderCellDef>Base Price</th>
                                        <td mat-cell *matCellDef="let element">
                                            {{ element.basePrice }}
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
                                </table>
                            </div>
                        </div>
                        <div class="container" *ngIf="showSavedItemsList && !showDisCountPage">
                            <table mat-table [dataSource]="opportunityItems" class="mat-elevation-z8 w-100    ">
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef>Item</th>
                                    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                                </ng-container>
                                <ng-container matColumnDef="basePrice">
                                    <th mat-header-cell *matHeaderCellDef>Base Price</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.basePrice }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef>Amount</th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.amount }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="quantity">
                                    <th mat-header-cell *matHeaderCellDef>Quantity</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-stroked-button (click)="decreaseItemQuantity(element)">
                      <mat-icon>remove</mat-icon>
                    </button> {{ element.quantity }}
                                        <button mat-stroked-button (click)="increaseItemQuantity(element)">
                      <mat-icon>add</mat-icon>
                    </button>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayOpportunityItems"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayOpportunityItems" (click)="selection.toggle(row)"></tr>
                            </table>
                        </div>
                        <div *ngIf="showDisCountPage" class="col-lg-12">
                            <div class="row m-0 pt-5 pb-5">
                                <div class="col-lg-8 border-right">
                                    <div class="summry-card">
                                        <h1 class="primary-text card-heading m-0 mb-3"><b>Summary</b></h1>
                                        <div class="border-bottom">
                                            <div class="summery-content">
                                                <p class="secondry-light-text m-0">Services</p>
                                                <p class="primary-text m-0">{{totalServiceAmount}}</p>
                                            </div>
                                            <div class="summery-content">
                                                <p class="secondry-light-text m-0">Hardware</p>
                                                <p class="primary-text m-0">{{totalHardwareAmount}}</p>
                                            </div>
                                        </div>
                                        <div class="pt-2 pb-2 ">
                                            <div class="summery-content">
                                                <p class="primary-text m-0"><b>GRAND TOTAL</b></p>
                                                <p class="primary-text m-0"><b>{{totalServiceAmount +
                            totalHardwareAmount}}</b>
                                                </p>
                                            </div>
                                            <div class="summery-content">
                                                <p class="secondry-light-text m-0">Subscription</p>
                                                <p class="primary-text m-0">{{totalSubscriptionAmount}}/wk</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="w-100">
                                        <div class="w-100 d-flex justify-content-between align-items-center mb-3">
                                            <h1 class="primary-text card-heading m-0"><b>Add Discount</b></h1>
                                            <div class="d-flex add-discount">
                                                <button mat-raised-button (click)="discountType = 'dollar'" class="me-2" [ngClass]="{'active': discountType == 'dollar'}">
                          <mat-icon>attach_money</mat-icon>
                        </button>
                                                <button mat-raised-button (click)="discountType = 'percent'" [ngClass]="{'active': discountType == 'percent'}">
                          <mat-icon>%</mat-icon>
                        </button>
                                            </div>
                                        </div>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label class="secondary-text">Discount</mat-label>
                                            <input [formControlName]="discountType == 'dollar'?'dollarDiscount':'percentageDiscount'" matInput type="number">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.08);">
                            <mat-card class="">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="w-100">
                                        <p class="m-0">
                                            {{ totalSelectedCheckBox }} items Selected
                                        </p>
                                    </div>
                                    <!-- <div class="text-right w-100">
                                        <button mat-raised-button class="theme-bg c-btn">
                                            Select
                                        </button>
                                    </div> -->
                                </div>
                            </mat-card>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="w-100">
                    <mat-card class="summry-card">
                        <h1 class="primary-text card-heading m-0 mb-3"><b>Summary</b></h1>
                        <!--  // no data-found card -->

                        <!-- <div class="no-content">
                        <h2 class="primary-text c-h2">No Summry data</h2>
                        <p class="secondry-light-text">Please add data to see the summary</p>
                    </div> -->
                        <div class="border-bottom">
                            <div class="summery-content">
                                <p class="secondry-light-text m-0">Services</p>
                                <p class="primary-text m-0">{{ totalServiceAmount }}</p>
                            </div>
                            <div class="summery-content">
                                <p class="secondry-light-text m-0">Hardware</p>
                                <p class="primary-text m-0">{{ totalHardwareAmount }}</p>
                            </div>
                            <!-- <div class="summery-content">
                                <p class="secondry-light-text m-0">Subscription</p>
                                <p class="primary-text m-0">1,000.00 AUD</p>
                            </div>
                            <div class="summery-content">
                                <p class="secondry-light-text m-0">Subscription</p>
                                <p class="primary-text m-0">1,000.00 AUD</p>
                            </div> -->
                        </div>
                        <div class="pt-2 pb-2 ">
                            <div class="summery-content">
                                <p class="primary-text m-0"><b>GRAND TOTAL</b></p>
                                <p class="green-text m-0">
                                    <b>{{ totalServiceAmount + totalHardwareAmount }}</b>
                                </p>
                            </div>
                            <div class="summery-content">
                                <p class="secondry-light-text m-0">Subscription</p>
                                <p class="primary-text m-0">{{ totalSubscriptionAmount }}/wk</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="mt-4" *ngIf="showExtras">
                    <mat-card class="related-contact-card ">
                        <div class="d-flex justify-content-between align-items-center ">
                            <h1 class="primary-text card-heading m-0">
                                <b>Related Contacts</b>
                            </h1>
                            <button mat-raised-button class="theme-bg c-btn">
                New
              </button>
                        </div>
                        <div class="row mt-3">
                            <div class="col-lg-10">
                                <mat-form-field class="example-full-width w-100" appearance="outline">
                                    <input matInput placeholder="Search" />
                                </mat-form-field>
                            </div>
                            <div class="col-lg-2 h-100">
                                <button mat-raised-button class="btn-link">
                  <span class="material-icons">
                    link
                  </span>
                </button>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2 pt-4">
                            <div>
                                <h2 class="primary-text c-h2 m-0 mb-2">Ms Pam Adamss</h2>
                                <p class="secondry-light-text">venue manager</p>
                            </div>
                            <div class="material-icons">
                                chevron_right
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2 pt-4">
                            <div>
                                <h2 class="primary-text c-h2 m-0 mb-2">Ms Pam Adamss</h2>
                                <p class="secondry-light-text">venue manager</p>
                            </div>
                            <div class="material-icons">
                                chevron_right
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="mt-4" *ngIf="showExtras">
                    <mat-card class="related-contact-card ">
                        <div class="d-flex justify-content-between align-items-center ">
                            <h1 class="primary-text card-heading m-0">
                                <b>Recent Activities </b>
                            </h1>
                            <button mat-raised-button class="theme-bg c-btn">
                New
              </button>
                        </div>
                        <div class="d-flex align-items-center border-bottom pb-2 pt-4">
                            <div class="img-wrapper blue">
                                <img src="../../../assets/img/list_icon.svg" />
                            </div>
                            <div>
                                <h2 class="primary-text c-h2 m-0 mb-2">
                                    Update Product Pricing
                                </h2>
                                <p class="secondry-light-text">10 Apr 2021 11:24</p>
                            </div>
                        </div>
                        <div class="d-flex align-items-center border-bottom pb-2 pt-4">
                            <div class="img-wrapper orange">
                                <img src="../../../assets/img/call_icon.svg" />
                            </div>
                            <div>
                                <h2 class="primary-text c-h2 m-0 mb-2">Calling samantha</h2>
                                <p class="secondry-light-text">10 Apr 2021 11:24</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </form>
</div>