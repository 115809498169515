import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { Router, NavigationExtras } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { CommonProvider } from 'src/app/shared/common';
import { Apiurl } from 'src/app/shared/route';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { LayoutService } from 'src/app/features/layout/layout.service';

@Component({
  selector: 'app-add-quotes',
  templateUrl: './add-quotes.component.html',
  styleUrls: ['./add-quotes.component.scss']
})
export class AddQuotesComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  listCompanies: any = [];
  listLookups: any = [];
  listopportunities: any = [];
  listTemplates: any = [];
  listItems: any = [];
  ContactList: any = [];
  duplicateListItems: any = [];
  quotesForm: FormGroup;
  activeTab: string = 'templates';
  displayedColumns: string[] = ['select', 'name', 'quantity', 'basePrice'];
  displayQuoteItems: string[] = ['name', 'rate', 'amount', 'quantity'];
  displayQuoteItemsInEdit: string[] = ['name', 'quantity', 'amount'];
  selection = new SelectionModel<any>(true, []);
  totalHardwareAmount: number = 0;
  totalServiceAmount: number = 0;
  totalSubscriptionAmount: number = 0;
  totalSelectedCheckBox: number = 0;
  quoteItems: any = [];
  showSavedItemsList: boolean = false;
  quoteItemsID: any;
  rowData: any;
  isShowData: boolean = false;
  isViewData: boolean = false;
  showExtras: boolean = false;
  openBlock: number = 1;
  showDisCountPage: boolean = false;
  discountType: string = 'dollar';
  activityList: any = [];
  serachInput: any = '';
  ContactSearch: any = [];
  quoteList: any = [];
  relatedActivityArray: any = [];

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listItems.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.listItems.map((x: any) => { x.selected = false })
      return;
    }

    this.selection.select(...this.listItems);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  contactData: any = [];

  constructor(
    private commonService: CommonProvider,
    private fb: FormBuilder,
    private router: Router,
    private location: Location,
    private layoutService: LayoutService,
    private SpinnerService: NgxSpinnerService,
  ) {
    this.fetchData();
  };

  ngOnInit(): void {
    this.createQuotesForm();
    this.getAllItemList();
    this.getAllQuotes();
    this.getAllLookupList();
    this.getAllOpportunityList();
    this.getAllTemplateList();
  }


  createQuotesForm() {
    let relatedContactIds: any = []
    var newDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
    this.quotesForm = this.fb.group({
      customerId: [null, Validators.required],
      title: [''],
      date: [new Date()],
      dueDate: [newDate],
      statusId: [9, Validators.required],
      probability: [70],
      salesRepId: [null, Validators.required],
      expectedClose: [new Date()],
      memo: [''],
      subsidiaryId: [6],
      opportunity: [null, Validators.required],
      leadSourceId: [null, Validators.required],
      forecastTypeId: [2],
      originalAmount: [0],
      amountAfterDiscount: [0],
      discountAmount: [0],
      relatedContactIds: [relatedContactIds],
      dollarDiscount: [null],
      percentageDiscount: [null],
    })
  }
  fetchData() {
    if (history.state.navigationId != 1) {
      console.log(history.state)
      if (history.state && history.state.contact_id && history.state.qoute_id) {
        this.isShowData = true;
        this.getAllContact();
      } else if (history.state && history.state.activity_id) {
        this.isShowData = true;
        this.getAllQuotes();
      } else if (history.state && history.state.isEdit) {
        this.isShowData = history.state.isEdit;
        this.getAllContact();
      } else {
        this.getAllCompaniesList();
      }
    }
    else {
      this.router.navigate(['dashboard'])
      setTimeout(() => {
        this.layoutService.activeRoute = this.router.url;
        this.layoutService.getcurrentActivateRoute(
          this.layoutService.activeRoute
        );
      }, 50);
    }
  }
  getAllQuotes() {
    this.commonService
      .GetMethod(Apiurl.getAllQuotes, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.quoteList = res;
          this.getAllActivityList();
        }
      });
  }
  getAllContact() {
    this.commonService
      .GetMethod(Apiurl.getAllQuotes + '/' + history.state.qoute_id, null, true, 'Loading')
      .then(async (res: any) => {
        if (res) {
          this.rowData = res;
          this.getAllCompaniesList();
          this.getItemList(res.id);
          if (history.state.contact_id != null && history.state.contact_id != undefined) {
            if (!res.relatedContactIds.includes(history.state.contact_id)) {
              let contactIDList: any = [];
              this.patchValues();
              contactIDList = this.quotesForm.controls['relatedContactIds'].value
              contactIDList.push(history.state.contact_id);
              this.quotesForm.controls['relatedContactIds'].setValue(contactIDList)
              this.quoteItemsID = history.state.qoute_id;
              this.updateQuotes();
            }
          }
          this.getAllContactList();
        }
      });
  }
  getAllCompaniesList() {
    this.commonService.GetMethod(Apiurl.getAllCompanies, null, true, 'Loading').then((res: any) => {
      if (res) {
        this.listCompanies = res;
        if (history.state && history.state.id && history.state.id.id) {
          this.quotesForm.controls['customerId'].setValue(history.state.id.id)
        }
        if (this.rowData && history.state.isEdit) {
          let companyName = _.findWhere(this.listCompanies, { id: this.rowData.customerId })
          let statusName = _.findWhere(this.listLookups.quotes.quotesStatus, { id: this.rowData.statusId });
          this.rowData.companyName = companyName?.legalName
          this.rowData.status = statusName?.statusDescription;
        }
      }
    })
  }
  getAllActivityList() {
    this.commonService
      .GetMethod(Apiurl.getAllActivties, null, true, 'Loading..')
      .then((resp: any) => {
        if (resp.length != 0) {
          this.activityList = resp;
          this.relatedActivityArray = []
          for (let index = 0; index < this.activityList.length; index++) {
            if (this.activityList[index].transactionType.includes('quote') || this.activityList[index].transactionType.includes('Quote')) {
              if (this.activityList[index].relatedTransactionId != 0) {
                let data = _.findWhere(this.quoteList, { id: this.activityList[index].relatedTransactionId })
                console.log(data.id)
                if (data) {
                  if (data.id == history.state.qoute_id) {
                    this.relatedActivityArray.push(this.activityList[index])
                  }
                }
              }
            }
          }
        }
      });
  }
  getAllContactList() {
    this.commonService
      .GetMethod(Apiurl.getContactList, null, true, 'Loading..')
      .then((resp: any) => {
        if (resp.length != 0) {
          this.ContactList = resp;
          let data: any = [];
          data = this.rowData.relatedContactIds.forEach((id: any) => {
            let contacts = _.findWhere(this.ContactList, { id })
            if (!contacts) return;
            this.contactData.push(contacts)
          })
          this.ContactSearch = this.contactData;
        }
      });
  }
  getAllLookupList() {
    this.commonService.GetMethod(Apiurl.getAllLookup, null, true, 'Loading').then((res: any) => {
      if (res) {
        this.listLookups = res;
      }
    })
  }
  getAllOpportunityList() {
    this.commonService.GetMethod(Apiurl.getAllOpportunityList, null, true, 'Loading').then((res: any) => {
      if (res) {
        this.listopportunities = res
      }
    })
  }
  getAllTemplateList() {
    this.commonService.GetMethod(Apiurl.getAllTemplates, null, true, 'Loading').then((res: any) => {
      if (res) {
        this.listTemplates = res.filter((item: any) => {
          if (item.templateName) {
            item.selected = false;
            item.added = false;
            return true;
          } else {
            return false;
          }
        });
        if (this.isShowData) {
          let templateItemsIds: any = [], quoteItemsIds: any = []
          this.listTemplates.forEach((x: any) => {
            templateItemsIds = _.pluck(x.items, 'itemId')
            quoteItemsIds = _.pluck(this.quoteItems, 'id')
            var check = templateItemsIds.every((el: any) => {
              return quoteItemsIds.indexOf(el) !== -1;
            });
            if (check) {
              x.selected = true;
            } else {
              x.selected = false;
            }
          })
        }
      }
    })
  }
  getAllItemList() {
    this.commonService.GetMethod(Apiurl.getAllItems, null, true, 'Loading').then((res: any) => {
      if (res) {
        this.duplicateListItems = res;
        this.duplicateListItems.map((x: any) => { x.selected = false })
      }
    })
  }
  onSubmit(form: FormGroup) {

  }
  saveAndContinue(shouldRoute?: boolean) {
    // this.showSavedItemsList = true
    // return
    if (this.quotesForm.invalid) return
    if (shouldRoute) {
      this.updateQuotes()

      return;
    }
    if (this.quoteItemsID) {
      this.updateQuotes(true);
      return;
    }
    this.quotesForm.value['quoteItems'] = this.quoteItems;
    this.quotesForm.value['dollarDiscount'] = 0;
    this.quotesForm.value['percentageDiscount'] = 0;
    this.commonService.PostMethod(Apiurl.getAllQuotes, this.quotesForm.value, true, 'Loading', null).then((res: any) => {
      if (!res) return;
      this.quoteItemsID = res
      this.getItemList(res);
    })

  }
  getItemList(id: any) {
    this.commonService.GetMethod(Apiurl.getAllQuotes + '/' + id, null, true, 'Loading').then((res: any) => {
      if (!res) return;
      this.showSavedItemsList = true;
      this.quoteItems = [];
      res.quoteItems.forEach((quotes: any, i: any) => {
        var items = _.findWhere(this.duplicateListItems, { id: quotes.itemId });
        if (!items) return;
        quotes.name = items.name
        quotes.segmentId = items.segmentId
        this.quoteItems.push(quotes);
        if (i === (res.quoteItems.length - 1)) {
          this.calculateSummarForQuotes()
        }
      })
    })
  }
  calculateSummarForQuotes() {
    if (this.isShowData) {
      let quoteItems: any = [];
      this.quoteItems.forEach((y: any) => {
        var x = _.findWhere(this.duplicateListItems, { id: y.itemId });
        if (x.segmentId == 1) {
          x.selected = true;
          y.name = x.name;
          // x.quantity = y.quantity;
          // x.amount = y.amount;
          this.totalHardwareAmount = this.totalHardwareAmount + y.amount;
          quoteItems.push(y)
        } else
          if (x.segmentId == 2) {
            // x.quantity = y.quantity;
            // x.amount = y.amount;
            y.name = x.name;
            x.selected = true;
            this.totalServiceAmount = this.totalServiceAmount + y.amount;
            quoteItems.push(y)
          } else
            if (x.segmentId == 3) {
              x.selected = true;
              y.name = x.name;
              // x.quantity = y.quantity;
              // x.amount = y.amount;
              this.totalSubscriptionAmount = this.totalSubscriptionAmount + y.amount;
              quoteItems.push(y);
            } else {
              x.selected = false;

            }
        this.quoteItems = quoteItems;
      })
    }
  }
  goTo(tab: string) {
    this.activeTab = tab;
    let segmentId: any;
    switch (this.activeTab) {
      case 'hardware':
        segmentId = 1;
        break;
      case 'services':
        segmentId = 2;
        break;
      case 'subscriptions':
        segmentId = 3;
        break;
      default:
        break;
    }
    this.listItems = this.duplicateListItems.filter((item: any) => item.segmentId == segmentId);
    this.listItems.sort((a: any, b: any) => (a.selected > b.selected) ? -1 : 1);
  }
  calculateTotalValue(items: any) {
    items.selected = !items.selected;
    if (items.selected) {
      items.items.forEach((object: any) => {
        object.selected = true;
      })
      this.totalSelectedCheckBox++
      items.added = false
      this.selectItems(items)
    } else {
      items.items.forEach((object: any) => {
        object.selected = false
      })
      this.totalSelectedCheckBox--
      items.added = true;
      this.selectItems(items)
    }
  }
  calculateHardwareItems(items: any) {
    let params = {
      amount: 0,
      itemId: 0,
      quantity: 0,
      rate: 0,
    }
    items.selected = !items.selected;
    this.listTemplates.forEach((x: any) => {
      var itemInTemplates = _.findWhere(x.items, { itemId: items.id });
      if (itemInTemplates) {
        itemInTemplates.selected = !itemInTemplates.selected
      }
      var selectedItems = x.items.filter((object: any) => object.selected === true)
      if (selectedItems.length === x.items.length) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });
    if (items.selected) {
      items.quantity = 1;
      params.quantity = items.quantity;
      params.amount = items.basePrice * items.quantity;
      params.itemId = items.itemId;
      params.rate = items.basePrice;
      this.quoteItems.push(params);
      this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
    } else {
      let index = this.quoteItems.findIndex((x: any) => x.id == items.id);
      if (index < 0) return;
      items.quantity = 0;
      items.selected = false;
      this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
      this.quoteItems.splice(index, 1);
    }
    this.quotesForm.controls['originalAmount'].setValue((this.totalHardwareAmount + this.totalServiceAmount));
    this.quotesForm.controls['amountAfterDiscount'].setValue((this.totalHardwareAmount + this.totalServiceAmount));
    this.listItems.sort((a: any, b: any) => (a.selected > b.selected) ? -1 : 1);
  }
  calculateServiceItems(items: any) {
    items.selected = !items.selected;
    let params = {
      amount: 0,
      itemId: 0,
      quantity: 0,
      rate: 0,
    }
    this.listTemplates.forEach((x: any) => {
      var itemInTemplates = _.findWhere(x.items, { itemId: items.id });
      if (itemInTemplates) {
        itemInTemplates.selected = !itemInTemplates.selected
      }
      var selectedItems = x.items.filter((object: any) => object.selected === true)
      if (selectedItems.length === x.items.length) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });
    if (items.selected) {
      items.quantity = 1;
      params.quantity = items.quantity;
      params.amount = items.basePrice * items.quantity;
      params.itemId = items.itemId;
      params.rate = items.basePrice;
      this.quoteItems.push(params);
      this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
    } else {
      let index = this.quoteItems.findIndex((x: any) => x.id == items.id);
      if (index < 0) return;
      items.quantity = 0;
      items.selected = false;
      this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
      this.quoteItems.splice(index, 1);
    }
    this.quotesForm.controls['originalAmount'].setValue((this.totalHardwareAmount + this.totalServiceAmount));
    this.quotesForm.controls['amountAfterDiscount'].setValue((this.totalHardwareAmount + this.totalServiceAmount));
    this.listItems.sort((a: any, b: any) => (a.selected > b.selected) ? -1 : 1);
  }
  calculateSubscriptionItems(items: any) {
    items.selected = !items.selected;
    let params = {
      amount: 0,
      itemId: 0,
      quantity: 0,
      rate: 0,
    }
    this.listTemplates.forEach((x: any) => {
      var itemInTemplates = _.findWhere(x.items, { itemId: items.id });
      if (itemInTemplates) {
        itemInTemplates.selected = !itemInTemplates.selected
      }
      var selectedItems = x.items.filter((object: any) => object.selected === true)
      if (selectedItems.length === x.items.length) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });
    if (items.selected) {
      items.quantity = 1;
      params.quantity = items.quantity;
      params.amount = items.basePrice * items.quantity;
      params.itemId = items.itemId;
      params.rate = items.basePrice;
      this.quoteItems.push(params);
      this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
    } else {
      let index = this.quoteItems.findIndex((x: any) => x.id == items.id);
      if (index < 0) return;
      items.quantity = 0;
      items.selected = false;
      this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
      this.quoteItems.splice(index, 1);
    }
    this.listItems.sort((a: any, b: any) => (a.selected > b.selected) ? -1 : 1);
  }
  selectItems(templateItems: any) {
    let index;
    templateItems.items.forEach((objs: any) => {
      let params = {
        amount: 0,
        itemId: 0,
        quantity: 0,
        rate: 0,
      }
      var items = _.findWhere(this.duplicateListItems, { id: objs.itemId });
      if (!items) { return }
      if (templateItems.selected && items.segmentId === 1) {
        items.quantity = 1;
        params.quantity = items.quantity;
        params.amount = items.basePrice * items.quantity;
        params.itemId = objs.itemId;
        params.rate = items.basePrice;
        items.selected = true;
        this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
        this.quoteItems.push(params);
      } else if (templateItems.added && items.segmentId === 1) {
        items.selected = false;
        this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
        index = this.quoteItems.findIndex((x: any) => x.id == items.id);
        this.quoteItems.splice(index, 1)
      }
      if (templateItems.selected && items.segmentId === 2) {
        items.quantity = 1;
        params.quantity = items.quantity;
        params.amount = items.basePrice * items.quantity;
        params.itemId = objs.itemId;
        params.rate = items.basePrice;
        items.selected = true;
        this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
        this.quoteItems.push(params);
      } else if (templateItems.added && items.segmentId === 2) {
        items.selected = false;
        this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
        index = this.quoteItems.findIndex((x: any) => x.id == items.id);
        this.quoteItems.splice(index, 1)
      }
      if (templateItems.selected && items.segmentId === 3) {
        items.quantity = 1;
        params.quantity = items.quantity;
        params.amount = items.basePrice * items.quantity;
        params.itemId = objs.itemId;
        params.rate = items.basePrice;
        items.selected = true;
        this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
        this.quoteItems.push(params);
      } else if (templateItems.added && items.segmentId === 3) {
        items.selected = false;
        this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
        index = this.quoteItems.findIndex((x: any) => x.id == items.id);
        this.quoteItems.splice(index, 1)
      }
    })
    this.quotesForm.controls['originalAmount'].setValue((this.totalHardwareAmount + this.totalServiceAmount));
    this.quotesForm.controls['amountAfterDiscount'].setValue((this.totalHardwareAmount + this.totalServiceAmount))
  }
  increaseItemQuantity(item: any) {
    if (item.segmentId === 1) {
      item.quantity++
      item.amount = item.rate * item.quantity;
      this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) + (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    if (item.segmentId === 2) {
      item.quantity++
      item.amount = item.rate * item.quantity;
      this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) + (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    if (item.segmentId === 3) {
      item.quantity++
      item.amount = item.rate * item.quantity;
      this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) + (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    let index = this.quoteItems.findIndex((x: any) => x.id == item.id)
    if (index < 0) { return };
    this.quoteItems[index] = item;
    this.quoteItems = [...this.quoteItems];
  }
  decreaseItemQuantity(item: any) {
    if (item.quantity <= 1) {
      let index = this.quoteItems.findIndex((x: any) => x.id == item.id)
      if (index < 0) { return };
      this.quoteItems[index].selected = false;
      this.quoteItems.splice(index, 1);
      this.quoteItems = [...this.quoteItems];
      item.quantity--
      return;
    }
    if (item.segmentId === 1) {
      item.quantity--
      item.amount = item.rate * item.quantity;
      this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) - (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    if (item.segmentId === 2) {
      item.quantity--
      item.amount = item.rate * item.quantity;
      this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) - (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    if (item.segmentId === 3) {
      item.quantity--
      item.amount = item.rate * item.quantity;
      this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) - (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    let index = this.quoteItems.findIndex((x: any) => x.id == item.id)
    if (index < 0) { return };
    this.quoteItems[index] = item;
    this.quoteItems = [...this.quoteItems];
  }
  goBackToSelectionList() {
    this.showSavedItemsList = false;
  }
  goBackToSavedItemsList() {
    this.showDisCountPage = false;
    this.quoteItems.forEach((quotes: any) => {
      var items = _.findWhere(this.duplicateListItems, { id: quotes.itemId });
      if (!items) return;
      quotes.name = items.name
      quotes.segmentId = items.segmentId
      this.quoteItems.push(quotes);
    })
  }
  updateQuotes(showSavedItemsList: boolean = false) {
    // this.showDisCountPage = true;
    // return
    this.quoteItems.map((quotes: any) => {
      delete quotes?.segmentId;
      delete quotes?.name;
    })

    if (this.rowData) {
      this.quotesForm.value['quoteItems'] = this.rowData.quoteItems;
    } else {
      this.quotesForm.value['quoteItems'] = this.quoteItems;
    }
    this.commonService.PutMethod(Apiurl.getAllQuotes + '/' + this.quoteItemsID, this.quotesForm.value, true, 'Loading', null).then((res: any) => {
      console.log(res)
      if (!res) return;
      if (res) {
        console.log(history.state)
        if (history.state.from == 'company-detail') {
          let param: NavigationExtras = {
            state: {
            id: history.state.id,
              quote_id: this.quoteItemsID
            }
          };
          this.router.navigate(['companies-details'], param)
        } else if (!history.state.isEdit && this.showDisCountPage) {
          this.router.navigate(['quotes']);
        }
        this.showDisCountPage = true;
      }
    })
  }
  OpenBlock(id: number) {
    this.openBlock = id;
  }
  addQuotes() {
    this.isShowData = false;
    this.showExtras = false;
  }
  editQuotes() {
    this.isShowData = false;
    this.isViewData = true;
    this.showExtras = true;
    this.patchValues();
  }
  patchValues() {
    let obj = this.rowData
    this.quotesForm.patchValue({
      customerId: obj.customerId,
      title: obj.title,
      date: obj.date,
      dueDate: obj.dueDate,
      statusId: obj.statusId,
      probability: obj.probability,
      salesRepId: obj.salesRepId,
      expectedClose: obj.expectedClose,
      memo: obj.memo,
      subsidiaryId: obj.subsidiaryId,
      opportunity: obj.opportunity,
      leadSourceId: obj.leadSourceId,
      forecastTypeId: obj.forecastTypeId,
      originalAmount: obj.originalAmount,
      amountAfterDiscount: obj.amountAfterDiscount,
      discountAmount: obj.discountAmount,
      dollarDiscount: obj.dollarDiscount,
      relatedContactIds: obj.relatedContactIds,
      percentageDiscount: obj.percentageDiscount,
    })
  }
  cancelEditing() {
    if (!this.rowData && !history.state.isEdit) {
      this.router.navigate(['quotes'])
      return;
    }
    this.isShowData = true;
  }
  navigateTo(route: string) {
    this.router.navigate([route])
  }
  inputEvent(event: any) {
    this.ContactList = this.ContactSearch;
    var evens = _.filter(this.ContactSearch, function (contact) {
      return (
        contact.name.toLowerCase().indexOf(event.target.value.toLowerCase()) >
        -1
      );
    });

    this.ContactList = evens;
  }

  addContact() {
    let param: NavigationExtras = {
      state: {
        Contact_id: 0,
        from: 'qoute',
        id: this.rowData.customerId,
        qoute_id: this.rowData.id,
      }
    };
    this.router.navigate(['contacts'], param);
  }
  addDiscount(discountType: string) {
    let totalAmount = this.totalHardwareAmount + this.totalServiceAmount;
    let discountAmount
    switch (discountType) {
      case 'dollar':
        discountAmount = this.quotesForm.controls['dollarDiscount'].value;
        totalAmount = totalAmount - this.quotesForm.controls['dollarDiscount'].value;
        if (totalAmount < 0) { totalAmount = 0.00 }
        break;
      case 'percent':
        totalAmount = totalAmount - totalAmount * (this.quotesForm.controls['percentageDiscount'].value / 100);
        discountAmount = totalAmount * (this.quotesForm.controls['percentageDiscount'].value / 100);
        if (totalAmount < 0) { totalAmount = 0 }
        break;

      default:
        break;
    }
    this.quotesForm.controls['amountAfterDiscount'].setValue((totalAmount))
    this.quotesForm.controls['discountAmount'].setValue((discountAmount))
  }
  gotoActivity() {
    let param: NavigationExtras = {
      state: {
        Contact_id: 0,
        from: 'qoute',
        id: this.rowData.customerId,
        qoute_id: this.rowData.id,
        type: 'Event'
      }
    };
    this.router.navigate(['sub-activity'], param);
  }
  setSelectedContact(item: any) {
    console.log(item)
    // history.state.contact_id=item.
    // this.getAllContact()
  }
  selectDiscountType(discountType: string) {
    this.discountType = discountType;
    let totalAmount = this.totalHardwareAmount + this.totalServiceAmount
    this.quotesForm.controls['amountAfterDiscount'].setValue((totalAmount))
    this.quotesForm.controls['percentageDiscount'].setValue(0);
    this.quotesForm.controls['dollarDiscount'].setValue(0.00);
    this.quotesForm.controls['discountAmount'].setValue((0.00))
  }
}
