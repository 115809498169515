import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/features/layout/layout.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  activeRoute: any;
  panelOpenState = false;
  sideMenuToggle: boolean = false;
  // @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, private layoutService: LayoutService) {
    this.activeRoute = this.router.url
    this.layoutService.setObservable().subscribe((data: any) => {
      this.activeRoute = data
    })
  }

  ngOnInit(): void {

  }

  gotoPage(page: any) {
    console.log(page)
    this.router.navigate([page])
    if (!page.includes('/')) {
      this.activeRoute = "/" + page
    } else {
      this.activeRoute = page
    }
  }

  LogOut() {
    //hide for temp.
    // this.layoutService.ResetLoginToken();
    // this.router.navigate(["/login"])
  }
  onSidenavToggle() {
    this.sideMenuToggle = !this.sideMenuToggle
    this.layoutService.sendToggleFlag(this.sideMenuToggle)
  }

}
