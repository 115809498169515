<!-- <app-header></app-header> -->
<div class="content-header">
    <div class="col-lg-8 col-md-8 col-sm-12">
        <div class="cc-header-wrapper">
            <div style="margin-top: 12px;">
                <h2 class="m-0">Contacts</h2>
                <!-- <p class="m-0">{{ TodayDate }}</p> -->
            </div>
            <div class="w-50 pl-17">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-icon matPrefix>search</mat-icon>
                    <mat-label class="f-12">Search</mat-label>
                    <input matInput (keyup)="inputEvent($event)" [(ngModel)]="searchText" />
                    <mat-icon matSuffix (click)="clear()">close</mat-icon>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div *ngIf="mainDataSouce && mainDataSouce.length" class="col-lg-4 col-md-4
    col-sm-12 text-right f-12">
        {{ dataSource?.filteredData?.length }} Records Found
        <button mat-stroked-button class="me-2 c-btn green-btn" (click)="openFilter()">
      <!-- <span class="material-icons me-2"> filter_list </span> -->
      Filter
    </button>
        <button mat-raised-button class="theme-bg c-btn" (click)="AddNewContact()">
      <mat-icon matPrefix class="add-btn">add</mat-icon> &nbsp;Add
    </button>
    </div>
</div>
<div>
    <div class="align-items-center row c-cad">
        <div class="row text-center" *ngIf="showFilter">
            <div class="col-md-2"></div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Company</mat-label>
                    <mat-select [(ngModel)]="Company" [ngModelOptions]="{ standalone: true
            }">
                        <mat-option *ngFor="let obj of companyList" [value]="obj.legalName" (click)="filterTypeChange(obj, 'company')">
                            {{ obj.legalName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Role</mat-label>
                    <mat-select [(ngModel)]="Role" [ngModelOptions]="{ standalone: true
            }">
                        <mat-option *ngFor="let obj of roleList" [value]="obj.roleDescription" (click)="filterTypeChange(obj,
              'role')">
                            {{ obj.roleDescription }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-2" style="margin-top:8px">
                <div class="row">
                    <div class="col-md-6 p-0">
                        <button mat-raised-button color="warn" class="c-btn w-100" (click)="clearSearch()">
              Clear All
            </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contact-list-content">
    <div class="contact-content">
        <mat-card>
            <!-- <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-icon matPrefix>search</mat-icon>
            <mat-label>Search</mat-label>
            <input  matInput (keyup)="inputEvent($event)"   [(ngModel)]="searchText"    />
            <mat-icon matSuffix (click)="clear()" *ngIf="searchText"
            >close</mat-icon
          >
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 text-right">
          <div class="d-flex align-items-center justify-content-end flex-wrap">
            <div *ngIf="mainDataSouce && mainDataSouce.length" class="me-2">
              {{ dataSource?.filteredData?.length }} Records Found
            </div>
            <button
              mat-stroked-button
              class="me-2 c-btn"
              (click)="openFilter()"
            >
              <span class="material-icons me-2"> filter_list </span>Filter
            </button>
            <button
              mat-raised-button
              class="theme-bg c-btn "
              (click)="AddNewContact()"
            >
              <mat-icon matPrefix class="add-btn">add</mat-icon> &nbsp;Add
            </button>
          </div>
        </div>
      </div>
      <div class="row text-center" *ngIf="showFilter">
        <div class="col-md-2"></div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Company</mat-label>
            <mat-select
              [(ngModel)]="Company"
              [ngModelOptions]="{ standalone: true }"
            >
              <mat-option
                *ngFor="let obj of companyList"
                [value]="obj.legalName"
                (click)="filterTypeChange(obj, 'company')"
              >
                {{ obj.legalName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Role</mat-label>
            <mat-select
              [(ngModel)]="Role"
              [ngModelOptions]="{ standalone: true }"
            >
              <mat-option
                *ngFor="let obj of roleList"
                [value]="obj.roleDescription"
                (click)="filterTypeChange(obj, 'role')"
              >
                {{ obj.roleDescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <button
            mat-stroked-button
            color="primary"
            class="col-md-2 c-btn green-btn  btn-height w-100"
            (click)="clearSearch()"
          >
            Clear All
          </button>
        </div>
        <div class="col-md-2"></div>
      </div> -->

            <table mat-table [dataSource]="dataSource" class=" w-100 contact_list-table" matSort>
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
                    <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
                </ng-container>
                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>last Name</th>
                    <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
                </ng-container>

                <ng-container matColumnDef="companyName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        company Name
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
                    <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
                </ng-container>
                <ng-container matColumnDef="jobTitle">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
                    <td mat-cell *matCellDef="let element">{{ element.jobTitle }}</td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
                    <td mat-cell *matCellDef="let element">{{ element.role }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection(row)"></tr>
            </table>
        </mat-card>
    </div>
</div>