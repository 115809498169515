<div class="header-logo" [ngClass]="{'sidebar-header-logo': sideMenuToggle}">
    <div class=" sidebar-logo" *ngIf="!sideMenuToggle">
        <img src="../../../assets/img/Bepoz_logo.svg" />
    </div>
    <button mat-icon-button class="header-menu" (click)="onSidenavToggle()">
        <mat-icon style="color: #000000;" *ngIf="!sideMenuToggle">menu</mat-icon>
        <mat-icon style="color: #000000;" *ngIf="sideMenuToggle">menu_open</mat-icon>
    </button>
</div>
<mat-nav-list class="sidebar-inner" [ngClass]="{'sidebar-icon': sideMenuToggle}">
    <a mat-list-item routerLinkActive="list-item-active" (click)="gotoPage('dashboard')" [ngClass]="{ active: activeRoute == '/dashboard' }">
        <img [src]="
        activeRoute != '/dashboard'
          ? '../../../assets/img/dashboard.svg'
          : '../../../assets/img/dashboard_active.svg'
      " />
        <div class="p-left primary-text" *ngIf="!sideMenuToggle">Dashboard</div>
    </a>
    <div>
        <div class="name-warpper secondary-text" *ngIf="!sideMenuToggle">LISTS</div>
        <a mat-list-item [ngClass]="{ active: activeRoute == '/companies'  || activeRoute.includes('/companies-details') || activeRoute == '/add-company' }" (click)="gotoPage('companies')">
            <img [src]="
          activeRoute == '/companies'
            ? '../../../assets/img/companies_active.svg'
            : '../../../assets/img/companies.svg'
        " />
            <div class="p-left primary-text" *ngIf="!sideMenuToggle">Companies</div>
        </a>

        <a mat-list-item [ngClass]="{
        active: activeRoute == '/contacts-list' || activeRoute == '/contacts'
      }" (click)="gotoPage('contacts-list')">
            <img [src]="
          activeRoute == '/contacts-list'
            ? '../../../assets/img/contact_active.svg'
            : '../../../assets/img/contact.svg'
        " />
            <div class="p-left primary-text" *ngIf="!sideMenuToggle">Contacts</div>
        </a>
    </div>
    <div>
        <div class="name-warpper secondary-text" *ngIf="!sideMenuToggle">
            TRANSACTION
        </div>
  
        <a mat-list-item [ngClass]="{
        active:
          activeRoute == '/opportunities' || activeRoute == '/add-opportunities'
      }" (click)="gotoPage('opportunities')">
            <img [src]="
          activeRoute == '/opportunities'
            ? '../../../assets/img/opp_active.svg'
            : '../../../assets/img/opp.svg'
        " />
            <div class="p-left primary-text" *ngIf="!sideMenuToggle">
                Opportunities
            </div>
        </a>
        <a mat-list-item [ngClass]="{
            active: activeRoute == '/quotes' || activeRoute == '/add-quotes'
          }" (click)="gotoPage('quotes')">
                <img [src]="
              activeRoute == '/quotes'
                ? '../../../assets/img/quotes_active.svg'
                : '../../../assets/img/quotes.svg'
            " />
                <div class="p-left primary-text" *ngIf="!sideMenuToggle">Quotes</div>
            </a>
    </div>
    <div>
        <div class="name-warpper secondary-text" *ngIf="!sideMenuToggle">
            ACTIVITIES
        </div>
        <a mat-list-item [ngClass]="{ active: activeRoute == '/activities' || activeRoute == '/sub-activity' }" (click)="gotoPage('activities')">
            <img [src]="
          activeRoute == '/activities'
            ? '../../../assets/img/activity_active.svg'
            : '../../../assets/img/activity.svg'
        " />
            <div class="p-left primary-text" *ngIf="!sideMenuToggle">Activities</div>
        </a>
    </div>
    <!-- <div>
        <div class="name-warpper secondary-text" *ngIf="!sideMenuToggle">
            OTHERS
        </div>
        <a mat-list-item [ngClass]="{ active: activeRoute == '/help' }" (click)="gotoPage('help')">
            <img [src]="
          activeRoute == '/help'
            ? '../../../assets/img/help_active.svg'
            : '../../../assets/img/help.svg'
        " />
            <div class="p-left primary-text" *ngIf="!sideMenuToggle">Help</div>
        </a>
        <a mat-list-item [ngClass]="{ active: activeRoute == '/setting' }" (click)="gotoPage('setting')">
            <img [src]="
          activeRoute == '/setting'
            ? '../../../assets/img/setting_active.svg'
            : '../../../assets/img/setting.svg'
        " />
            <div class="p-left primary-text" *ngIf="!sideMenuToggle">Setting</div>
        </a>
    </div> -->
</mat-nav-list>
<div class="sidebar-footer" [ngClass]="{'sidebar-footer-icon': sideMenuToggle}">
    <p class="m-0 primary-text " (click)="LogOut()" *ngIf="!sideMenuToggle">Sign Out</p>
    <div class="material-icons" *ngIf="sideMenuToggle">
        logout
    </div>
    <img src="../../../assets/img/Text-Copyright.svg" class="pt-3 pb-2" *ngIf="!sideMenuToggle" />
</div>