<div id="fab-dismiss" *ngIf="fabTogglerState==='active'" (click)="onToggleFab()">
</div>
<div class='fab-container' [style.flexDirection]="reverseColumnDirection?'column-reverse':'column'">
    <button mat-fab class="fab-toggler" (click)="onToggleFab()">
        <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">add</i>
    </button>
    <div [className]="reverseColumnDirection?'column-reverse':'column'" [@speedDialStagger]="buttons.length">
        <button *ngFor="let btn of buttons" mat-mini-fab matTooltip="{{btn.tooltip}}" class="fab-secondary" color="secondary" (click)="onClickFab(btn)">
            <i class="material-icons">{{btn.icon}}</i>
        </button>
    </div>
</div>