import { LayoutService } from 'src/app/features/layout/layout.service';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as moment from 'moment';
import { CommonProvider } from '../../../shared/common';
import { Apiurl } from '../../../shared/route';
import * as _ from 'underscore';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sub-activity',
  templateUrl: './sub-activity.component.html',
  styleUrls: ['./sub-activity.component.scss']
})
export class SubActivityComponent implements OnInit {
  subActivityForm: FormGroup;
  listCompanies: any = [];
  listContacts: any = [];
  listContactIds: any = [];
  activity_id: any = 0;
  companyListSearch: any = [];
  listQutoes: any = [];
  listopportunities: any = [];
  listTransactions: any = [];
  listParticipants: any = [];
  editflag: Boolean = false;
  listLookups: any = [];
  isSheduled: Boolean = false;
  isCompleted: Boolean = false;
  companyId: any;

  type: any;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonProvider,
    private router: Router,
    private route: ActivatedRoute,
    private layoutService: LayoutService,
    private SpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.salaryFormSet();
    this.getAllCompaniesList();
    this.getAllLookups();
    if (history.state.navigationId != 1) {
      console.log(history.state);
      if (history && history.state) {
        if (history.state.type != null && history.state.type != undefined) {
          this.type = history.state.type;
          if (
            history.state.activity_id != null &&
            history.state.activity_id != undefined &&
            history.state.activity_id > 0
          ) {
            this.activity_id = history.state.activity_id;
            this.getActivityById();
            this.getAllCompaniesList();
            this.editflag = true;
          }
          if (
            history.state.Contact_id != null &&
            history.state.Contact_id != undefined
          ) {
            this.subActivityForm.controls['contact'].setValue(
              history.state.Contact_id
            );
            this.subActivityForm.controls['contact'].updateValueAndValidity();
          }
        }
      }
    } else {
      this.router.navigate(['dashboard']);
      setTimeout(() => {
        this.layoutService.activeRoute = this.router.url;
        this.layoutService.getcurrentActivateRoute(
          this.layoutService.activeRoute
        );
      }, 50);
    }
  }
  salaryFormSet() {
    this.subActivityForm = this.fb.group({
      company: ['', Validators.required],
      companyId: [''],
      id: [0],
      transaction: [''],
      contact: ['0'],
      title: ['', Validators.required],
      date: [new Date(), Validators.required],
      description: [''],
      phoneNumber: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
      transactionType: [''],
      relatedTransactionId: [''],
      salesRepId: [0],
      statusId: [0]
    });
  }
  getAllCompaniesList() {
    this.commonService
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.listCompanies = res;
          this.companyListSearch = res;
          if (this.editflag) {
            this.subActivityForm.controls['company'].setValue(
              this.listCompanies
                .filter((x: any) => x.id == this.companyId)
                .map((x: any) => x.legalName)[0]
            );
          }
          if (history.state.id != null && history.state.id != undefined) {
            let data = _.findWhere(this.listCompanies, { id: history.state.id })
            console.log(data)
            this.subActivityForm.controls['companyId'].setValue(
              history.state.id
            );
            this.subActivityForm.controls['companyId'].updateValueAndValidity();
            this.subActivityForm.controls['company'].setValue(
              data.legalName
            );
            this.subActivityForm.controls['company'].updateValueAndValidity();
            this.getCompanyBaseData();
            this.getAllContactList();
          }
        }
      });
  }
  getCompanyBaseData() {
    this.listTransactions = [];
    this.getAllOpportunityList();
    this.getAllQuoteList();
  }
  getAllContactList() {
    return new Promise((resolve, reject) => {
      var companyId = this.subActivityForm.get('companyId')?.value;
      this.listContacts = [];
      this.commonService
        .GetMethod(Apiurl.getContactList, null, true, 'Loading')
        .then((res: any) => {
          if (res) {
            for (let i = 0; i < res.length; i++) {
              if (res[i].companyId == companyId) {
                res[i].isSelect = false;
                this.listContacts.push(res[i]);
                this.listContactIds.push(res[i].id);
                if (!this.editflag) {
                  if (res[i].role == 'Primary Contact') {
                    this.subActivityForm.controls['contact'].setValue(
                      res[i].id
                    );
                  }
                }
              }
            }
            if (this.listContacts.length == 1) {
              this.subActivityForm.controls['contact'].setValue(
                this.listContacts[0].id
              );
            }
            console.log(this.listContacts);

            resolve(this.listContacts);
          }
        });
    });
  }
  getAllLookups() {
    this.listLookups = [];
    this.commonService
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading..')
      .then((res: any) => {
        this.listLookups = res;
      });
  }

  getAllQuoteList() {
    var companyId = this.subActivityForm.get('companyId')?.value;
    this.listQutoes = [];
    this.commonService
      .GetMethod(Apiurl.getAllQuotes, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            if (
              res[i].customerId == companyId &&
              res[i].documentNumber != null &&
              res[i].documentNumber != ''
            ) {
              res[i].type = 'Quotes' + '-' + res[i].id;
              res[i].typeval = res[i].documentNumber + '-' + 'Quotes';
              this.listQutoes.push(res[i]);
              this.listTransactions.push(res[i]);
            }
          }
          if (this.listTransactions) {
            for (let index = 0; index < this.listTransactions.length; index++) {
              if (
                history.state.qoute_id != null &&
                history.state.qoute_id != undefined &&
                this.listTransactions[index].id == history.state.qoute_id
              ) {
                this.subActivityForm.controls['relatedTransactionId'].setValue(
                  history.state.qoute_id
                );
                this.subActivityForm.controls[
                  'relatedTransactionId'
                ].updateValueAndValidity();
                this.subActivityForm.controls['transaction'].setValue(
                  this.listTransactions[index].type
                );
                this.subActivityForm.controls[
                  'transaction'
                ].updateValueAndValidity();
                this.subActivityForm.controls['transactionType'].setValue(
                  'Quotes'
                );
                this.subActivityForm.controls[
                  'transactionType'
                ].updateValueAndValidity();
              }
            }
          }
        }
      });
  }
  getAllOpportunityList() {
    var companyId = this.subActivityForm.get('companyId')?.value;
    this.listopportunities = [];
    this.commonService
      .GetMethod(Apiurl.getAllOpportunityList, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            if (
              res[i].customerId == companyId &&
              res[i].documentNumber != null &&
              res[i].documentNumber != ''
            ) {
              res[i].type = 'Opportunities' + '-' + res[i].id;
              res[i].typeval = res[i].documentNumber + '-' + 'Opportunities';
              this.listopportunities.push(res[i]);
              this.listTransactions.push(res[i]);
              console.log(this.listTransactions);
            }
          }
        }
      });
  }
  getTransactionType(event: any) {
    var type: any;
    var id: any;

    if (event.value) {
      var arr = event.value.split('-');
      type = arr[0];
      id = arr[1];

      this.subActivityForm.controls['relatedTransactionId'].setValue(id);
      this.subActivityForm.controls['transactionType'].setValue(type);
    }
  }
  SelectParticipant(contactInfo: any) {
    if (contactInfo.isSelect == false) {
      contactInfo.isSelect = true;
    } else if (contactInfo.isSelect == true) {
      contactInfo.isSelect = false;
    }
  }
  SaveData() {
    this.SpinnerService.show();
    this.listParticipants = [];
    for (let i = 0; i < this.listContacts.length; i++) {
      if (this.listContacts[i].isSelect == true) {
        this.listParticipants.push(this.listContacts[i].id);
      }
    }
    console.log(this.subActivityForm.get('salesRepId')?.value);

    var params = {
      salesRepId: this.subActivityForm.get('salesRepId')?.value,
      type: this.type,
      title: this.subActivityForm.get('title')?.value,
      startDate: this.subActivityForm.get('date')?.value,
      startTime: this.subActivityForm.get('startTime')?.value,
      endTime: this.subActivityForm.get('endTime')?.value,
      relatedCustomerId: parseInt(this.subActivityForm.get('companyId')?.value)
        ? parseInt(this.subActivityForm.get('companyId')?.value)
        : 0,
      relatedContactId: parseInt(this.subActivityForm.get('contact')?.value)
        ? parseInt(this.subActivityForm.get('contact')?.value)
        : 0,
      relatedTransactionId: parseInt(
        this.subActivityForm.get('relatedTransactionId')?.value
      )
        ? parseInt(this.subActivityForm.get('relatedTransactionId')?.value)
        : 0,
      message: '',
      statusId: parseInt(this.subActivityForm.get('statusId')?.value)
        ? parseInt(this.subActivityForm.get('statusId')?.value)
        : 0,
      transactionType: this.subActivityForm.get('transactionType')?.value,
      participantIds: this.listParticipants,
      netSuiteId: 0,
      accountId: 0,
      createBy: '',
      createDate: new Date().toISOString(),
      updatedBy: '',
      updatedDate: new Date().toISOString(),
      dateCreated: new Date().toISOString(),
      endDate: new Date().toISOString(),
      id: this.subActivityForm.get('id')?.value
    };

    if (this.subActivityForm.get('id')?.value > 0) {
      this.commonService
        .PutMethod(
          Apiurl.getAllActivties + '/' + this.subActivityForm.get('id')?.value,
          params,
          true,
          'Loading..',
          null
        )
        .then((resp: any) => {
          if (resp) {
            this.SpinnerService.hide();
            Swal.fire(
              'Success',
              'Activity Updated successfully',
              'success'
            ).then(() => {
              if (history && history.state.id) {
                let id = history.state.id;
                let param: NavigationExtras;
                if (history.state.qoute_id) {
                  param = {
                    state: {
                      id: id,
                      activity_id: resp,
                      Contact_id: history.state.Contact_id,
                      qoute_id: history.state.qoute_id
                    }
                  };
                } else {
                  param = {
                    state: {
                      id: id,
                      activity_id: resp,
                      Contact_id: history.state.Contact_id
                    }
                  };
                }
                if (history.state.from == 'company-detail') {
                  this.router.navigate(['companies-details'], param);
                }
                if (history.state.from == 'contact') {
                  this.router.navigate(['contacts'], param);
                }
                if (history.state.from == 'qoute') {
                  this.router.navigate(['add-quotes'], param);
                }
              } else {
                let param: NavigationExtras = {
                  state: {
                    type: history.state.type,
                    activity_id: history.state.activity_id
                    // from: 'activity-details'
                  }
                };

                if (history.state.from == 'activity-details') {
                  this.router.navigate(['activity-details'], param);
                } else if (history.state.from == 'dashboard') {
                  this.router.navigate(['dashboard']);
                } else {
                  this.router.navigate(['activities']);
                }
              }
            });
          } else {
            Swal.fire(
              'Error',
              'Failed to Update Activity',
              'error'
            ).then(() => { });
          }
        });
    } else {
      this.commonService
        .PostMethod(Apiurl.saveActivity, params, false, 'Loading.', null)
        .then((responsedata: any) => {
          this.SpinnerService.hide();
          if (responsedata > 0) {
            Swal.fire('Success', 'Activity Save successfully', 'success').then(
              () => {
                // this.router.navigate(["activities"]);
                if (history && history.state.id) {
                  let id = history.state.id;
                  let param: NavigationExtras;
                  if (history.state.qoute_id) {
                    param = {
                      state: {
                        id: id,
                        activity_id: responsedata,
                        Contact_id: history.state.Contact_id,
                        qoute_id: history.state.qoute_id
                      }
                    };
                  } else {
                    param = {
                      state: {
                        id: id,
                        activity_id: responsedata,
                        Contact_id: history.state.Contact_id
                      }
                    };
                  }
                  if (history.state.from == 'company-detail') {
                    this.router.navigate(['companies-details'], param);
                  }
                  if (history.state.from == 'contact') {
                    this.router.navigate(['contacts'], param);
                  }
                  if (history.state.from == 'qoute') {
                    this.router.navigate(['add-quotes'], param);
                  }
                } else {
                  let param: NavigationExtras = {
                    state: {
                      type: history.state.type,
                      activity_id: history.state.activity_id
                      // from: 'activity-details'
                    }
                  };

                  if (history.state.from == 'activity-details') {
                    this.router.navigate(['activity-details'], param);
                  } else if (history.state.from == 'dashboard') {
                    this.router.navigate(['dashboard']);
                  } else {
                    this.router.navigate(['activities']);
                  }
                }
              }
            );
          } else {
            Swal.fire(
              'Error',
              'Failed to Save Activity',
              'error'
            ).then(() => { });
          }
        });
    }
  }
  async ViewActivity() {
    let obj = {
      title: 'Are you sure you want to Cancel?',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes'
    };
    await this.commonService.showConfirm(obj).then((resp: any) => {
      if (resp.isConfirmed) {
        this.router.navigate(['activities']);
      }
    });
  }
  async getActivityById() {
    await this.commonService
      .GetMethod(
        Apiurl.GetAllActivities + '/' + this.activity_id,
        null,
        true,
        'Loading'
      )
      .then(async (res: any) => {
        if (res) {
          this.companyId = res.relatedCustomerId;
          this.subActivityForm.controls['id'].setValue(res.id);
          this.subActivityForm.controls['contact'].setValue(
            res.relatedContactId
          );
          this.subActivityForm.controls['title'].setValue(res.title);
          this.subActivityForm.controls['date'].setValue(res.startDate);
          this.subActivityForm.controls['startTime'].setValue(
            new Date(res.startTime)
          );
          this.subActivityForm.controls['endTime'].setValue(
            new Date(res.endTime)
          );
          this.subActivityForm.controls['companyId'].setValue(
            res.relatedCustomerId
          );
          // for (var k = 0; k < this.listCompanies.length; k++) {
          //   console.log();

          //   if (this.listCompanies[k].id == res.relatedCustomerId) {
          //     this.subActivityForm.controls["company"].setValue(this.listCompanies[k].legalName);
          //     this.subActivityForm.controls['companyId'].setValue(res.relatedCustomerId)
          //   }
          // }
          console.log(this.subActivityForm.value);
          //        console.log(this.subActivityForm.controls["companyId"]);
          this.getCompanyBaseData();
          await this.getAllContactList();
          this.subActivityForm.controls['contact'].setValue(
            res.relatedContactId
          );
          this.subActivityForm.controls['transactionType'].setValue(
            res.transactionType
          );
          this.subActivityForm.controls['relatedTransactionId'].setValue(
            res.relatedTransactionId
          );
          console.log(res);

          this.subActivityForm.controls['salesRepId'].setValue(res.salesRepId);

          this.subActivityForm.controls['transaction'].setValue(
            res.transactionType + '-' + res.relatedTransactionId
          );
          if (res.statusId > 0) {
            this.subActivityForm.controls['statusId'].setValue(res.statusId);
            if (res.statusId == 3) {
              this.isSheduled = true;
              this.isCompleted = false;
            }
            if (res.statusId == 1) {
              this.isSheduled = false;
              this.isCompleted = true;
            }
          }
          // for (let j = 0; j < res.participantIds.length; j++) {
          //   for (let i = 0; i < this.listContacts.length; i++) {

          //     if (this.listContacts[i].id == res.participantIds[j]) {
          //       this.listContacts[i].isSelect = true;
          //       this.listParticipants.push(this.listContacts[i].id);
          //     }
          //   }

          // }
          res.participantIds.forEach((element: any) => {
            this.listContacts.forEach((element1: any) => {
              if (element1.id == element) {
                element1.isSelect = true;
                this.listParticipants.push(element1.id);
              }
            });
          });
        }
      });
  }
  searchCompany(event: any) {
    if (event.target.value != '') {
      this.companyListSearch = this.listCompanies.filter(
        (s: any) =>
          s.legalName
            .toLowerCase()
            .indexOf(event.target.value.toLowerCase()) !== -1
      );
    } else {
      this.companyListSearch = this.listCompanies;
    }
  }
  setCompany(obj: any) {
    this.subActivityForm.controls['companyId'].setValue(obj.id);
  }
  setActivityStatus(id: any) {
    console.log(id);
    this.subActivityForm.controls['statusId'].setValue(id);
    if (id == 3) {
      this.isSheduled = true;
      this.isCompleted = false;
    } else {
      this.isSheduled = false;
      this.isCompleted = true;
    }
  }
}
