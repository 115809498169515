import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-delete-content',
  templateUrl: './delete-content.component.html',
  styleUrls: ['./delete-content.component.scss']
})
export class DeleteContentComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteContentComponent>
  ) { }

  ngOnInit(): void {
  }

  //not delete
  noClick() {
    this.dialogRef.close("No")
  }

  //delete
  okClick() {
    this.dialogRef.close("Ok")
  }

}
