import Swal from 'sweetalert2';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Apiurl } from 'src/app/shared/route';
import { CommonProvider } from 'src/app/shared/common';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import * as _ from 'underscore';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  addCompanyForm: FormGroup;
  listLookups: any = [];
  companyList: any = [];
  companyListSearch: any = [];
  billingPostCodeLength = 0;
  billingPostCodeData: any;
  shippingPostCodeLength = 0;
  shippingPostCodeData: any;
  editflag: Boolean = false
  parentId: any;
  id: any;
  phoneLength = 0;
  phoneData: any;
  submitted: boolean = false;
  allExpand: boolean = false;
  firstExpand: boolean = true;
  searchdropdown :any;
  constructor(
    private fb: FormBuilder,
    private commonService: CommonProvider,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.addCompanyForm = this.createCompanyForm();
    this.getAllCompanies();
    this.getAllLookups();
    this.route.queryParams.subscribe(params => {
      if (params.id != undefined) {
        this.id = params.id;
        this.setCompanyData(this.id);
        this.editflag = true
      }
    });
  }

  createCompanyForm() {
    return this.fb.group({
      stageTypeId: [1, Validators.required],
      companyName: ['', Validators.required],
      parentId: [''],
      parentType: [''],
      tradingName: [''],
      leadSourceId: [0],
      leadStatusId: [30],
      salesRepId: [0],
      subsidiaryId: [6],
      comments: [''],
      email: ['', Validators.email],
      phone: [''],
      marketId: ['', Validators.required],
      tierId: ['', Validators.required],
      productCategoryId: ['', Validators.required],
      softwareId: [0],
      billingAddressLine1: [''],
      billingAddressLine2: [''],
      billingCity: [''],
      billingPostCode: [''],
      billingState: [0],
      billingCountry: [1],
      shippingAddressLine1: [''],
      shippingAddressLine2: [''],
      shippingCity: [''],
      shippingPostCode: [''],
      shippingState: [0],
      shippingCountry: [1],
      relatedContactIds: [''],
    })
  }

  setCompanyData(id: any) {
    this.commonService
      .GetMethod(Apiurl.getAllCompanies + '/' + id, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.parentId = res.parentId
          this.addCompanyForm.controls['companyName'].setValue(res.legalName)
          this.addCompanyForm.controls['stageTypeId'].setValue(res.stageId)
          this.addCompanyForm.controls['parentId'].setValue(res.parentId)
          this.addCompanyForm.controls['tradingName'].setValue(res.tradingName)
          this.addCompanyForm.controls['leadSourceId'].setValue(res.leadSourceId)
          this.addCompanyForm.controls['leadStatusId'].setValue(res.leadStatusId)
          this.addCompanyForm.controls['salesRepId'].setValue(res.salesRepId)
          this.addCompanyForm.controls['subsidiaryId'].setValue(res.subsidiaryId)
          this.addCompanyForm.controls['comments'].setValue(res.comments)
          this.addCompanyForm.controls['email'].setValue(res.email)
          this.addCompanyForm.controls['phone'].setValue(res.phone)
          this.addCompanyForm.controls['marketId'].setValue(res.marketIndustryId)
          this.addCompanyForm.controls['tierId'].setValue(res.tierId)
          this.addCompanyForm.controls['productCategoryId'].setValue(res.productCategoryId)
          this.addCompanyForm.controls['softwareId'].setValue(res.softwareId)
          this.addCompanyForm.controls['billingAddressLine1'].setValue(res.billingAddress?.addressLine1)
          this.addCompanyForm.controls['billingAddressLine2'].setValue(res.billingAddress?.addressLine2)
          this.addCompanyForm.controls['billingCity'].setValue(res.billingAddress?.city)
          this.addCompanyForm.controls['billingPostCode'].setValue(res.billingAddress?.postCode)
          this.addCompanyForm.controls['billingState'].setValue(res.billingAddress?.stateId)
          this.addCompanyForm.controls['billingCountry'].setValue(res.billingAddress?.countryId)
          this.addCompanyForm.controls['shippingAddressLine1'].setValue(res.shippingAddress?.addressLine1)
          this.addCompanyForm.controls['shippingAddressLine2'].setValue(res.shippingAddress?.addressLine2)
          this.addCompanyForm.controls['shippingCity'].setValue(res.shippingAddress?.city)
          this.addCompanyForm.controls['shippingPostCode'].setValue(res.billingAddress?.postCode)
          this.addCompanyForm.controls['shippingState'].setValue(res.shippingAddress?.stateId)
          this.addCompanyForm.controls['shippingCountry'].setValue(res.shippingAddress?.countryId)
          this.addCompanyForm.controls['relatedContactIds'].setValue(res.relatedContactIds)

        }
        //this.companyList.filter((x  :any)=>x.id ==res.parentId ).map((x  :any)=>x.legalName)[0];
        //console.log( this.companyList.filter((x  :any)=>x.id ==res.parentId ).map((x  :any)=>x.legalName)[0],'ParentName');
        let name = _.findWhere(this.companyList, { id: res.parentId })
      });
  }

  getAllLookups() {
    this.listLookups = [];
    this.commonService
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading..')
      .then((res: any) => {
        this.listLookups = res;
      });
  }

  getAllCompanies() {
    this.companyList = [];
    this.commonService
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading..')
      // return data
      .then((res: any) => {
        this.companyList = res;
        this.companyListSearch = res;
        if (this.editflag) {
          this.addCompanyForm.controls['parentType'].setValue(this.companyList.filter((x: any) => x.id == this.parentId).map((x: any) => x.legalName)[0]);
        }
      });
  }

  saveCustomer(): any {
    this.submitted = true;
    this.firstExpand = true;
    this.allExpand = true;
    if (!this.addCompanyForm.valid) {
      this.addCompanyForm.markAllAsTouched()
      return false
    } else {
      let salesRep = '';
      let stage = '';
      if (this.addCompanyForm.value.salesRepId == 0 || this.addCompanyForm.value.salesRepId == null) {
        salesRep = '';
      } else {
        salesRep = _.findWhere(this.listLookups?.opportunity?.salesRep, { id: this.addCompanyForm.value.salesRepId }).salesRepDescription;
      }
      if (this.addCompanyForm.value.salesRepId == 0 || this.addCompanyForm.value.salesRepId == null) {
        stage = '';
      } else {
        stage = _.findWhere(this.listLookups?.stage?.stage, { id: this.addCompanyForm.value.stageTypeId }).stageDescription;
      }
      if (this.addCompanyForm.value.shippingPostCode == '') {
        this.addCompanyForm.value.shippingPostCode = 0;
      }
      if (this.addCompanyForm.value.billingPostCode == '') {
        this.addCompanyForm.value.billingPostCode = 0;
      }
      if (this.addCompanyForm.value.parentId == '') {
        this.addCompanyForm.value.parentId = 0;
      }
      if (this.editflag == false) {
        this.addCompanyForm.value.relatedContactIds = [0];
      }
      let paramObj = {
        "netSuiteId": 0,
        "legalName": this.addCompanyForm.value.companyName,
        "tradingName": this.addCompanyForm.value.tradingName,
        "salesRepId": this.addCompanyForm.value.salesRepId,
        "salesRep": salesRep,
        "comments": this.addCompanyForm.value.comments,
        "phone": JSON.stringify(this.addCompanyForm.value.phone),
        "email": this.addCompanyForm.value.email,
        "stage": stage,
        "dateCreated": new Date(),
        "tierId": this.addCompanyForm.value.tierId,
        "marketIndustryId": this.addCompanyForm.value.marketId,
        "productCategoryId": this.addCompanyForm.value.productCategoryId,
        "leadStatusId": this.addCompanyForm.value.leadStatusId,
        "leadSourceId": this.addCompanyForm.value.leadSourceId,
        "softwareId": this.addCompanyForm.value.softwareId,
        "subsidiaryId": this.addCompanyForm.value.subsidiaryId,
        "parentId": this.addCompanyForm.value.parentId,
        "accountId": 0,
        "stageId": this.addCompanyForm.value.stageTypeId,
        "shippingAddress": {
          "id": 0,
          "companyId": 0,
          "addressLine1": this.addCompanyForm.value.shippingAddressLine1,
          "addressLine2": this.addCompanyForm.value.shippingAddressLine2,
          "city": this.addCompanyForm.value.shippingCity,
          "stateId": this.addCompanyForm.value.shippingState,
          "postCode": this.addCompanyForm.value.shippingPostCode,
          "countryId": this.addCompanyForm.value.shippingCountry,
        },
        "billingAddress": {
          "id": 0,
          "companyId": 0,
          "addressLine1": this.addCompanyForm.value.billingAddressLine1,
          "addressLine2": this.addCompanyForm.value.billingAddressLine2,
          "city": this.addCompanyForm.value.billingCity,
          "stateId": this.addCompanyForm.value.billingState,
          "postCode": this.addCompanyForm.value.billingPostCode,
          "countryId": this.addCompanyForm.value.billingCountry,
        },
        "createBy": "1",
        "createDate": new Date(),
        "updatedBy": "1",
        "updatedDate": new Date(),
        "relatedContactIds": this.addCompanyForm.value.relatedContactIds,
      }
      if (this.editflag) {
        this.commonService.PutMethod(Apiurl.saveCompany + '/' + this.id, paramObj, true, 'Loading', null).then((res: any) => {
          if (res) {
            Swal.fire("Success", "Company Updated successfully", "success").then(() => {
              if (history && history.state.id) {
                let id = history.state.id
                let param: NavigationExtras = {
                  state: {
                    id: id,
                    Contact_id: history.state.id,
                    Company_id: res,
                    from: 'add-company'
                  }
                };
                this.router.navigate(['contacts'], param);
              } else {
                this.router.navigate(['companies']);
              }
            });
          } else {
            Swal.fire("Error", "Contact Updated unsuccsessfully", "error").then(() => {
            });
          }
        }).catch(err => {
          console.log(err)
        })
      }
      else {
        this.commonService.PostMethod(Apiurl.saveCompany, paramObj, false, "Loading.", null)
          .then((responsedata: any) => {
            if (responsedata) {
              Swal.fire("Success", "Company Added successfully", "success").then(() => {
                if (history && history.state.id) {
                  let id = history.state.id
                  let param: NavigationExtras = {
                    state: {
                      id: id,
                      Contact_id: history.state.id,
                      Company_id: responsedata,
                      from: 'add-company'
                    }
                  };
                  this.router.navigate(['contacts'], param);
                } else {
                  this.router.navigate(['companies']);
                }
              });
            } else {
              Swal.fire("Error", "Contact Added unsuccsessfully", "error").then(() => {
              });
            }
          }).catch(err => {
            console.log(err)
          })
      }
    }

  }
  async goBackToListing() {
    let obj = {
      title: 'Are you sure you want to Cancel?',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    }
    await this.commonService.showConfirm(obj).then((resp: any) => {
      if (resp.isConfirmed) {
        this.router.navigate(['companies'])
      }
    });
  }

  search(event: any, from: any) {
    if (from == 'billing') {
      if (event.target.value.length <= 6) {
        this.billingPostCodeLength = event.target.value.length
        this.billingPostCodeData = event.target.value
        return;
      }
      this.addCompanyForm.controls['billingPostCode'].setValue(parseInt(this.billingPostCodeData))
    }
    if (from == 'shipping') {
      if (event.target.value.length <= 6) {
        this.shippingPostCodeLength = event.target.value.length
        this.shippingPostCodeData = event.target.value
        return;
      }
      this.addCompanyForm.controls['shippingPostCode'].setValue(parseInt(this.shippingPostCodeData))
    }
    if (from == 'phone') {
      if (event.target.value.length <= 10) {
        this.phoneLength = event.target.value.length
        this.phoneData = event.target.value
        return;
      }
      this.addCompanyForm.controls['phone'].setValue(this.phoneData)
    }
  }

  inputCompanyName(event: any) {
    this.addCompanyForm.controls['tradingName'].setValue(event.target.value)
  }

  searchParent(event: any) {
    if (event.target.value != '') {
      this.companyListSearch = this.companyList.filter(
        (s: any) => s.legalName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
      );
    } else {
      this.companyListSearch = this.companyList
    }
  }
  setPatient(obj: any) {
    this.addCompanyForm.controls['parentId'].setValue(obj.id)
  }
  clearCompanyDetails() {
    this.searchdropdown = '';
    this.companyListSearch = this.companyList
  }
}
