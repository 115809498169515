import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LayoutService } from 'src/app/features/layout/layout.service';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  showLogo: boolean = true;
  control = new FormControl();
  streets: string[] = ['Champs-Élysées', 'Lombard Street', 'Abbey Road', 'Fifth Avenue'];
  filteredStreets: Observable<string[]>;

  constructor(private router: Router, private layoutService: LayoutService) { }

  ngOnInit() {
    this.filteredStreets = this.control.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    this.showLogo = !this.showLogo
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.streets.filter(street => this._normalizeValue(street).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  logout() {
    this.router.navigate(['/login'])
    setTimeout(() => {
      console.log(this.router.url)
      this.layoutService.activeRoute = this.router.url
      this.layoutService.getcurrentActivateRoute(this.layoutService.activeRoute)
    }, 100);
  }
}
