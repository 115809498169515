import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    activeRoute: any;
    // LoginToken: any = null;
    LoginToken: any = "4F4F9EB5A3F86E7FAE5E431E179777AFB5FE4D07730219A66A263A332193AF15";

    constructor() {
        localStorage.setItem("LoginToken", this.LoginToken);
    }

    private routeSelected = new Subject<any>();
    private sideMenuToggle = new Subject<any>();

    getcurrentActivateRoute(route: any) {
        this.routeSelected.next(route)
    }
    setObservable(): Subject<any> {
        return this.routeSelected;
    }

    sendToggleFlag(data: any) {
        this.sideMenuToggle.next(data)
    }

    setObservableforFlag(): Subject<any> {
        return this.sideMenuToggle;
    }

    ResetLoginToken() {
        this.LoginToken = null;
        localStorage.setItem("LoginToken", this.LoginToken);
    }
}