<div class="login_wrapper">
    <div class="bg"></div>
    <div class="bg bg2"></div>
    <div class="bg bg3"></div>
    <div class="Form_Conatiner">
        <div id="login-form" class="login-page">
            <div class="form-box">
                <div class="button-box">
                    <img src="../../../assets/img/user.png">
                </div>
            </div>
        </div>
    </div>
</div>