import { LayoutService } from 'src/app/features/layout/layout.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { CommonProvider } from 'src/app/shared/common';
import { Apiurl } from 'src/app/shared/route';
import * as _ from 'underscore';
import { Subject } from 'rxjs';
import {
  CalendarView,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent
} from 'angular-calendar';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarSchedulerViewComponent } from 'angular-calendar-scheduler';
import { isSameDay, isSameMonth } from 'date-fns';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss']
})
export class ActivityDetailsComponent implements OnInit {
  // ContactForm: any;
  test = 'hello';
  CompanyList: any[] = [];
  RoleList: any[] = [];
  SubsidiariesList: any[] = [];
  StateList: any[] = [];
  CountryList: any[] = [];
  accountId: any;
  companyId: any;
  netSuiteId: any;
  openBlock: any = 1;
  ActivityId: any;
  activityDetails: any = [];
  IsEditClick: boolean = false;
  StateName: any;
  CountryName: any;
  phoneLength = 0;
  phoneData: any;
  postCodeLength = 0;
  postCodeData: any;
  filterTypeList: any = [];
  selectedView: any = 'Agenda';
  // dataSource: any;
  dataSource: any = null;
  displayedColumns: string[] = [
    'eventType',
    'eventTitle',
    'relatedCompany',
    'relatedTransaction',
    'relatedContact',
    'startDate',
    'startTime',
    'endTime'
    // 'salesRep'
    // 'phoneNumber'
  ];
  companyListSearch: any;
  companyType: any;
  activity_id: number;
  contactData: any = [];

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: any = 'Agenda';
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();
  showFilter: boolean = false;
  statusType: any;
  events: CalendarEvent[];
  filterType: any;
  activeDayIsOpen: boolean = true;
  searchInput: any;
  statusList: any = [];
  lookup: any = [];
  salesrep: any;
  @ViewChild(CalendarSchedulerViewComponent)
  calendarScheduler: CalendarSchedulerViewComponent;
  constructor(
    public common: CommonProvider,
    public router: Router,
    private modal: NgbModal,
    private layoutService: LayoutService
  ) { }
  customer_id: any;

  ngOnInit(): void {
    this.FillDropDowns();

    if (history.state.activity_id) {
      this.ActivityId = history.state.activity_id;

      this.getActivityDetailById();
      this.getCalenderEvent();
    } else {
      this.router.navigate(['activities']);
      setTimeout(() => {
        this.layoutService.activeRoute = this.router.url;
        this.layoutService.getcurrentActivateRoute(
          this.layoutService.activeRoute
        );
      }, 50);
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }
  statusTypeChange(event: any) { }
  filterTypeChange(event: any) { }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.showFilter = false;
  }
  clearSearch() {
    this.searchInput = '';
    this.filterType = null;
    this.statusType = null;
  }

  async FillDropDowns() {
    await this.common
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading..')
      .then((resp: any) => {
        if (resp.length != 0) {
          this.CompanyList = [];
          for (var i = 0; i < resp.length; i++) {
            this.CompanyList.push({
              id: resp[i].id,
              text: resp[i].legalName,
              netSuiteId: resp[i].netSuiteId,
              accountId: resp[i].accountId
            });
          }
          this.companyListSearch = this.CompanyList;
        }
      });
    await this.common
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading..')
      .then((resp: any) => {
        this.lookup = resp;
        this.salesrep = this.lookup.opportunity.salesRep;

        this.activityDetails.salesRep = this.salesrep
          .filter((x: any) => x.id == this.activityDetails.salesRepId)
          .map((x: any) => x.salesRepDescription);
      });
  }

  isRequired(controlName: string) {
    if (!controlName) {
      return false;
    }
    return;
  }

  async ViewContact() {
    let obj = {
      title: 'Are you sure you want to Cancel?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    };
    await this.common.showConfirm(obj).then((resp: any) => {
      if (resp.isConfirmed) {
        if (history && history.state.id) {
          let id = history.state.id;
          this.router.navigate(['companies-details'], {
            queryParams: { id: id }
          });
        } else {
          this.router.navigate(['contacts-list']);
        }
      }
    });
  }

  goToPage() {
    let data = {
      id: this.activityDetails.id,
      netsuitid: this.activityDetails.netSuiteId,
      from: 'contacts'
    };

    let id: NavigationExtras = {
      state: {
        id: data
      }
    };
    // this.router.navigate(['add-company'], id);
  }
  async getActivityDetailById() {
    await this.common
      .GetMethod(
        Apiurl.GetAllActivities + '/' + this.ActivityId,
        null,
        true,
        'Loading'
      )
      .then((res: any) => {
        console.log('activity', res);
        this.activityDetails = [];
        this.activityDetails = res;
      });
  }
  viewFilterField() {
    this.showFilter = !this.showFilter;
  }
  OpenBlock(id: any) {
    this.openBlock = id;
    this.StateName = this.StateList.filter(
      x => x.id == this.activityDetails.residentialAddress.stateId
    ).map(x => x.stateDescription)[0];
    this.CountryName = this.CountryList.filter(
      x => x.id == this.activityDetails.residentialAddress.countryId
    ).map(x => x.countryDescription)[0];
  }
  EditClick() {
    let param: NavigationExtras = {
      state: {
        type: history.state.type,
        activity_id: history.state.activity_id,
        from: 'activity-details'
      }
    };
    this.router.navigate(['/sub-activity'], param);
    // this.router.navigate(['/activity-details'], param);
  }
  AddNewContact() {
    this.IsEditClick = true;
    this.ActivityId = 0;
  }
  viewAgenda() {
    this.view = 'Agenda';
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  gotoActivities() {
    let param: NavigationExtras = {
      state: {
        type: 'Event',
        from: 'contact',
        Contact_id: history.state.Contact_id,
        id: this.customer_id
      }
    };
    this.router.navigate(['sub-activity'], param);
  }
  searchCompany(event: any) {
    if (event.target.value != '') {
      this.companyListSearch = this.CompanyList.filter(
        (s: any) =>
          s.text.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
      );
    } else {
      this.companyListSearch = this.CompanyList;
    }
  }

  getCalenderEvent() {
    this.common
      .GetMethod(
        Apiurl.getAllActivties + '/' + this.ActivityId,
        null,
        true,
        'Loading..'
      )
      .then((res: any) => {
        this.dataSource = new MatTableDataSource([{ ...res }]);
        console.log('calender', res);
        // this.dataSource = res;
        // this.mainDataSouce = res;
        this.events = [];

        console.log('in');
        let end: any;
        if (
          new Date(res.endDate).setHours(0, 0, 0, 0) ==
          new Date().setHours(0, 0, 0, 0)
        ) {
          end = null;
        } else {
          end = new Date(res.endDate);
        }
        this.events.push({
          start: new Date(res.startDate),
          end: end,
          title: res.title,
          actions: this.actions,
          allDay: true,
          resizable: {
            beforeStart: true,
            afterEnd: true
          },
          draggable: true
        });
        console.log('events', this.events);
      });
  }

  //

  addContact() {
    let param: NavigationExtras = {
      state: {
        Contact_id: 0,
        from: 'qoute'
        // id: this.rowData.customerId,
        // qoute_id: this.rowData.id
      }
    };
    // this.router.navigate(['contacts'], param);
  }
  //
}
