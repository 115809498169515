import { Location } from '@angular/common';
import { LayoutService } from 'src/app/features/layout/layout.service';
import { NavigationExtras, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { CommonProvider } from 'src/app/shared/common';
import { Apiurl } from 'src/app/shared/route';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'underscore';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConcatSource } from 'webpack-sources';
@Component({
  selector: 'app-add-opportunities',
  templateUrl: './add-opportunities.component.html',
  styleUrls: ['./add-opportunities.component.scss']
})
export class AddOpportunitiesComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  listCompanies: any = [];
  listLookups: any = [];
  listopportunities: any = [];
  listTemplates: any = [];
  listItems: any = [];
  ContactList: any = [];
  duplicateListItems: any = [];
  opportunitiesForm: FormGroup;
  activeTab: string = 'templates';
  displayedColumns: string[] = ['select', 'name', 'quantity', 'basePrice'];
  displayOpportunityItems: string[] = [
    'name',
    'rate',
    'amount',
    'quantity'
  ];
  displayOpportunityItemsInEdit: string[] = ['name', 'quantity', 'amount'];
  selection = new SelectionModel<any>(true, []);
  totalHardwareAmount: number = 0;
  totalServiceAmount: number = 0;
  totalSubscriptionAmount: number = 0;
  totalSelectedCheckBox: number = 0;
  opportunityItems: any = [];
  showSavedItemsList: boolean = false;
  opportunityItemsID: any;
  rowData: any;
  isShowData: boolean = false;
  isViewData: boolean = false;
  showExtras: boolean = false;
  showDisCountPage: boolean = false;
  openBlock: number = 1;
  discountType: string = 'dollar';
  contactData: any = [];
  activityList: any = [];
  ContactSearch: any = [];
  opportunityList: any = [];
  relatedActivityArray: any = [];
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.listItems.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.listItems.map((x: any) => {
        x.selected = false;
      });
      return;
    }

    this.selection.select(...this.listItems);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'
      } row ${row.position + 1}`;
  }

  constructor(
    private commonService: CommonProvider,
    private fb: FormBuilder,
    private router: Router,
    private location: Location,
    private layoutService: LayoutService,
    private SpinnerService: NgxSpinnerService,
  ) {
    this.fetchData();
  }

  ngOnInit(): void {
    this.createOpportunitiesForm();
    if (history.state.navigationId != 1) {
      if (history.state && history.state.contact_id && history.state.qoute_id) {
        this.isShowData = true;
        this.getAllContact();
      }
      if (history.state && history.state.activity_id) {
        this.isShowData = true;
        this.getAllOpportunity();
      }
    }
    else {
      this.router.navigate(['dashboard'])
      setTimeout(() => {
        this.layoutService.activeRoute = this.router.url;
        this.layoutService.getcurrentActivateRoute(
          this.layoutService.activeRoute
        );
      }, 50);
    }
    this.getAllCompaniesList();
    this.getAllLookupList();
    this.getAllOpportunityList();
    this.getAllContactList();
    this.getAllTemplateList();
    this.getAllItemList();
  }

  getAllOpportunity() {
    this.commonService
      .GetMethod(Apiurl.getAllOpportunityList, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.opportunityList = res;
          this.getAllActivityList();
        }
      });
  }
  getAllContact() {
    let data: any = []
    this.commonService
      .GetMethod(Apiurl.getAllQuotes + '/' + history.state.qoute_id, null, true, 'Loading')
      .then(async (res: any) => {
        if (res) {
          if (history.state.contact_id != null && history.state.contact_id != undefined) {
            if (!res.relatedContactIds.includes(history.state.contact_id)) {
              res.relatedContactIds.push(history.state.contact_id)
            }
            this.commonService.PutMethod(Apiurl.getAllQuotes + '/' + history.state.qoute_id, res, true, 'Loading', null).then(async (resdata: any) => {
              if (resdata) {
                if (res.relatedContactIds) {
                  this.SpinnerService.show();
                  let index
                  for (index = 0; index < res.relatedContactIds.length; index++) {
                    let resp = await this.commonService
                      .GetMethod(
                        Apiurl.getContactList + '/' + res.relatedContactIds[index],
                        null,
                        false,
                        'Loading..'
                      )
                    data.push(resp);
                  }
                  this.contactData = data
                  this.SpinnerService.hide();
                }
              }
            }).catch(err => {
            })
          } else {
            if (res.relatedContactIds) {
              this.SpinnerService.show();
              let index
              for (index = 0; index < res.relatedContactIds.length; index++) {
                let resp = await this.commonService
                  .GetMethod(
                    Apiurl.getContactList + '/' + res.relatedContactIds[index],
                    null,
                    false,
                    'Loading..'
                  )
                data.push(resp);
              }
              this.contactData = data
              this.SpinnerService.hide();
            }
          }
        }
      });
  }
  createOpportunitiesForm() {
    this.opportunitiesForm = this.fb.group({
      customerId: [null, Validators.required],
      title: [''],
      date: [new Date()],
      statusId: [8, Validators.required],
      probability: [70],
      OpportunityNumber: [null],
      opportunityItems: [],
      subsidiaryId: [6, Validators.required],
      winLossReasonId: [null, Validators.required],
      salesRepId: [null, Validators.required],
      leadSourceId: [null, Validators.required],
      memo: [''],
      forecastTypeId: [null, Validators.required],
      marketIndustryId: [null, Validators.required],
      productCategoryId: [null, Validators.required],
      tiersId: [null, Validators.required],
      originalAmount: [0],
      amountAfterDiscount: [0],
      discountAmount: [0],
      dollarDiscount: [0],
      percentageDiscount: [0],
      totalAmount: [0],
      weightedTotal: [0],
      projectedTotal: [0],
      rangeHigh: [0],
      rangeLow: [0],
      documentNumber: [""],
    });
  }
  fetchData() {
    this.rowData = this.location.getState() as any;
    if (Object.entries(this.rowData).length > 1) {
      if (this.rowData.isEdit) {
        this.isShowData = this.rowData.isEdit;
        this.rowData = this.rowData.row;
        this.getAllCompaniesList()
        this.opportunityItems = this.rowData.opportunityItems;
        history.state.qoute_id = this.rowData.id
      }
    }
  }
  getAllCompaniesList() {
    this.commonService
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.listCompanies = res;
          if (this.rowData.id) {
            this.opportunitiesForm.controls['customerId'].setValue(this.rowData.id.id)
          }
          if (this.rowData) {
            let companyName = _.findWhere(this.listCompanies, { id: this.rowData.customerId })
            let statusName = _.findWhere(this.listLookups.quotes.quotesStatus, { id: this.rowData.statusId });
            this.rowData.companyName = companyName.legalName
            this.rowData.status = statusName.statusDescription
          }
        }
      });
  }
  getAllActivityList() {
    this.commonService
      .GetMethod(Apiurl.getAllActivties, null, true, 'Loading..')
      .then((resp: any) => {
        if (resp.length != 0) {
          this.activityList = resp;
          this.relatedActivityArray = []
          for (let index = 0; index < this.activityList.length; index++) {
            if (this.activityList[index].transactionType.includes('quote') || this.activityList[index].transactionType.includes('Quote')) {
              if (this.activityList[index].relatedTransactionId != 0) {
                let data = _.findWhere(this.opportunityList, { id: this.activityList[index].relatedTransactionId })
                if (data) {
                  if (data.id == history.state.qoute_id) {
                    this.relatedActivityArray.push(this.activityList[index])
                  }
                }
              }
            }
          }
        }
      });
  }
  getAllContactList() {
    this.commonService
      .GetMethod(Apiurl.getContactList, null, true, 'Loading..')
      .then((resp: any) => {
        if (resp.length != 0) {
          this.ContactList = resp;
          this.ContactSearch = resp;
        }
      });
  }
  getAllLookupList() {
    this.commonService
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.listLookups = res;
        }
      });
  }
  getAllOpportunityList() {
    this.commonService
      .GetMethod(Apiurl.getAllOpportunityList, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.listopportunities = res;
        }
      });
  }
  getAllTemplateList() {
    this.commonService.GetMethod(Apiurl.getAllTemplates, null, true, 'Loading').then((res: any) => {
      if (res) {
        this.listTemplates = res.filter((item: any) => {
          if (item.templateName) {
            item.selected = false;
            item.added = false;
            return true;
          } else {
            return false;
          }
        });
        if (this.isShowData) {
          let templateItemsIds: any = [], quoteItemsIds: any = []
          this.listTemplates.forEach((x: any) => {
            templateItemsIds = _.pluck(x.items, 'itemId')
            quoteItemsIds = _.pluck(this.opportunityItems, 'id')
            var check = templateItemsIds.every((el: any) => {
              return quoteItemsIds.indexOf(el) !== -1;
            });
            if (check) {
              x.selected = true;
            } else {
              x.selected = false;
            }
          })
        }
      }
    })
  }
  getAllItemList() {
    this.commonService.GetMethod(Apiurl.getAllItems, null, true, 'Loading').then((res: any) => {
      if (res) {
        this.duplicateListItems = res;
        this.duplicateListItems.map((x: any) => { x.selected = false })
        if (this.isShowData) {
          let opportunityItems: any = [];
          this.opportunityItems.forEach((y: any) => {
            var x = _.findWhere(this.duplicateListItems, { id: y.itemId });
            if (x.segmentId == 1) {
              x.selected = true;
              x.quantity = y.quantity;
              this.totalHardwareAmount = this.totalHardwareAmount + x.basePrice;
              opportunityItems.push(x)
            } else
              if (x.segmentId == 2) {
                x.quantity = y.quantity;
                x.selected = true;
                this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) + x.basePrice;
                opportunityItems.push(x)
              } else
                if (x.segmentId == 3) {
                  x.selected = true;
                  x.quantity = y.quantity;
                  this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) + x.basePrice;
                  opportunityItems.push(x);
                } else {
                  x.selected = false;

                }
            this.opportunityItems = opportunityItems;
          })
        }
      }
    })
  }
  onSubmit(form: FormGroup) { }
  saveAndContinue(shouldRoute?: boolean) {
    // this.showSavedItemsList = true
    // return
    if (this.opportunitiesForm.invalid) return;
    if (shouldRoute) {
      this.saveQuantityOfItems();
      this.router.navigate(['opportunities']);
      return;
    }
    if (this.opportunityItemsID) {
      this.saveQuantityOfItems();
    }
    this.opportunitiesForm.value['opportunityItems'] = this.opportunityItems;
    this.commonService
      .PostMethod(
        Apiurl.getAllOpportunityList,
        this.opportunitiesForm.value,
        true,
        'Loading',
        null
      )
      .then((res: any) => {
        if (!res) return;
        this.opportunityItemsID = res;
        this.getItemList(res);
      });
  }
  getItemList(id: any) {
    this.commonService.GetMethod(Apiurl.getAllQuotes + '/' + id, null, true, 'Loading').then((res: any) => {
      if (!res) return;
      this.showSavedItemsList = true;
      this.opportunityItems = [];
      res.opportunityItems.forEach((quotes: any) => {
        var items = _.findWhere(this.duplicateListItems, { id: quotes.itemId });
        if (!items) return;
        quotes.name = items.name
        quotes.segmentId = items.segmentId
        this.opportunityItems.push(quotes);
      })
    })
  }
  goTo(tab: string) {
    this.activeTab = tab;
    let segmentId: any;
    switch (this.activeTab) {
      case 'hardware':
        segmentId = 1;
        break;
      case 'services':
        segmentId = 2;
        break;
      case 'subscriptions':
        segmentId = 3;
        break;
      default:
        break;
    }
    this.listItems = this.duplicateListItems.filter(
      (item: any) => item.segmentId == segmentId
    );
    this.listItems.sort((a: any, b: any) => (a.selected > b.selected) ? -1 : 1);
  }
  calculateTotalValue(items: any) {
    items.selected = !items.selected;
    if (items.selected) {
      items.items.forEach((object: any) => {
        object.selected = true;
      })
      this.totalSelectedCheckBox++
      items.added = false
      this.selectItems(items)
    } else {
      items.items.forEach((object: any) => {
        object.selected = false
      })
      this.totalSelectedCheckBox--
      items.added = true;
      this.selectItems(items)
    }
  }
  calculateHardwareItems(items: any) {
    let params = {
      amount: 0,
      itemId: 0,
      quantity: 0,
      rate: 0,
    }
    items.selected = !items.selected;
    this.listTemplates.forEach((x: any) => {
      var itemInTemplates = _.findWhere(x.items, { itemId: items.id });
      if (itemInTemplates) {
        itemInTemplates.selected = !itemInTemplates.selected
      }
      var selectedItems = x.items.filter((object: any) => object.selected === true)
      if (selectedItems.length === x.items.length) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });
    if (items.selected) {
      items.quantity = 1;
      params.quantity = items.quantity;
      params.amount = items.basePrice * items.quantity;
      params.itemId = items.itemId;
      params.rate = items.basePrice;
      this.opportunityItems.push(params);
      this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
    } else {
      let index = this.opportunityItems.findIndex((x: any) => x.id == items.id);
      if (index < 0) return;
      items.quantity = 0;
      items.selected = false;
      this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
      this.opportunityItems.splice(index, 1);
    }
  }
  calculateServiceItems(items: any) {
    items.selected = !items.selected;
    let params = {
      amount: 0,
      itemId: 0,
      quantity: 0,
      rate: 0,
    }
    this.listTemplates.forEach((x: any) => {
      var itemInTemplates = _.findWhere(x.items, { itemId: items.id });
      if (itemInTemplates) {
        itemInTemplates.selected = !itemInTemplates.selected
      }
      var selectedItems = x.items.filter((object: any) => object.selected === true)
      if (selectedItems.length === x.items.length) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });
    if (items.selected) {
      items.quantity = 1;
      params.quantity = items.quantity;
      params.amount = items.basePrice * items.quantity;
      params.itemId = items.itemId;
      params.rate = items.basePrice;
      this.opportunityItems.push(params);
      this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
    } else {
      let index = this.opportunityItems.findIndex((x: any) => x.id == items.id);
      if (index < 0) return;
      items.quantity = 0;
      items.selected = false;
      this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
      this.opportunityItems.splice(index, 1);
    }
  }
  calculateSubscriptionItems(items: any) {
    items.selected = !items.selected;
    let params = {
      amount: 0,
      itemId: 0,
      quantity: 0,
      rate: 0,
    }
    this.listTemplates.forEach((x: any) => {
      var itemInTemplates = _.findWhere(x.items, { itemId: items.id });
      if (itemInTemplates) {
        itemInTemplates.selected = !itemInTemplates.selected
      }
      var selectedItems = x.items.filter((object: any) => object.selected === true)
      if (selectedItems.length === x.items.length) {
        x.selected = true;
      } else {
        x.selected = false;
      }
    });
    if (items.selected) {
      items.quantity = 1;
      params.quantity = items.quantity;
      params.amount = items.basePrice * items.quantity;
      params.itemId = items.itemId;
      params.rate = items.basePrice;
      this.opportunityItems.push(params);
      this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
    } else {
      let index = this.opportunityItems.findIndex((x: any) => x.id == items.id);
      if (index < 0) return;
      items.quantity = 0;
      items.selected = false;
      this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
      this.opportunityItems.splice(index, 1);
    }
  }
  selectItems(templateItems: any) {
    let index;
    templateItems.items.forEach((objs: any) => {
      let params = {
        amount: 0,
        itemId: 0,
        quantity: 0,
        rate: 0,
      }
      var items = _.findWhere(this.duplicateListItems, { id: objs.itemId });
      if (!items) { return }
      if (templateItems.selected && items.segmentId === 1) {
        items.quantity = 1;
        params.quantity = items.quantity;
        params.amount = items.basePrice * items.quantity;
        params.itemId = objs.itemId;
        params.rate = items.basePrice;
        items.selected = true;
        this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
        this.opportunityItems.push(params);
      } else if (templateItems.added && items.segmentId === 1) {
        items.selected = false;
        this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
        index = this.opportunityItems.findIndex((x: any) => x.id == items.id);
        this.opportunityItems.splice(index, 1)
      }
      if (templateItems.selected && items.segmentId === 2) {
        items.quantity = 1;
        params.quantity = items.quantity;
        params.amount = items.basePrice * items.quantity;
        params.itemId = objs.itemId;
        params.rate = items.basePrice;
        items.selected = true;
        this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
        this.opportunityItems.push(params);
      } else if (templateItems.added && items.segmentId === 2) {
        items.selected = false;
        this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
        index = this.opportunityItems.findIndex((x: any) => x.id == items.id);
        this.opportunityItems.splice(index, 1)
      }
      if (templateItems.selected && items.segmentId === 3) {
        items.quantity = 1;
        params.quantity = items.quantity;
        params.amount = items.basePrice * items.quantity;
        params.itemId = objs.itemId;
        params.rate = items.basePrice;
        items.selected = true;
        this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) + items.basePrice;
        this.opportunityItems.push(params);
      } else if (templateItems.added && items.segmentId === 3) {
        items.selected = false;
        this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) - items.basePrice;
        index = this.opportunityItems.findIndex((x: any) => x.id == items.id);
        this.opportunityItems.splice(index, 1)
      }
    })
    this.opportunitiesForm.controls['originalAmount'].setValue((this.totalHardwareAmount + this.totalServiceAmount) - this.opportunitiesForm.value.discountAmount)
  }
  increaseItemQuantity(item: any) {
    if (item.segmentId === 1) {
      item.quantity++
      item.amount = item.rate * item.quantity;
      this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) + (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    if (item.segmentId === 2) {
      item.quantity++
      item.amount = item.rate * item.quantity;
      this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) + (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    if (item.segmentId === 3) {
      item.quantity++
      item.amount = item.rate * item.quantity;
      this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) + (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    let index = this.opportunityItems.findIndex((x: any) => x.id == item.id)
    if (index < 0) { return };
    this.opportunityItems[index] = item;
    this.opportunityItems = [...this.opportunityItems];
  }
  decreaseItemQuantity(item: any) {
    if (item.quantity <= 1) {
      let index = this.opportunityItems.findIndex((x: any) => x.id == item.id)
      if (index < 0) { return };
      this.opportunityItems[index].selected = false;
      this.opportunityItems.splice(index, 1);
      this.opportunityItems = [...this.opportunityItems];
      item.quantity--
      return;
    }
    if (item.segmentId === 1) {
      item.quantity--
      item.amount = item.rate * item.quantity;
      this.totalHardwareAmount = (Math.round(this.totalHardwareAmount * 100 + Number.EPSILON) / 100) - (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    if (item.segmentId === 2) {
      item.quantity--
      item.amount = item.rate * item.quantity;
      this.totalServiceAmount = (Math.round(this.totalServiceAmount * 100 + Number.EPSILON) / 100) - (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    if (item.segmentId === 3) {
      item.quantity--
      item.amount = item.rate * item.quantity;
      this.totalSubscriptionAmount = (Math.round(this.totalSubscriptionAmount * 100 + Number.EPSILON) / 100) - (Math.round(item.rate * 100 + Number.EPSILON) / 100);
    }
    let index = this.opportunityItems.findIndex((x: any) => x.id == item.id)
    if (index < 0) { return };
    this.opportunityItems.splice(index, 1);
    this.opportunityItems[index] = item;
    this.opportunityItems = [...this.opportunityItems];
  }
  goBackToTabs() {
    this.showSavedItemsList = false;
  }
  goBackToSelectionList() {
    this.showSavedItemsList = false;
  }
  goBackToSavedItemsList() {
    this.showDisCountPage = false;
    this.opportunityItems.forEach((quotes: any) => {
      var items = _.findWhere(this.duplicateListItems, { id: quotes.itemId });
      if (!items) return;
      quotes.name = items.name
      quotes.segmentId = items.segmentId
      this.opportunityItems.push(quotes);
    })
  }
  saveQuantityOfItems(showSavedItemsList: boolean = false) {
    this.opportunityItems.map((quotes: any) => {
      delete quotes.segmentId;
      delete quotes.name;
    })
    this.opportunitiesForm.value['opportunityItems'] = this.opportunityItems;
    this.commonService.PutMethod(Apiurl.getAllOpportunityList + '/' + this.opportunityItemsID, this.opportunitiesForm.value, true, 'Loading', null).then((res: any) => {
      if (!res) return;
      if (showSavedItemsList) {
        this.showSavedItemsList = true;
      } else {
        this.showDisCountPage = true;
      }
    })
  }
  OpenBlock(id: number) {
    this.openBlock = id;
  }
  addQuotes() {
    this.isShowData = false;
    this.showExtras = false;
  }
  editQuotes() {
    this.isShowData = false;
    this.isViewData = true;
    this.showExtras = true;
    let obj = this.rowData;
    this.opportunitiesForm.patchValue({
      customerId: obj.customerId,
      title: obj.title,
      date: obj.date,
      dueDate: obj.dueDate,
      statusId: obj.statusId,
      probability: obj.probability,
      salesRepId: obj.salesRepId,
      memo: obj.memo,
      subsidiaryId: obj.subsidiaryId,
      opportunity: obj.opportunity,
      leadSourceId: obj.leadSourceId,
      forecastTypeId: obj.forecastTypeId,
      OpportunityNumber: obj.OpportunityNumber,
      winLossReasonId: obj.winLossReasonId,
      marketIndustryId: obj.marketIndustryId,
      productCategoryId: obj.productCategoryId,
      tiersId: obj.tiersId,
      originalAmount: obj.originalAmount,
      amountAfterDiscount: obj.amountAfterDiscount,
      discountAmount: obj.discountAmount,
      dollarDiscount: obj.dollarDiscount,
      percentageDiscount: obj.percentageDiscount,
      relatedContactIds: obj.relatedContactIds,
      totalAmount: obj.totalAmount,
      weightedTotal: obj.weightedTotal,
      projectedTotal: obj.projectedTotal,
      rangeHigh: obj.rangeHigh,
      rangeLow: obj.rangeLow,
      documentNumber: obj.documentNumber,
    });
  }
  cancelEditing() {
    if (!this.rowData.isEdit) {
      this.router.navigate(['opportunities']);
      return;
    }
    this.isShowData = true;
  }
  navigateTo(route: string) {
    this.router.navigate([route])
  }
  inputEvent(event: any) {
    this.ContactList = this.ContactSearch;
    var evens = _.filter(this.ContactSearch, function (contact) {
      return (
        contact.name.toLowerCase().indexOf(event.target.value.toLowerCase()) >
        -1
      );
    });

    this.ContactList = evens;
  }

  addContact() {
    let param: NavigationExtras = {
      state: {
        Contact_id: 0,
        from: 'oppportunity',
        id: this.rowData.customerId,
        oppportunity_id: this.rowData.id
      }
    };
    this.router.navigate(['contacts'], param);
  }

  gotoActivity() {
    let param: NavigationExtras = {
      state: {
        Contact_id: 0,
        from: 'oppportunity',
        id: this.rowData.customerId,
        oppportunity_id: this.rowData.id,
        type: 'Event'
      }
    };
    this.router.navigate(['sub-activity'], param);
  }
}
