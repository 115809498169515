<div *ngIf="activeRoute != '/'">
    <!-- <app-header (toggleSideBarForMe)="sideBarToggler($event)"></app-header> -->
</div>
<mat-drawer-container *ngIf="activeRoute != '/'">
    <mat-drawer mode="side" [opened]="sideBarOpen" [ngClass]="{'sidenavmini': miniSidemenu == true, 'sidenav': miniSidemenu == false}">
        <app-menu></app-menu>
    </mat-drawer>
    <mat-drawer-content [ngClass]="{'contentmini': miniSidemenu == true, 'contentfull': miniSidemenu == false}">
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
<div *ngIf="activeRoute == '/'">
    <router-outlet></router-outlet>
</div>