<app-header></app-header>
<div class="content-header">
  <div class="col-lg-6 col-md-6 col-sm-12">
    <h1 class="m-0">Quotes</h1>
    <!-- <p class="m-0">Today 10th April 2021</p> -->
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12 text-right">
    <button mat-raised-button class="theme-bg c-btn" (click)="addQuotes()">
      + Add Quotes
    </button>
  </div>
</div>
<div class="quotes-content">
  <mat-card class="quotes-card">
    <div class="align-items-center row">
      <div class="col-lg-5">
        <mat-form-field appearance="outline" class="w-100">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search</mat-label>
          <input
            matInput
            (keyup)="inputEvent($event)"
            [(ngModel)]="searchText"
          />
        </mat-form-field>
      </div>
      <div class="col-lg-7 text-right">
        <div class="d-flex align-items-center justify-content-end flex-wrap">
          <div *ngIf="mainDataSouce && mainDataSouce.length" class="me-2">
            {{ dataSource?.filteredData?.length }} Records Found
            <!-- Records Found -->
          </div>
          <button
          mat-stroked-button
          class="me-2 c-btn"
          (click)="openFilter()"
        >
          <span class="material-icons me-2"> filter_list </span>Filter
        </button>
        </div>

        <!-- <button mat-raised-button class="c-btn green-btn">
                    <span class="material-icons me-2"> exit_to_app </span>Export
                </button> -->
      </div>
      <div class="row text-center" *ngIf="showFilter">
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Company</mat-label>

            <mat-select
              [(ngModel)]="Company"
              [ngModelOptions]="{ standalone: true }"
            >
              <mat-option
                *ngFor="let obj of companyList"
                [value]="obj.legalName"
                (click)="filterTypeChange(obj)"
              >
                {{ obj.legalName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Quote Status</mat-label>
            <mat-select
              [(ngModel)]="QuoteStatus"
              [ngModelOptions]="{ standalone: true }"
            >
              <!-- QuoteStatusList -->
              <mat-option
                *ngFor="let obj of QuoteStatusList"
                [value]="obj.statusDescription"
                (click)="filterTypeChange(obj)"
              >
                {{ obj.statusDescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label class="secondary-text">date</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              (click)="picker.open()"
              (dateInput)="filterTypeChange($event)"
              [(ngModel)]="datepicker"
            />
            <mat-icon (click)="picker.open()" matSuffix
              >calendar_today</mat-icon
            >
          </mat-form-field>
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <div class="col-md-3">
          <button
            mat-stroked-button
            color="primary"
            class="col-md-2 c-btn green-btn  btn-height w-100"
            (click)="clearSearch()"
          >
            Clear All
          </button>
        </div>
      </div>
      <!--  -->
      <!--  -->
    </div>
    <table
      mat-table
      [dataSource]="dataSource"
      class="w-100"
      matSort
      style="cursor: pointer;"
    >
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let element">{{ element.title }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.date | date: "dd MMM YYYY" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>
      <ng-container matColumnDef="probability">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Probability</th>
        <td mat-cell *matCellDef="let element">{{ element.probability }}</td>
      </ng-container>
      <ng-container matColumnDef="salesRep">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sales Rep</th>
        <td mat-cell *matCellDef="let element">{{ element.salesRep }}</td>
      </ng-container>
      <ng-container matColumnDef="opportunity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Opportunity</th>
        <td mat-cell *matCellDef="let element">{{ element.opportunity }}</td>
      </ng-container>
      <ng-container matColumnDef="forecastType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Forecast Type</th>
        <td mat-cell *matCellDef="let element">{{ element.forecastType }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="viewRow(row)"
      ></tr>
    </table>
  </mat-card>
</div>
