<!-- <app-header></app-header> -->
<div class="content-header">
  <div class="col-lg-8 col-md-8 col-sm-12">
      <div class="cc-header-wrapper">
          <div>
              <h2 class="m-0">Activities</h2>
              <!-- <p class="m-0">{{TodayDate}}</p> -->
          </div>
          <div class="w-50 pl-17">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-icon matPrefix>search</mat-icon>
                  <mat-label class="f-12">Search</mat-label>
                  <input matInput (keyup)="inputEvent($event)" [(ngModel)]="searchText" />
                  <mat-icon matSuffix (click)="clear()">close</mat-icon>
              </mat-form-field>
          </div>
      </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-12 text-right f-12">
      {{ dataSource?.filteredData?.length || 0 }} Records Found
      <button mat-stroked-button class="me-2 c-btn btn-success" (click)="openFilter()">
    <span class="material-icons me-2"> filter_list </span>Filter
  </button>
      <!-- <button mat-raised-button class="theme-bg c-btn" (click)="goToAddContact()">
    <mat-icon matPrefix>add</mat-icon> &nbsp;Add
  </button> -->
  </div>
</div>
<!-- Filter -->
<div>
  <div class="align-items-center row c-cad">
    <div class="row text-center" *ngIf="showFilter">
      <div class="col-md-1"></div>

      <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Type</mat-label>
          <mat-select
            [(ngModel)]="Type"
            [ngModelOptions]="{ standalone: true }"
            (selectionChange)="filterTypeChange('Type')"
          >
            <mat-option value="Phone">Phone</mat-option>
            <mat-option value="Event">Event</mat-option>
            <mat-option value="Task">Task</mat-option>
            <mat-option value="Note">Note</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Status</mat-label>
          <mat-select
            [(ngModel)]="Status"
            [ngModelOptions]="{ standalone: true }"
            (selectionChange)="filterTypeChange('Status')"
          >
            <mat-option value="Completed">Completed</mat-option>
            <mat-option value="Scheduled">Scheduled</mat-option>
            
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label class="secondary-text">Sales Rep</mat-label>
          <mat-select
            [(ngModel)]="salesRepId"
            (selectionChange)="filterTypeChange('SalesRepo')"
          >
            <mat-option
              *ngFor="let lookups of listLookups?.opportunity?.salesRep"
              [value]="lookups.id"
            >
              {{ lookups.salesRepDescription }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <button
          mat-stroked-button
          color="primary"
          class="col-md-2 c-btn green-btn  btn-height w-100"
          (click)="clearSearch()"
          style="color: #ffffff"
        >
          Clear All
        </button>
      </div>
    </div>
</div>

<!--  -->



<!-- <div class="content-header">
  <div class="col-lg-6 col-md-6 col-sm-12">
    <h1 class="m-0">Activities</h1>
    <p class="m-0">Today {{ todayDate | date: "dd MMMM YYYY" }}</p>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12 text-right">
    <button
      type="submit"
      mat-raised-button
      class="theme-bg c-btn"
      (click)="GoToSubActivity('Phone')"
    >
      Call</button
    >&nbsp;&nbsp;
    <button
      type="submit"
      mat-raised-button
      class="theme-bg c-btn"
      (click)="GoToSubActivity('Event')"
    >
      Event</button
    >&nbsp;&nbsp;
    <button
      type="submit"
      mat-raised-button
      class="theme-bg c-btn"
      (click)="GoToSubActivity('Task')"
    >
      Task
    </button>
  </div>
</div> -->
<div class="activity-content">
  <mat-card class="opportunities-card">
    <div class="align-items-center row">
     
     
    </div>
   
    <table *ngIf="dataSource?.length>0" mat-table [dataSource]="dataSource" class="w-100" matSort>
      <!-- <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container> -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type || "-" }}</td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let element">{{ element.title || "-" }}</td>
      </ng-container>
      <ng-container matColumnDef="relatedCustomer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Related Company
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.relatedCustomer || "-" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="relatedTransaction">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Related Transaction
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.relatedTransaction || "-" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="relatedContact">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Related Contact
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.relatedContact || "-" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.startDate | date: "dd MMM YYYY" || "-" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element.startTime | date: "HH:mm" || "-" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="endTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element.endTime | date: "HH:mm" || "-" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status || "-" }}</td>
      </ng-container>
      <ng-container matColumnDef="salesRep">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sales Rep</th>
        <td mat-cell *matCellDef="let element">
          {{ element.salesRep || "-" }}
        </td>
      </ng-container>
      <!--
            <ng-container matColumnDef="relatedContact">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Related Contact </th>
                <td mat-cell *matCellDef="let element"> {{element.relatedContact}} </td>
            </ng-container> -->
      <!-- <ng-container matColumnDef="salesRep">
                <th mat-header-cell *matHeaderCellDef> Sales Rep </th>
                <td mat-cell *matCellDef="let element"> {{element.salesRep}} </td>
            </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns1"
        (click)="selection(row)"
        style="cursor: pointer"
      ></tr>
    </table>
  </mat-card>
</div>
