<form [formGroup]="form">
    <mat-card-content>
        <!-- wizard start -->
        <ng-wizard [config]="config" (stepChanged)="stepChanged($event)" id="c-tab-bar">

            <!-- step 1 -->
            <ng-wizard-step [title]="'Step 1'" [description]="'Step 1 Description'" [canEnter]="isValidTypeBoolean" [canExit]="isValidFunctionReturnsBoolean.bind(this)">
                <mat-form-field class="example-full-width custom-input-wrapper" appearance="legacy">
                    <mat-label>Author Name</mat-label>
                    <input matInput placeholder="Author Name" formControlName="authorName" required>
                </mat-form-field>
                <div class="custom-error" *ngIf="form.controls['authorName'].hasError('required') && submitted">
                    Please enter Author Name </div>
                <br>
                <mat-form-field class="example-full-width custom-input-wrapper" appearance="legacy">
                    <mat-label>Title</mat-label>
                    <input matInput placeholder="Title" formControlName="title" required>
                </mat-form-field>
                <div class="custom-error" *ngIf="form.controls['title'].hasError('required') && submitted">
                    Please enter Title</div>
            </ng-wizard-step>

            <!-- step 2 -->
            <ng-wizard-step [title]="'Step 2'" [description]="'Step 2 Description'">
                <mat-form-field class="example-full-width custom-input-wrapper" appearance="legacy">
                    <mat-label>Genres</mat-label>
                    <input matInput placeholder="Genres" formControlName="genres" required>
                </mat-form-field>
                <div class="custom-error" *ngIf="form.controls['genres'].hasError('required') && submitted">
                    Please enter Genres</div>
                <br>
                <mat-form-field class="example-full-width custom-input-area" appearance="legacy">
                    <mat-label>Synopsis</mat-label>
                    <textarea matInput placeholder="Synopsis" name="synopsis" formControlName="synopsis" required rows="5"></textarea>
                    <mat-hint align="end"><span *ngIf="form.value.synopsis">
                            {{form.value.synopsis.length}}</span> / 500
                    </mat-hint>
                </mat-form-field>
                <div class="custom-error" *ngIf="form.controls['synopsis'].hasError('required') && submitted">
                    Please enter Synopsis</div>
                <br>
                <mat-form-field class="example-full-width custom-input-wrapper" appearance="legacy">
                    <mat-label>Released Date</mat-label>
                    <input matInput [matDatepicker]="dueDatePicker" placeholder="DD/MM/YYYY" formControlName="release_date" required>
                    <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #dueDatePicker></mat-datepicker>
                </mat-form-field>
                <div class="custom-error" *ngIf="form.controls['release_date'].hasError('required') && submitted">
                    Please enter Released Date</div>
            </ng-wizard-step>

            <!-- step 3 -->
            <ng-wizard-step [title]="'Step 3'" [description]="'Step 3 Description'" [canEnter]="isValidFunctionReturnsObservable.bind(this)" [canExit]="isValidFunctionReturnsBoolean.bind(this)">
                <mat-card class="example-card">
                    <div class="card-img">
                        <img mat-card-image src="../../../assets/img/card-img2.jpg" alt="Photo of a Shiba Inu" class="block-img">
                    </div>
                    <!-- grid view display -->
                    <mat-card-content class="content">
                        <table>
                            <tr>
                                <td>Author Name </td>
                                <td class="dot-td">:</td>
                                <td class="data-td"> {{form.value.authorName}}</td>
                            </tr>
                            <tr>
                                <td>Title </td>
                                <td class="dot-td">:</td>
                                <td class="data-td"> {{form.value.title}}</td>
                            </tr>
                            <tr>
                                <td>Genres </td>
                                <td class="dot-td">:</td>
                                <td class="data-td"> {{form.value.genres}}</td>
                            </tr>
                            <tr>
                                <td>Synopsis </td>
                                <td class="dot-td">:</td>
                                <td class="data-td"> {{form.value.synopsis}}</td>
                            </tr>
                            <tr>
                                <td>Released Date </td>
                                <td class="dot-td">:</td>
                                <td class="data-td"> {{form.value.release_date | date:'dd/MM/yyyy'}}</td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </ng-wizard-step>
        </ng-wizard>
        <!-- wizard end -->
    </mat-card-content>
</form>