import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { CommonProvider } from 'src/app/shared/common';
import { Apiurl } from 'src/app/shared/route';
import * as moment from 'moment';
import * as _ from 'underscore';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})
export class OpportunitiesComponent implements OnInit {
  displayedColumns: string[] = [
    'dateCreated',
    'name',
    'title',
    'date',
    'status',
    'probability',
    'expectedClose',
    'actualClose',
    'salesRep',
    'projectedTotal',
    'forecastType'
  ];
  dataSource: any;
  mainDataSouce: any = [];
  showFilter: boolean = false;
  @ViewChild(MatSort) sort: any;
  panelOpenState = false;
  searchText: any = '';
  Company: any;
  OpportunityStatus: any;
  companyList: any = [];
  OpportunityStatusList: any = [];
  datepicker: any = '';
  TodayDate: any = new Date().toDateString();
  constructor(private commonService: CommonProvider, private router: Router,private SpinnerService: NgxSpinnerService,) { }
  ngOnInit(): void {
    this.getAllQuotes();
    this.getAllStages();
    this.getAllLookupList();
  }

  getAllStages() {
    this.commonService
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading..')
      .then((res: any) => {
        this.companyList = [];
        if (res.length != 0) {
          let companyName = _.map(res, function (company) {
            return company;
          });
          this.companyList = companyName;
        }
      })
      .catch(error => { });
  }
  getAllLookupList() {
    this.commonService
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          console.log('res', res);
          this.OpportunityStatusList = res.opportunity.opportunityStatus;
        }
      });
  }

  getAllQuotes() {
    this.commonService
      .GetMethod(Apiurl.getAllOpportunityList, null, true, 'Loading')
      .then((resp: any) => {
        if (resp) {
          console.log(resp)
          this.commonService
          .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading')
          .then((res: any) => {
           for(let i=0;i<resp.length;i++){
             let data = _.findWhere(res,{id:resp[i].customerId})
             resp[i].name=data.legalName
           }
           this.mainDataSouce = resp;
          this.dataSource = new MatTableDataSource(resp);
          this.dataSource.sort = this.sort;
          })
        }
      });
  }
  inputEvent(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterTypeChange(filterValue);
  }
  openFilter() {
    this.showFilter = !this.showFilter;
  }
  viewRow(row: any) {
    var navigationExtras: NavigationExtras = {
      state: { row, isEdit: true }
    };
    this.router.navigate(['add-opportunities'], navigationExtras);
  }
  filterTypeChange(event: any) {
    if (event?.target?.value) {
      this.datepicker = event.target.value;
    }
    this.dataSource = new MatTableDataSource(this.mainDataSouce);

    if (this.searchText !== '') {
      this.dataSource.filter = this.searchText.trim().toLowerCase();
    }
    if (this.dataSource.filteredData.length === 0) {
      this.dataSource = new MatTableDataSource(this.mainDataSouce);
    }
    if (this.Company) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, { name: this.Company })
      );
    }
    if (this.OpportunityStatus) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, {
          status: this.OpportunityStatus
        })
      );
    }
    if (this.datepicker) {
      console.log(moment(this.datepicker).format('DD MMM YYYY'));
      let x: any = [];
      _.map(this.dataSource.filteredData, function (res) {
        x.push({ ...res, date: moment(res.date).format('DD MMM YYYY') });
      });
      this.dataSource = new MatTableDataSource(x);

      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, {
          date: moment(this.datepicker).format('DD MMM YYYY')
        })
      );
    }
  }
  clearSearch() {
    this.Company = null;
    this.OpportunityStatus = null;
    this.datepicker = null;
    this.dataSource = new MatTableDataSource(this.mainDataSouce);
  }

  sample() {
    alert('yes');
  }

  addOpportunities() {
    this.router.navigate(['add-opportunities']);
  }
}
