import { Apiurl } from './route';
// import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
// import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';



@Injectable({
    providedIn: 'root'
})
export class CommonProvider {
    navigation: any;

    constructor(
        public http: HttpClient,
        public notifier: NotifierService,
        private SpinnerService: NgxSpinnerService,
        // private spinner: NgxSpinnerService,
        private router: Router
    ) // private SpinnerService: NgxSpinnerService
    {
        // this.navigation = navigation;
    }

    showNotifier(type: any, message: any) {
        this.notifier.notify(type, message);
    }


    showConfirm(obj: any): any {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                
                confirmButton: 'btn btn-success swal-modal',
                cancelButton: 'btn btn-danger swal-modal'
            },
        
            buttonsStyling: false
            
        })
    
        return swalWithBootstrapButtons.fire({
            title: obj.title,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: obj.confirmButtonText,
            cancelButtonText: obj.cancelButtonText,
            reverseButtons: false,
         
            
        })
    }

    //GET METHOD
    GetMethod(url: any, data: any, IsShowLoader = true, LoadingMsg = 'Loading.') {
        // this.spinner.show();
        const httpHeader = new HttpHeaders({
            Authorization: 'bearer ' + localStorage.getItem('access_token')
        });
        return new Promise(async (resolve, reject) => {
            if (IsShowLoader) {
                this.SpinnerService.show();
            }
            return this.http
                .get(Apiurl.RoutePath + url, { params: data, headers: httpHeader })
                .subscribe(
                    (data: any) => {
                        if (data) {
                            resolve(data);
                        }
                        if (IsShowLoader) {
                            this.SpinnerService.hide();
                        }
                    },
                    (error: any) => {
                        if (IsShowLoader) {
                            this.SpinnerService.hide();
                        }
                        if (error.status === 401)
                            console.log('error caught in component', error);
                        resolve([]);
                    }
                );
        });
    }

    //POST METHOD
    PostMethod(
        url: any,
        data: any,
        IsShowLoader = true,
        LoadingMsg = 'Loading.',
        headers: any
    ) {
        // this.spinner.show();
        if (headers == null || Object.keys(headers).length === 0)
            headers = new HttpHeaders({
                Authorization: 'bearer ' + localStorage.getItem('access_token')
            });
        else
            headers.append(
                'Authorization',
                'bearer ' + localStorage.getItem('access_token')
            );

        return new Promise(async (resolve, reject) => {
            if (IsShowLoader) {
                this.SpinnerService.show();
            }
            /* console.log(routeObj[window.location.origin]); */
            // return this.http.post(Apiurl.RoutePath + url, data, { headers: httpHeader }).subscribe((data) => {
            return this.http
                .post(Apiurl.RoutePath + url, data, { headers })
                .subscribe(
                    (data: any) => {
                        if (IsShowLoader) {
                            this.SpinnerService.hide();
                        }
                        resolve(data);
                    },
                    (error: any) => {
                        if (IsShowLoader) {
                            this.SpinnerService.hide();
                        }
                        console.log(error);
                    }
                );
        });
    }

    PutMethod(
        url: any,
        data: any,
        IsShowLoader = true,
        LoadingMsg = 'Loading.',
        headers: any
    ) {
        // this.spinner.show();
        if (headers == null || Object.keys(headers).length === 0)
            headers = new HttpHeaders({
                Authorization: 'bearer ' + localStorage.getItem('access_token')
            });
        else
            headers.append(
                'Authorization',
                'bearer ' + localStorage.getItem('access_token')
            );

        return new Promise(async (resolve, reject) => {
            if (IsShowLoader) {
                this.SpinnerService.show();
            }
            /* console.log(routeObj[window.location.origin]); */
            // return this.http.post(Apiurl.RoutePath + url, data, { headers: httpHeader }).subscribe((data) => {
            return this.http.put(Apiurl.RoutePath + url, data, { headers }).subscribe(
                (data: any) => {
                    if (IsShowLoader) {
                        this.SpinnerService.hide();
                    }
                    resolve(data);
                    // this.spinner.hide();
                },
                (error: any) => {
                    if (IsShowLoader) {
                        this.SpinnerService.hide();
                    }
                    console.log(error);
                }
            );
        });
    }
}
