<div class="content-header">
    <div class="col-lg-6 col-md-6 col-sm-12">
        <h1 class="m-0">Quotes</h1>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-right">

        <button mat-raised-button class="green-btn c-btn" *ngIf="!isShowData" [disabled]="!showDisCountPage"
            (click)="saveAndContinue(true)"><span class="material-icons me-2">
                save
            </span>Save</button>
        <button mat-raised-button class="green-btn c-btn me-2" *ngIf="isShowData" (click)="editQuotes()"><span
                class="material-icons">
                edit
            </span> Edit</button>
        <button mat-raised-button class="green-btn c-btn me-2" *ngIf="!isShowData" style="margin-left: 1em;"
            (click)="cancelEditing()"><span class="material-icons me-2">
                cancel
            </span>Cancel</button>
        <button mat-raised-button class="theme-bg c-btn" *ngIf="isShowData" (click)="addQuotes()">
            + Add Quotes
        </button>
    </div>
</div>
<div class="quote-content" *ngIf="isShowData">
    <div class="row">
        <div class="col-lg-8">
            <div class="w-100">
                <mat-card class="related-contact-card ">
                    <button mat-button (click)="OpenBlock(1)" [class.purplecolor]="openBlock == 1">
                        Primary Information
                    </button>
                    <button mat-button (click)="OpenBlock(2)" [class.purplecolor]="openBlock == 2">
                        Forecast Information
                    </button>
                    <hr>
                    <table class=" w-100" *ngIf="openBlock == 2">
                        <tr style="font-size: small;color: grey;">
                            <td>
                                <mat-label>Sales Rep</mat-label>
                            </td>
                            <td>
                                <mat-label>Opportunity</mat-label>
                            </td>
                            <td>
                                <mat-label>Lead source</mat-label>
                            </td>
                            <td>
                                <mat-label>Forecast Type</mat-label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-label>{{rowData.salesRep}}</mat-label>
                            </td>
                            <td>
                                <mat-label>{{rowData.opportunity}}</mat-label>
                            </td>
                            <td>
                                <mat-label>{{rowData.leadSource}}</mat-label>
                            </td>
                            <td>
                                <mat-label>{{rowData.forecastTypeId}}</mat-label>
                            </td>
                        </tr>
                    </table>
                    <table class=" w-100" *ngIf="openBlock == 1">
                        <tr style="font-size: small;color: grey;">
                            <td>
                                <mat-label>Customer</mat-label>
                            </td>
                            <td>
                                <mat-label>Title</mat-label>
                            </td>
                            <td>
                                <mat-label>Date</mat-label>
                            </td>
                            <td>
                                <mat-label>Due Date</mat-label>
                            </td>
                            <td>
                                <mat-label>Status</mat-label>
                            </td>
                            <td>
                                <mat-label>Probability</mat-label>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <mat-label>{{rowData?.companyName}}</mat-label>
                            </td>
                            <td>
                                <mat-label>{{rowData?.title}}</mat-label>
                            </td>
                            <td>
                                <mat-label>{{rowData?.date |date: "dd MMM YYYY" }}</mat-label>
                            </td>
                            <td>
                                <mat-label>{{rowData?.dueDate | date: "dd MMM YYYY" }}</mat-label>
                            </td>
                            <td>
                                <mat-label>{{rowData?.status}}</mat-label>
                            </td>
                            <td>
                                <mat-label>{{rowData?.probability}}</mat-label>
                            </td>

                        </tr>
                        <br />
                        <tr style="font-size: small;color: grey;">
                            <td>
                                <mat-label>Expected Close</mat-label>
                            </td>
                            <td>
                                <mat-label>Memo</mat-label>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <mat-label>{{rowData?.expectedClose | date: "dd MMM YYYY" }}</mat-label>
                            </td>
                            <td>
                                <mat-label>{{rowData?.memo}}</mat-label>
                            </td>
                        </tr>
                    </table>
                </mat-card>
            </div>
            <mat-card class="w-100 mt-4 p-0">
                <table mat-table [dataSource]="quoteItems" class="mat-elevation-z8 w-100 quote-edit-table">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef> Quantity On Hand</th>
                        <td mat-cell *matCellDef="let element">
                            {{element.quantity}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef> Amount</th>
                        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayQuoteItemsInEdit"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayQuoteItemsInEdit;" (click)="selection.toggle(row)">
                    </tr>
                </table>
            </mat-card>
        </div>
        <div class="col-lg-4">
            <div class="w-100">
                <mat-card class="summry-card">
                    <h1 class="primary-text card-heading m-0 mb-3"><b>Summary</b></h1>
                    <!--  // no data-found card -->

                    <!-- <div class="no-content">
                                <h2 class="primary-text c-h2">No Summry data</h2>
                                <p class="secondry-light-text">Please add data to see the summary</p>
                            </div> -->
                    <div class="border-bottom">
                        <div class="summery-content">
                            <p class="secondry-light-text m-0">Services</p>
                            <p class="primary-text m-0">{{totalServiceAmount | number : '1.2-2'}}</p>
                        </div>
                        <div class="summery-content">
                            <p class="secondry-light-text m-0">Hardware</p>
                            <p class="primary-text m-0">{{totalHardwareAmount | number : '1.2-2'}}</p>
                        </div>
                        <!-- <div class="summery-content">
                                        <p class="secondry-light-text m-0">Subscription</p>
                                        <p class="primary-text m-0">1,000.00 AUD</p>
                                    </div>
                                    <div class="summery-content">
                                        <p class="secondry-light-text m-0">Subscription</p>
                                        <p class="primary-text m-0">1,000.00 AUD</p>
                                    </div> -->
                    </div>
                    <div class="pt-2 pb-2 ">
                        <div class="summery-content">
                            <p class="primary-text m-0"><b>GRAND TOTAL</b></p>
                            <p class="green-text m-0"><b>{{quotesForm.value.amountAfterDiscount | number : '1.2-2'}}</b>
                            </p>
                        </div>
                        <div class="summery-content">
                            <p class="secondry-light-text m-0">Subscription</p>
                            <p class="primary-text m-0">{{totalSubscriptionAmount | number : '1.2-2'}}/wk</p>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div class="mt-4">
                <mat-card class="related-contact-card ">
                    <div class="d-flex justify-content-between align-items-center ">
                        <h1 class="primary-text card-heading m-0"><b>Related Contacts</b></h1>
                        <button mat-raised-button class="theme-bg c-btn" (click)="addContact()">
                            New
                        </button>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-10">
                            <mat-form-field class="example-full-width w-100" appearance="outline">
                                <input matInput placeholder="Search">
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-lg-2 h-100">
                            <button mat-raised-button class="btn-link">
                                <span class="material-icons">
                                    link
                                </span>
                            </button>
                        </div> -->
                    </div>
                    <div class="contact-height">
                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2 pt-4"
                            *ngFor="let contact of contactData">
                            <div>
                                <h2 class="primary-text c-h2 m-0 mb-2">{{contact.name}}</h2>
                                <p class="secondry-light-text">{{contact.jobTitle}}</p>
                            </div>
                            <div class="material-icons">
                                chevron_right
                            </div>
                        </div>
                    </div>
                </mat-card>
                <!-- <mat-card class="related-contact-card ">
                    <div class="d-flex justify-content-between align-items-center ">
                        <h1 class="primary-text card-heading m-0"><b>Related Contacts</b></h1>
                        <button mat-raised-button class="theme-bg c-btn" (click)="addContact()">
                            New
                        </button>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-10">
                            <mat-form-field class="example-full-width w-100" appearance="outline">
                                <input matInput placeholder="Search">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2 h-100">
                            <button mat-raised-button class="btn-link">
                                <span class="material-icons">
                                    link
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center border-bottom pb-2 pt-4"
                        *ngFor="let item of contactData">
                        <div>
                            <h2 class="primary-text c-h2 m-0 mb-2">{{item.name}}</h2>
                            <p class="secondry-light-text">{{item.jobTitle}}</p>
                        </div>
                        <div class="material-icons">
                            chevron_right
                        </div>
                    </div>

                </mat-card> -->
            </div>
            <div class="mt-4">
                <mat-card class="related-contact-card ">
                    <div class="d-flex justify-content-between align-items-center ">
                        <h1 class="primary-text card-heading m-0"><b>Recent Activities
                            </b></h1>
                        <!-- *ngIf="isViewData"  -->
                        <button mat-raised-button class="theme-bg c-btn" (click)="gotoActivity()">
                            New
                        </button>
                    </div>
                    <div class="d-flex align-items-center border-bottom pb-2 pt-4"
                        *ngFor="let item of relatedActivityArray">
                        <div class="img-wrapper blue">
                            <img *ngIf="item.type == 'Task'" src="../../../assets/img/list_icon.svg">
                            <img *ngIf="item.type == 'Phone'" src="../../../assets/img/call_icon.svg">
                            <img *ngIf="item.type == 'Event'" src="../../../assets/img/New_event.svg">
                        </div>
                        <div>
                            <h2 class="primary-text c-h2 m-0 mb-2">
                                {{item.title}}
                            </h2>
                            <p class="secondry-light-text"> {{item.startDate | date:'dd MMM YYYY'}}</p>
                        </div>
                    </div>
                    <div *ngIf="relatedActivityArray.length == 0"
                        class="d-flex align-items-center border-bottom pb-2 pt-4">No Activity Found</div>
                </mat-card>
            </div>
        </div>
    </div>
</div>
<div class="quote-content" *ngIf="!isShowData">
    <form [formGroup]="quotesForm" (ngSubmit)="onSubmit(quotesForm)">
        <div class="row">
            <div class="col-lg-8">
                <div class="w-100">
                    <mat-accordion class="example-headers-align" multi>
                        <mat-expansion-panel class="border-bottom" expanded>
                            <mat-expansion-panel-header>
                                <mat-panel-title class="primary-text">
                                    <b>Primary Information</b>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Company</mat-label>
                                        <mat-select formControlName="customerId" required>
                                            <mat-option *ngFor="let company of listCompanies" [value]="company.id">
                                                {{company.legalName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Title</mat-label>
                                        <input formControlName="title" matInput type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Date</mat-label>
                                        <input formControlName="date" matInput [matDatepicker]="picker"
                                            (focus)="picker.open()" readonly>
                                        <mat-icon matSuffix>close</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-datepicker #picker1></mat-datepicker>
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Due Date</mat-label>
                                        <input formControlName="dueDate" matInput [matDatepicker]="picker1"
                                            (focus)="picker1.open()" readonly>
                                        <mat-icon matSuffix>close</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Status</mat-label>
                                        <mat-select formControlName="statusId">
                                            <mat-option *ngFor="let lookup of listLookups?.quotes?.quotesStatus"
                                                [value]="lookup?.id">
                                                {{lookup?.statusDescription}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Probability</mat-label>
                                        <input formControlName="probability" matInput type="number">
                                        <mat-icon matSuffix>%</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Sales Rep</mat-label>
                                        <mat-select formControlName="salesRepId" required>
                                            <mat-option *ngFor="let lookups of listLookups?.quotes?.salesRep"
                                                [value]="lookups.id">
                                                {{lookups.salesRepDescription}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Expected Close</mat-label>
                                        <input formControlName="expectedClose" matInput [matDatepicker]="picker2"
                                            (focus)="picker2.open()" readonly>
                                        <mat-icon matSuffix>close</mat-icon>
                                    </mat-form-field>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Memo</mat-label>
                                        <input formControlName="memo" matInput type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Subsidary</mat-label>
                                        <mat-select formControlName="subsidiaryId" required readonly>
                                            <mat-option *ngFor="let lookups of listLookups?.quotes?.subsidiaries"
                                                [value]="lookups.id">
                                                {{lookups.subsidiaryDescription}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel expanded>
                            <mat-expansion-panel-header>
                                <mat-panel-title class="primary-text">
                                    <b>Forecast Information</b>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Sales Rep</mat-label>
                                        <mat-select formControlName="salesRepId">
                                            <mat-option *ngFor="let lookups of listLookups?.quotes?.salesRep"
                                                [value]="lookups.id">
                                                {{lookups.salesRepDescription}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div> -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Opportunity</mat-label>
                                        <mat-select formControlName="opportunity" required>
                                            <mat-option *ngFor="let oppprotunity of listopportunities"
                                                [value]="oppprotunity.id">
                                                {{oppprotunity.documentNumber}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Lead Source</mat-label>
                                        <mat-select formControlName="leadSourceId" required>
                                            <mat-option *ngFor="let lookups of listLookups?.company?.leadSource"
                                                [value]="lookups.id">
                                                {{lookups.leadTypeDescription}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Forecast Type</mat-label>
                                        <mat-select formControlName="forecastTypeId">
                                            <mat-option *ngFor="let lookups of listLookups?.quotes?.forecastType"
                                                [value]="lookups.id">
                                                {{lookups.forecastTypeDescription}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="mt-4">
                    <mat-card class="item-card p-0">
                        <div class="border-bottom w-100 d-flex justify-content-between align-items-center p-3">
                            <h1 class="primary-text card-heading m-0"><b>Item to be Purchased</b></h1>
                            
                        </div>
                        <div class="w-100 ">
                            <div class="border-bottom w-100 pb-3 pt-3 ps-3 d-flex justify-content-between"
                                *ngIf="!showSavedItemsList">
                                <div class="d-flex align-items-center flex-wrap">
                                    <div class="mb-2 tab-list-wrapper">
                                        <button mat-stroked-button (click)="goTo('templates')"
                                            [ngClass]="{'active': activeTab === 'templates'}">Templates</button>
                                        <button mat-stroked-button (click)="goTo('hardware')"
                                            [ngClass]="{'active': activeTab === 'hardware'}">Hardware</button>
                                        <button mat-stroked-button (click)="goTo('services')"
                                            [ngClass]="{'active': activeTab === 'services'}">Services</button>
                                        <button mat-stroked-button (click)="goTo('subscriptions')"
                                            [ngClass]="{'active': activeTab === 'subscriptions'}">Subscriptions</button>
                                    </div>
                                    <div class="c-mat-select-wrapper mb-2">
                                        <mat-form-field appearance="outline">
                                            <mat-icon matPrefix>search</mat-icon>
                                            <mat-label>Search</mat-label>
                                            <input matInput>
                                            <mat-icon matSuffix>close</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="me-3">
                                    <button mat-raised-button (click)="saveAndContinue()"
                                        class="theme-bg c-btn border-none">
                                        <mat-icon matPrefix>chevron_right</mat-icon>
                                        Save & Continue
                                    </button>
                                </div>
                            </div>
                            <div class="border-bottom w-100 pb-3 pt-3 ps-3 d-flex justify-content-end"
                                *ngIf="showSavedItemsList">
                                <button mat-stroked-button *ngIf="showSavedItemsList && !showDisCountPage"
                                    (click)="goBackToSelectionList()" class="green-btn">
                                    <mat-icon matPrefix>chevron_left</mat-icon>
                                    Back
                                </button>
                                <button mat-stroked-button *ngIf="showDisCountPage" (click)="goBackToSavedItemsList()"
                                    class="green-btn">
                                    <mat-icon matPrefix>chevron_left</mat-icon>
                                    Back
                                </button>
                                <button mat-raised-button color="primary" *ngIf="!showDisCountPage"
                                    (click)="updateQuotes()" class="save-btn">
                                    <mat-icon matPrefix>chevron_right</mat-icon>
                                    Save & Continue
                                </button>
                            </div>
                            <div class="ms-3 me-3"
                                *ngIf="activeTab === 'templates' && !showSavedItemsList  && !showDisCountPage">
                                <mat-accordion class="example-headers-align c-accordian" multi>
                                    <mat-expansion-panel *ngFor="let templates of listTemplates">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title class="primary-text">
                                                <mat-checkbox [checked]="templates.selected" matPrefix
                                                    (change)="calculateTotalValue(templates)"
                                                    (click)="$event.stopPropagation()" class="px-4">
                                                </mat-checkbox>
                                                {{templates.templateName}}
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="container p-0">
                                            <mat-list class="p-0">
                                                <mat-list-item *ngFor="let item of templates.items"
                                                    class="c-mat-list-item">
                                                    <div>{{item.quantity}} x {{item.itemName}}</div>
                                                    <div>{{item.amount | number : '1.2-2' || 0.00}}</div>
                                                </mat-list-item>
                                            </mat-list>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <div class="" *ngIf="activeTab === 'hardware' && !showSavedItemsList  && !showDisCountPage">
                                <table mat-table [dataSource]="listItems" class=" quote-tab">
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                [aria-label]="checkboxLabel()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="selection.toggle(row);calculateHardwareItems(row) "
                                                (click)="$event.stopPropagation()"
                                                [checked]="selection.isSelected(row) || row.selected"
                                                [aria-label]="checkboxLabel(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef> Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef> Quantity On Hand </th>
                                        <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="basePrice">
                                        <th mat-header-cell *matHeaderCellDef> Base Price </th>
                                        <td mat-cell *matCellDef="let element"> {{element.basePrice}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                        (click)="selection.toggle(row)">
                                    </tr>
                                </table>
                            </div>
                            <div class="" *ngIf="activeTab === 'services' && !showSavedItemsList  && !showDisCountPage">
                                <table mat-table [dataSource]="listItems" class=" quote-tab">
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                [aria-label]="checkboxLabel()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="selection.toggle(row);calculateServiceItems(row) "
                                                (click)="$event.stopPropagation()"
                                                [checked]="selection.isSelected(row) || row.selected"
                                                [aria-label]="checkboxLabel(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef> Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef> Quantity On Hand </th>
                                        <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="basePrice">
                                        <th mat-header-cell *matHeaderCellDef> Base Price </th>
                                        <td mat-cell *matCellDef="let element"> {{element.basePrice}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                        (click)="selection.toggle(row)">
                                    </tr>
                                </table>
                            </div>
                            <div class=""
                                *ngIf="activeTab === 'subscriptions' && !showSavedItemsList  && !showDisCountPage">
                                <table mat-table [dataSource]="listItems" class="quote-tab">
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                [aria-label]="checkboxLabel()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="selection.toggle(row);calculateSubscriptionItems(row) "
                                                (click)="$event.stopPropagation()"
                                                [checked]="selection.isSelected(row) || row.selected"
                                                [aria-label]="checkboxLabel(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="name">
                                        <th mat-header-cell *matHeaderCellDef> Name </th>
                                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="quantity">
                                        <th mat-header-cell *matHeaderCellDef> Quantity On Hand </th>
                                        <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="basePrice">
                                        <th mat-header-cell *matHeaderCellDef> Base Price </th>
                                        <td mat-cell *matCellDef="let element"> {{element.basePrice}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                        (click)="selection.toggle(row)">
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="" *ngIf="showSavedItemsList  && !showDisCountPage">
                            <table mat-table [dataSource]="quoteItems" class=" w-100">
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef class="primary-text"><b>Item</b> </th>
                                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="rate">
                                    <th mat-header-cell *matHeaderCellDef class="text-end ps-3 pe-3 primary-text">
                                        <b>Base Price</b>
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="text-end ps-3 pe-3">
                                        {{element.rate}} </td>
                                </ng-container>
                                <ng-container matColumnDef="amount">
                                    <th mat-header-cell *matHeaderCellDef class="text-end ps-3 pe-3 primary-text">
                                        <b>Amount</b>
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="text-end ps-3 pe-3">
                                        {{element.amount | number : '1.2-2'}} </td>
                                </ng-container>
                                <ng-container matColumnDef="quantity">
                                    <th mat-header-cell *matHeaderCellDef class="text-end primary-text"><b>Quantity</b>
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="text-end">
                                        <button mat-stroked-button (click)="decreaseItemQuantity(element)" class="me-3">
                                            <mat-icon class="secondary-text">remove</mat-icon>
                                        </button> {{element.quantity}}
                                        <button mat-stroked-button (click)="increaseItemQuantity(element)" class="ms-3">
                                            <mat-icon class="secondary-text">add</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayQuoteItems"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayQuoteItems;"
                                    (click)="selection.toggle(row)">
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="showDisCountPage" class="col-lg-12">
                            <div class="row m-0 pt-5 pb-5">
                                <div class="col-lg-8 border-right">
                                    <div class="summry-card">
                                        <h1 class="primary-text card-heading m-0 mb-3"><b>Summary</b></h1>
                                        <div class="border-bottom">
                                            <div class="summery-content">
                                                <p class="secondry-light-text m-0">Services</p>
                                                <p class="primary-text m-0">{{totalServiceAmount | number : '1.2-2'}}
                                                </p>
                                            </div>
                                            <div class="summery-content">
                                                <p class="secondry-light-text m-0">Hardware</p>
                                                <p class="primary-text m-0">{{totalHardwareAmount | number : '1.2-2'}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="pt-2 pb-2 ">
                                            <div class="summery-content">
                                                <p class="primary-text m-0"><b>GRAND TOTAL</b></p>
                                                <p class="primary-text m-0"><b>
                                                        {{ quotesForm.value.amountAfterDiscount | number : '1.2-2'}}</b>
                                                </p>
                                            </div>
                                            <div class="summery-content">
                                                <p class="secondry-light-text m-0">Subscription</p>
                                                <p class="primary-text m-0">
                                                    {{totalSubscriptionAmount | number:'1.2-2'}}/wk</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="w-100">
                                        <div class="w-100 d-flex justify-content-between align-items-center mb-3">
                                            <h1 class="primary-text card-heading m-0"><b>Add Discount</b></h1>
                                            <div class="d-flex add-discount">
                                                <button mat-raised-button (click)="selectDiscountType('dollar')"
                                                    class="me-2" [ngClass]="{'active': discountType == 'dollar'}">
                                                    <mat-icon>attach_money</mat-icon>
                                                </button>
                                                <button mat-raised-button (click)="selectDiscountType('percent')"
                                                    [ngClass]="{'active': discountType == 'percent'}">
                                                    <mat-icon>%</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label class="secondary-text">Discount</mat-label>
                                            <input *ngIf="discountType == 'dollar'" formControlName="dollarDiscount"
                                                matInput type="number" (input)="addDiscount(discountType)"
                                                placeholder="0.00">
                                            <input *ngIf="discountType == 'percent'" placeholder="0"
                                                formControlName="percentageDiscount" matInput type="number"
                                                (input)="addDiscount(discountType)">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.08);"
                            *ngIf="activeTab === 'templates' && !showDisCountPage && !showSavedItemsList">
                            <mat-card class="">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="w-100">
                                        <p class="m-0">{{totalSelectedCheckBox}} items Selected</p>
                                    </div>
                                    <!-- <div class="text-right w-100">
                                        <button mat-raised-button class="theme-bg c-btn">
                                            Select
                                        </button>
                                    </div> -->
                                </div>
                            </mat-card>
                        </div>
                    </mat-card>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="w-100">
                    <mat-card class="summry-card">
                        <h1 class="primary-text card-heading m-0 mb-3"><b>Summary</b></h1>
                        <!--  // no data-found card -->

                        <!-- <div class="no-content">
                        <h2 class="primary-text c-h2">No Summry data</h2>
                        <p class="secondry-light-text">Please add data to see the summary</p>
                    </div> -->
                        <div class="border-bottom">
                            <div class="summery-content">
                                <p class="secondry-light-text m-0">Services</p>
                                <p class="primary-text m-0">{{totalServiceAmount| number:'1.2-2'}}</p>
                            </div>
                            <div class="summery-content">
                                <p class="secondry-light-text m-0">Hardware</p>
                                <p class="primary-text m-0">{{totalHardwareAmount| number:'1.2-2'}}</p>
                            </div>
                            <!-- <div class="summery-content">
                                <p class="secondry-light-text m-0">Subscription</p>
                                <p class="primary-text m-0">1,000.00 AUD</p>
                            </div>
                            <div class="summery-content">
                                <p class="secondry-light-text m-0">Subscription</p>
                                <p class="primary-text m-0">1,000.00 AUD</p>
                            </div> -->
                        </div>
                        <div class="pt-2 pb-2 ">
                            <div class="summery-content">
                                <p class="primary-text m-0"><b>GRAND TOTAL</b></p>
                                <p class="green-text m-0"><b>{{ quotesForm.value.amountAfterDiscount | number :
                                        '1.2-2'}}</b>
                                </p>
                            </div>
                            <div class="summery-content">
                                <p class="secondry-light-text m-0">Subscription</p>
                                <p class="primary-text m-0">{{totalSubscriptionAmount | number:'1.2-2'}}/wk</p>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="mt-4" *ngIf="showExtras">
                    <mat-card class="related-contact-card ">
                        <div class="d-flex justify-content-between align-items-center ">
                            <h1 class="primary-text card-heading m-0"><b>Related Contacts</b></h1>
                        </div>
                        <div class="row mt-3">
                            <div class="col-lg-12">
                                <mat-form-field class="example-full-width w-100" appearance="outline">
                                    <input matInput placeholder="Search">
                                </mat-form-field>
                            </div>
                            <!-- <div class="col-lg-2 h-100">
                                <button mat-raised-button class="btn-link">
                                    <span class="material-icons">
                                        link
                                    </span>
                                </button>
                            </div> -->
                        </div>
                        <div class="contact-height">
                            <div class="d-flex justify-content-between align-items-center border-bottom pb-2 pt-4"
                                *ngFor="let contact of contactData">
                                <div>
                                    <h2 class="primary-text c-h2 m-0 mb-2">{{contact.name}}</h2>
                                    <p class="secondry-light-text">{{contact.jobTitle}}</p>
                                </div>
                                <div class="material-icons">
                                    chevron_right
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="mt-4" *ngIf="showExtras">
                    <mat-card class="related-contact-card ">
                        <div class="d-flex justify-content-between align-items-center ">
                            <h1 class="primary-text card-heading m-0"><b>Recent Activities
                                </b></h1>
                        </div>
                        <div class="contact-height">
                            <!-- <div class="d-flex align-items-center border-bottom pb-2 pt-4"
                                *ngFor="let activity of activityList">
                                <div class="img-wrapper blue">
                                    <img src="../../../assets/img/list_icon.svg">
                                </div>
                                <div>
                                    <h2 class="primary-text c-h2 m-0 mb-2">{{activity.message}}</h2>
                                    <p class="secondry-light-text">{{activity.startDate | date: "dd MMM YYYY hh:mm a" }}
                                    </p>
                                </div>
                            </div> -->
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    </form>
</div>