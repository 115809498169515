<mat-toolbar color="primary">
    <mat-toolbar-row class="c-mat-toolbar-row">
        <div class="col-lg-5">
            <!-- <div class="search-wrapper">
                <input type="text" placeholder="Type keywords to find your search" [formControl]="control" [matAutocomplete]="auto" class="w-100">
                <mat-autocomplete #auto="matAutocomplete" class="w-100">
                    <mat-option *ngFor="let street of filteredStreets | async" [value]="street">
                        {{street}}
                    </mat-option>
                </mat-autocomplete>
                <span class="material-icons secondry-light-text search-icon">search</span>
            </div> -->
        </div>
        <div class="col-lg-7">
            <div class="top-bar-wrapper">
                <div class="d-flex align-items-center justify-content-end w-100">
                    <div class="header-profile d-flex align-items-center">
                        <div class="material-icons secondary-text">
                            notifications
                        </div>
                        <div class="profile-wrapper">
                            <button mat-button [matMenuTriggerFor]="menu" class="header-profile-img">
                                <div class="profile-menu">
                                    <img src="../../../assets/img/user_icon.svg">
                                </div>
                            </button>
                            <div class="header-profile-name">
                                <h6 class="m-0 primary-text">Bruce Wayne</h6>
                                <p class="m-0 secondry-light-text">Administrator</p>
                            </div>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="logout()">
                                    <mat-icon>exit_to_app</mat-icon>
                                    Sign out
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>