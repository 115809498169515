import { Apiurl } from './../../shared/route';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonProvider } from 'src/app/shared/common';
import { Router, NavigationExtras } from '@angular/router';
import { map, filter } from 'underscore';
import * as _ from 'underscore';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  searchText: any = '';
  filterShow: boolean = false;

  displayedColumns: string[] = [
    'stage',
    'legalName',
    'tradingName',
    'leadStatus',
    'phone',
    'email',
    'leadSource',
    'marketIndustry',
    'tier',
    'salesRep'
  ];
  dataSource: any;
  @ViewChild(MatSort) sort: any;
  TodayDate: any = new Date().toDateString();
  panelOpenState = false;
  showFilter: boolean = false;
  stageList: any = [];
  statusList: any = [];
  marketList: any = [];
  salesRepList: any = [];
  stageType: any;
  statusType: any;
  marketType: any;
  salesRepType: any;
  mainDataSouce: any = [];
  searchInput: any;
  isLoading = true;
  constructor(
    private commonService: CommonProvider,
    private router: Router,
    private SpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    if (history.state.stage && history.state.status) {
      console.log('alert', history.state);
      this.stageType = history.state.stage;
      this.statusType = history.state.status;
      this.showFilter = true;
    }
    this.getAllCompanies();
    this.getAllStages();
  }
  getAllCompanies() {
    this.SpinnerService.show();
    this.commonService
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          console.log('res', res);
          this.mainDataSouce = res;
          if (history.state.stage && history.state.status) {
            this.filterTypeChange('event');
          } else {
            this.dataSource = new MatTableDataSource(res);
            this.dataSource.sort = this.sort;
            this.dataSource.sort.matSortChange;
          }
          this.SpinnerService.hide();
        }
      })
      .catch(error => {
        this.isLoading = false;
        console.log(error);
        this.SpinnerService.hide();
      });
  }
  AddCompanies() {
    this.router.navigate(['add-companies']);
  }
  showDetails(elemet: any) {
    let param: NavigationExtras = {
      state: {
        id: elemet
      }
    };
    this.router.navigate(['companies-details'], param);
  }

  getAllStages() {
    this.commonService
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading..')
      .then((res: any) => {
        this.stageList = [];
        this.marketList = [];
        this.salesRepList = [];
        this.statusList = [];
        if (res.length != 0) {
          this.stageList = res.stage.stage;
          this.statusList = res.company.leadStatus;
          this.marketList = res.company.marketIndustry;
          this.salesRepList = res.opportunity.salesRep;
        }
      });
  }

  filterTypeChange(event: any) {
    this.dataSource = new MatTableDataSource(this.mainDataSouce);
    console.log('this.dataSource', this.dataSource);
    if (this.searchText !== '') {
      this.dataSource.filter = this.searchText.trim().toLowerCase();
    }
    if (this.stageType) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, {
          stage: this.stageType
        })
      );
    }
    if (this.statusType) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, {
          leadStatus: this.statusType
        })
      );
    }
    if (this.marketType) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, {
          marketIndustry: this.marketType
        })
      );
    }
    if (this.salesRepType) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, {
          salesRep: this.salesRepType
        })
      );
    }
    this.dataSource.sort = this.sort;
  }

  clearSearch() {
    this.stageType = null;
    this.statusType = null;
    this.marketType = null;
    this.salesRepType = null;
    this.dataSource = new MatTableDataSource(this.mainDataSouce);
  }
  openFilter() {
    this.showFilter = !this.showFilter;
  }

  inputEvent(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterTypeChange(filterValue);
  }

  goToAddContact() {
    this.router.navigate(['add-company']);
  }

  clear() {
    this.searchText = '';
    this.getAllCompanies();
  }
  test() {
    console.log(this.dataSource._sort);
    console.log(this.dataSource._sort._direction);
  }
}
