<div class="content-header">
    <div class="col-lg-6 col-md-6 col-sm-12">
        <h1 class="m-0">Company</h1>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-right">
        <button mat-raised-button class="green-btn c-btn me-2" (click)="saveCustomer()">
            <span class="material-icons me-2">
                save
            </span>
            Save
        </button>
        <button mat-raised-button class="green-btn c-btn" (click)="goBackToListing()">
            <span class="material-icons me-2">
                cancel
            </span>
            Cancel
        </button>
    </div>
</div>

<div class="company-content hidden">
    <form [formGroup]="addCompanyForm">
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <mat-card>
                        <mat-tab-group mat-align-tabs="start" class="content">
                            <!-- 1 Primary Information -->
                            <mat-tab label="Primary Information" style="margin: 10px;">
                                <div class="row" style="margin: 10px;">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Stage </mat-label>
                                            <mat-select formControlName="stageTypeId" required>
                                                <mat-option *ngFor="let obj of listLookups?.stage?.stage"
                                                    [value]="obj.id">
                                                    {{ obj.stageDescription }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf=" addCompanyForm.controls['stageTypeId'].hasError(
                            'required') && (addCompanyForm.controls['stageTypeId'].touched || submitted)">
                                                Required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label class="secondary-text">Company Name
                                            </mat-label>
                                            <input formControlName="companyName" placeholder="Company Name" matInput
                                                type="text" required (keyup)="inputCompanyName($event)" />
                                            <mat-error *ngIf="
                          addCompanyForm.controls['companyName'].hasError(
                            'required'
                          ) &&
                          (addCompanyForm.controls['companyName'].touched ||
                            submitted)
                        ">
                                                Required.</mat-error>
                                                <mat-error *ngIf="addCompanyForm.value.companyId == undefined">
                                                    Please enter valid company.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Parent Name</mat-label>
                                           
                                            <mat-select
                                            (click)="clearCompanyDetails()"
                                            formControlName="parentType"
                                            [ngModelOptions]="{ standalone: true }"
                                          >
                                            <div style="padding:15px">
                                              <input
                                                type="text"
                                                [(ngModel)]="searchdropdown"
                                                matInput
                                                (keyup)="searchParent($event)"
                                                (keydown)="$event.stopPropagation()"
                                                formControlName="parentType"
                                                autofocus
                                                placeholder="Search"
                                                autocomplete="off"
                                              />
                                            </div>
                    
                                            <mat-option
                                              *ngFor="let obj of companyListSearch"
                                              [value]="obj.legalName"
                                              (click)="setPatient(obj)"
                                            >
                                              {{ obj.legalName }}
                                            </mat-option>
                                          </mat-select>
                                            <!-- <input matInput (keyup)="searchParent($event)" [matAutocomplete]="auto"
                                                formControlName="parentType" />
                                            <mat-autocomplete #auto="matAutocomplete">
                                                <mat-option *ngFor="let obj of companyListSearch"
                                                    [value]="obj.legalName" (click)="setPatient(obj)">
                                                    {{ obj.legalName }}
                                                </mat-option>
                                            </mat-autocomplete> -->
                                        </mat-form-field>
                                      
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label class="secondary-text">Trading Name</mat-label>
                                            <input formControlName="tradingName" placeholder="Trading Name" matInput
                                                type="text" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Lead Source</mat-label>
                                            <mat-select formControlName="leadSourceId">
                                                <mat-option *ngFor="let obj of listLookups?.company?.leadSource"
                                                    [value]="obj.id">
                                                    {{ obj.leadTypeDescription }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Lead Status</mat-label>
                                            <mat-select formControlName="leadStatusId">
                                                <mat-option *ngFor="let obj of listLookups?.company?.leadStatus"
                                                    [value]="obj.id">
                                                    {{ obj.leadStatusDescription }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label class="secondary-text">Sales Rep</mat-label>
                                            <mat-select formControlName="salesRepId">
                                                <mat-option *ngFor="
                            let lookups of listLookups?.opportunity?.salesRep
                          " [value]="lookups.id">
                                                    {{ lookups.salesRepDescription }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Subsidiary</mat-label>
                                            <mat-select formControlName="subsidiaryId">
                                                <mat-option *ngFor="
                            let obj of listLookups?.opportunity?.subsidiaries
                          " [value]="obj.id">
                                                    {{ obj.subsidiaryDescription }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label class="secondary-text">Comments</mat-label>
                                            <textarea matInput rows="5" formControlName="comments"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-tab>
                            <!--  -->
                            <!-- 2 Communication -->
                            <mat-tab label="Communication">
                                <div class="row" style="margin: 10px;">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label class="secondary-text">E-mail</mat-label>
                                            <input formControlName="email" placeholder="E-mail" matInput type="text" />
                                            <mat-error *ngIf="
                          addCompanyForm.controls['email'].hasError('email') ||
                          addCompanyForm.controls['email'].touched
                        ">
                                                Please Enter valid E-mail</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label class="secondary-text">Phone</mat-label>
                                            <input formControlName="phone" placeholder="Phone" matInput type="number"
                                                (keyup)="search($event, 'phone')" />
                                            <mat-hint align="end"> {{ phoneLength }} / 10</mat-hint>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-tab>
                            <!-- 3 Classifiation -->
                            <mat-tab label="Classifiation">
                                <div class="row" style="margin: 10px;">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Market / Indusrty </mat-label>
                                            <mat-select formControlName="marketId" required>
                                                <mat-option *ngFor="
                            let obj of listLookups?.company?.marketIndustry
                          " [value]="obj.id">
                                                    {{ obj.marketIndustryDescription }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="
                          addCompanyForm.controls['marketId'].hasError(
                            'required'
                          ) &&
                          (addCompanyForm.controls['marketId'].touched ||
                            submitted)
                        ">
                                                Required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Tier </mat-label>
                                            <mat-select formControlName="tierId" required>
                                                <mat-option *ngFor="let obj of listLookups?.company?.tiers"
                                                    [value]="obj.id">
                                                    {{ obj.tierDescription }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="
                          addCompanyForm.controls['tierId'].hasError(
                            'required'
                          ) &&
                          (addCompanyForm.controls['tierId'].touched ||
                            submitted)
                        ">
                                                Required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-tab>
                            <!-- 4 Product Information -->
                            <mat-tab label="Product Information">
                                <div class="row" style="margin: 10px;">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Product Category </mat-label>
                                            <mat-select formControlName="productCategoryId" required>
                                                <mat-option *ngFor="
                            let obj of listLookups?.company?.productCategory
                          " [value]="obj.id">
                                                    {{ obj.productCategoryDescription }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="
                          addCompanyForm.controls['productCategoryId'].hasError(
                            'required'
                          ) &&
                          (addCompanyForm.controls['productCategoryId']
                            .touched ||
                            submitted)
                        ">
                                                Required.</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Software</mat-label>
                                            <mat-select formControlName="softwareId">
                                                <mat-option *ngFor="let obj of listLookups?.company?.softwares"
                                                    [value]="obj.id">
                                                    {{ obj.softwareDescription }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-tab>
                            <!-- 5 Billing Address -->
                            <mat-tab label="Billing Address">
                                <div class="row" style="margin: 10px;">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Billing Address Line 1</mat-label>
                                            <input formControlName="billingAddressLine1"
                                                placeholder="Billing Address Line 1" matInput type="text" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Billing Address Line 2</mat-label>
                                            <input formControlName="billingAddressLine2"
                                                placeholder="Billing Address Line 2" matInput type="text" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Billing City or Suburb</mat-label>
                                            <input formControlName="billingCity" placeholder="Billing City or Suburb"
                                                matInput type="text" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Billing Post Code</mat-label>
                                            <input formControlName="billingPostCode" placeholder="Billing Post Code"
                                                matInput type="number" (keyup)="search($event, 'billing')" />
                                            <mat-hint align="end">
                                                {{ billingPostCodeLength }} / 6
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>State</mat-label>
                                            <mat-select formControlName="billingState">
                                                <mat-option *ngFor="let obj of listLookups?.address?.states"
                                                    [value]="obj.id">
                                                    {{ obj.stateDescription }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Country</mat-label>
                                            <mat-select formControlName="billingCountry">
                                                <mat-option *ngFor="let obj of listLookups?.address?.countries"
                                                    [value]="obj.id">
                                                    {{ obj.countryDescription }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-tab>
                            <!-- 6 Shipping Address -->
                            <mat-tab label="Shipping Address">
                                <div class="row" style="margin: 10px;">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Shipping Address Line 1</mat-label>
                                            <input formControlName="shippingAddressLine1"
                                                placeholder="Shipping Address Line 1" matInput type="text" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Shipping Address Line 2</mat-label>
                                            <input formControlName="shippingAddressLine2"
                                                placeholder="Shipping Address Line 2" matInput type="text" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Shipping City or Suburb</mat-label>
                                            <input formControlName="shippingCity" placeholder="Shipping City or Suburb"
                                                matInput type="text" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Shipping Post Code</mat-label>
                                            <input formControlName="shippingPostCode" placeholder="Shipping Post Code"
                                                matInput type="number" (keyup)="search($event, 'shipping')" />
                                            <mat-hint align="end">
                                                {{ shippingPostCodeLength }} / 6
                                            </mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>State</mat-label>
                                            <mat-select formControlName="shippingState">
                                                <mat-option *ngFor="let obj of listLookups?.address?.states"
                                                    [value]="obj.id">
                                                    {{ obj.stateDescription }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Country</mat-label>
                                            <mat-select formControlName="shippingCountry">
                                                <mat-option *ngFor="let obj of listLookups?.address?.countries"
                                                    [value]="obj.id">
                                                    {{ obj.countryDescription }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </mat-tab>
                            <!--  -->
                        </mat-tab-group>
                    </mat-card>
                </div>
            </div>
        </div>
    </form>
</div>