<!-- <app-header></app-header> -->
<div class="dashbord-contnet">
    <div class="content-header">
        <div fxFlex="50" fxFlex.md="100" fxFlex.sm="100">
            <h1 class="m-0">Sales Dashboard</h1>
            <!-- <p class="m-0">{{ TodayDate }}</p> -->
        </div>
    </div>
    <div class="main-bg">
        <div class="row">
            <div class="col-lg-3 col-md-6" *ngFor="let item of cardList">
                <mat-card class="c-mat-card" (click)="gotoPage(item)">
                    <div class="img-wrapper">
                        <img [src]="item.Image" />
                    </div>
                    <div class="content-wrapper">
                        <div class="content-wrapper-inner">
                            <div class="content">
                                <h3>{{ item.value }}</h3>
                                <p class="author">{{ item.name }}</p>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
        <!-- <div class="row mt-4">
      <div class="col-lg-4 col-md-12">
        <mat-card class="chart-card">
          <div id="chart">
            <apx-chart
              [series]="chartOptions?.series"
              [chart]="chartOptions?.chart"
              [xaxis]="chartOptions?.xaxis"
              [dataLabels]="chartOptions?.dataLabels"
              [grid]="chartOptions?.grid"
              [stroke]="chartOptions?.stroke"
              [title]="chartOptions?.title"
            >
            </apx-chart>
          </div>
        </mat-card>
      </div>
      <div class="col-lg-4 col-md-12">
        <mat-card class="chart-card">
          <div id="chart">
            <apx-chart
              [series]="chartOptions1?.series"
              [chart]="chartOptions1?.chart"
              [xaxis]="chartOptions1?.xaxis"
              [dataLabels]="chartOptions1?.dataLabels"
              [grid]="chartOptions1?.grid"
              [stroke]="chartOptions1?.stroke"
              [title]="chartOptions1?.title"
            >
            </apx-chart>
          </div>
        </mat-card>
      </div>
      <div class="col-lg-4 col-md-12">
        <mat-card class="chart-card">
          <div id="chart">
            <apx-chart
              [series]="chartOptions2?.series"
              [chart]="chartOptions2?.chart"
              [xaxis]="chartOptions2?.xaxis"
              [dataLabels]="chartOptions2?.dataLabels"
              [grid]="chartOptions2?.grid"
              [stroke]="chartOptions2?.stroke"
              [title]="chartOptions2?.title"
            >
            </apx-chart>
          </div>
        </mat-card>
      </div>
    </div> -->
        <mat-card class="mt-4">
            <div class="row mb-4">
                <div class="col-md-4">
                    <div class="d-flex align-items-center pt-2 pb-4 flex-wrap" *ngIf="view != 'Agenda'">
                        <h3 class="m-0 d-flex align-items-center calendar-text
                            me-2">
                            <span class="material-icons me-2
                                secondary-text">
                date_range </span>{{ viewDate | calendarDate: view + "ViewTitle":"en" }}
                        </h3>
                        <div class="calendar-btn-group">
                            <button mat-stroked-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                <div class="material-icons">
                  chevron_left
                </div>
              </button>
                            <button mat-stroked-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                <div class="material-icons">
                  chevron_right
                </div>
              </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 text-right">
                    <div class="d-flex align-items-center justify-content-end
                        flex-wrap">
                        <div class="me-2">
                            {{ events && events.length ? events.length : '0' }} Records Found
                        </div>
                        <div class="mat-select-wrapper mb-2">
                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="selectedView">
                                    <mat-option value="Agenda">
                                        <div (click)="viewAgenda()" [class.active]="view == 'Agenda'">
                                            Agenda
                                        </div>
                                    </mat-option>
                                    <mat-option value="Month">
                                        <div (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                                            Month
                                        </div>
                                    </mat-option>
                                    <mat-option value="Week">
                                        <div (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                                            Week
                                        </div>
                                    </mat-option>
                                    <mat-option value="Day">
                                        <div (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                                            Day
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <mat-form-field appearance="outline" class="w-100">
                                <mat-icon matPrefix>search</mat-icon>
                                <mat-label>Search</mat-label>
                                <input matInput [(ngModel)]="searchInput" [ngModelOptions]="{standalone: true}">
                                <mat-icon matSuffix (click)="clearSearch()">close</mat-icon>
                            </mat-form-field> -->
                        </div>
                        <div class="d-flex ms-2 mb-2">
                            <button mat-stroked-button class="me-2 c-btn" (click)="viewFilterField()">
                <span class="material-icons me-2"> filter_list </span>Filter
              </button>
                            <button (click)="gotoActivities()" mat-raised-button class="c-btn theme-bg" mwlCalendarPreviousView [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                <span class="material-icons add-btn">
                  event
                </span>
                &nbsp;New Event
              </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center" *ngIf="showFilter">

                <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Event Type</mat-label>
                        <mat-select [(ngModel)]="eventType" [ngModelOptions]="{ standalone: true }">
                            <mat-option *ngFor="let obj of eventList" [value]="obj.value" (click)="filterTypeChange(obj)">
                                {{ obj.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Company Name</mat-label>
                        <mat-select [(ngModel)]="relatedCompany" [ngModelOptions]="{ standalone: true }">
                            <mat-option *ngFor="let obj of relatedCompanyList" [value]="obj.legalName" (click)="filterTypeChange(obj)">
                                {{ obj.legalName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- <div class="col-md-3">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Transection Type</mat-label>
                  <mat-select [(ngModel)]="relatedTransaction" [ngModelOptions]="{ standalone: true }">
                      <mat-option *ngFor="let obj of cardList" [value]="obj.stageDescription" (click)="filterTypeChange(obj)">
                          {{ obj.stageDescription }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
          </div> -->
                <div class="col-md-4">

                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Conatact Name</mat-label>
                        <mat-select [(ngModel)]="relatedContact" [ngModelOptions]="{ standalone: true }" [disabled]='relatedContactList.length == 0'>
                            <mat-option *ngFor="let obj of relatedContactList" [value]="obj.name" (click)="filterTypeChange(obj)">
                                {{ obj.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-5"></div>
                <div class="col-md-2">
                    <button mat-raised-button color="primary" class="c-btn green-btn w-100" (click)="clearSearch()">
            Clear All
          </button>
                </div>
                <div class="col-md-5"></div>
            </div>
            <div class="row text-center">
                <div class="col-md-12"></div>
            </div>
            <div [ngSwitch]="view">
                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-month-view>
                <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-week-view>
                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                </mwl-calendar-day-view>
                <!-- <div *ngSwitchCase="'Agenda'" class="agenda-div"> -->
                <div *ngSwitchCase="'Agenda'">
                    <div class="align-items-center row">
                        <div class="col-lg-5">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-icon matPrefix>search</mat-icon>
                                <mat-label>Search</mat-label>
                                <input matInput (keyup)="inputEvent($event)" [(ngModel)]="searchText" />
                                <mat-icon matSuffix (click)="clear()" *ngIf="searchText">close</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="no-content" *ngIf="events && events.length == 0">
                        <h2 class="primary-text c-h2">No Related Record Found</h2>
                        <p class="secondry-light-text">
                            Please add data to see the Records
                        </p>
                    </div>
                    <div *ngIf="events && events.length > 0">
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 companies-table w-100" matSort style="cursor:
                            pointer">
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Event Type
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.type || "-" }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="title">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Event Title
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.title || "-" }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="relatedCustomer">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Related Company
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.relatedCustomer || "-" }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="relatedTransaction">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Related Transaction
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.relatedTransaction || "-" }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="relatedContact">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Related Contact
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.relatedContact || "-" }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="createDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Start Date
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.createDate | date: "dd MMM YYYY" || "-" }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="startTime">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Start Time
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.startTime | date: "HH:mm" || "-" }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="endTime">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    End Time
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{ element.endTime | date: "HH:mm" || "-" }}
                                </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="phoneNumber">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phone
                                                </th>
                                                <td mat-cell *matCellDef="let element">{{ element.statusId }}</td>
                                            </ng-container> -->
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->
                            <tr mat-row *matRowDef="let row; columns: displayedColumns; let element"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>