import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '../layout/layout.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.Authlogin();
  }

  Authlogin() {
    const clientId = "salesapp.portal";
    const redirectUrl = "http://localhost:3000/%23/signin-oidc";

    let url = 'https://salesapp-auth.nexware.app/connect/authorize?response_type=token&client_id=$clientId&redirect_uri=$redirectUrl&scope=gateway.salesapp.portal';

    url = url.replace("$clientId", clientId).replace("$redirectUrl", redirectUrl);
    window.open(url,'_self');
  }

}
