<div class="content-header">
    <div class="col-lg-6 col-md-6 col-sm-12">
        <h1 *ngIf="type=='Phone'">Edit/New Call</h1>
        <h1 *ngIf="type=='Event'">Edit/New Event</h1>
        <h1 *ngIf="type=='Task'">Edit/New Task</h1>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-right">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <div>
            </div>
            <div mat-dialog-actions>
                <button mat-raised-button class="green-btn c-btn" [disabled]="subActivityForm.invalid"
                    (click)="SaveData()"><span class="material-icons me-2">
                        save
                    </span>Save</button>
                <button mat-raised-button class="green-btn c-btn" style="margin-left: 1em;"
                    (click)="ViewActivity()"><span class="material-icons me-2">
                        cancel
                    </span>Cancel</button>
            </div>
        </div>

    </div>
</div>
<div class="activity-content">
    <mat-card>
        <form [formGroup]="subActivityForm" fxLayout="column" novalidate="novalidate">
            <div class="row">
                <div class="col-lg-12">
                    <div class="w-100">
                        <div>
                            <div>
                                <h1 class="primary-text card-heading m-0"><b>Primary Information</b></h1>
                            </div>
                            <div class="row border-bottom">
                                <!-- <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Related Company </mat-label>
                                        <mat-select formControlName="company"
                                            (selectionChange)="getCompanyBaseData();getAllContactList();" required>
                                            <mat-option *ngFor="let company of listCompanies" [value]="company.id">
                                                {{company.legalName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="subActivityForm.controls.company.invalid || subActivityForm.controls.company.touched">
                                            Please Select Company</mat-error>
                                    </mat-form-field>
                                </div> -->
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Company</mat-label>
                                        <div class="material-icons"
                                            style="position: absolute;right: -7px;color: rgba(0,0,0,.54);">
                                            arrow_drop_down
                                        </div>
                                        <input matInput (keyup)="searchCompany($event)" [matAutocomplete]="auto"
                                            formControlName="company" />
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option *ngFor="let company of companyListSearch"
                                                [value]="company.legalName"
                                                (click)="setCompany(company);getCompanyBaseData();getAllContactList();">
                                                {{company.legalName}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error
                                            *ngIf="subActivityForm.controls.company.invalid || subActivityForm.controls.company.touched">
                                            Please Select Company</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Related Transaction </mat-label>
                                        <mat-select placeholder="Select Transaction" formControlName="transaction"
                                            (selectionChange)="getTransactionType($event)">
                                            <mat-option *ngFor="let transaction of listTransactions"
                                                [value]="transaction.type">
                                                {{transaction.typeval}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Related Contact </mat-label>
                                        <mat-select placeholder="Select Contact" formControlName="contact">
                                            <mat-option *ngFor="let contact of listContacts" [value]="contact.id">
                                                {{contact.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100" *ngIf="type=='Phone'">
                                        <mat-label>Phone number</mat-label>
                                        <input matInput name="phoneNumber" id="phoneNumber"
                                            formControlName="phoneNumber" maxlength="15" type="number" size="30">
                                        <mat-error class="pl-1"
                                            *ngIf="subActivityForm.get('phoneNumber')?.hasError('pattern')">
                                            Please enter valid Phone Number</mat-error>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="listContacts.length>0" class="mb-3">
                                    <mat-label style="color: lightgray;">Participents : </mat-label>
                                    <div>
                                        <mat-chip-list class="flex-full-width">
                                            <ng-container *ngFor="let contact  of listContacts">
                                                <mat-basic-chip (click)="SelectParticipant(contact)"
                                                    [ngClass]="{ 'activetParticipents': contact.isSelect,'noActivetParticipents' :!contact.isSelect}">
                                                    {{contact.name}}</mat-basic-chip>
                                            </ng-container>
                                        </mat-chip-list>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <h1 class="primary-text card-heading m-0 mb-3"><b>Details</b></h1>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Title</mat-label>
                                        <input matInput name="title" id="title" formControlName="title" maxlength="1000"
                                            required size="30">
                                        <mat-error
                                            *ngIf="subActivityForm.controls.title.invalid || subActivityForm.controls.title.touched">
                                            Title should not be Empty</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Date</mat-label>
                                        <input matInput autocomplete="off" formControlName="date"
                                            [matDatepicker]="picker1" (click)="picker1.open()" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1>
                                            <mat-datepicker-actions>
                                                <button mat-button matDatepickerCancel>Cancel</button>
                                                <button mat-raised-button color="primary"
                                                    matDatepickerApply>Apply</button>
                                            </mat-datepicker-actions>
                                        </mat-datepicker>
                                        <mat-error
                                            *ngIf="subActivityForm.controls.date.invalid || subActivityForm.controls.date.touched">
                                            Date should not be Empty</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Start Time * </mat-label>
                                        <input matInput formControlName="startTime" matTimepicker>
                                        <mat-error
                                            *ngIf="subActivityForm.controls.startTime.invalid || subActivityForm.controls.startTime.touched">
                                            Start Time should not be Empty</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>End Time * </mat-label>
                                        <input formControlName="endTime" matTimepicker>
                                        <mat-error
                                            *ngIf="subActivityForm.controls.endTime.invalid || subActivityForm.controls.endTime.touched">
                                            End Time should not be Empty</mat-error>
                                    </mat-form-field>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label class="secondary-text">Sales Rep</mat-label>
                                        <mat-select formControlName="salesRepId">
                                            <mat-option *ngFor="let lookups of listLookups?.opportunity?.salesRep"
                                                [value]="lookups.id">
                                                {{ lookups.salesRepDescription }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">

                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Description</mat-label>
                                        <textarea matInput name="description" id="description"
                                            formControlName="description" maxlength="1000">
                                    </textarea>
                                        <mat-error
                                            *ngIf="subActivityForm.controls.description.invalid || subActivityForm.controls.description.touched">
                                            Description should not be Empty</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <div mat-dialog-actions>
                                <a mat-raised-button class="c-btn" [ngClass]="{ 'img-wrapper': isSheduled}"
                                    (click)="setActivityStatus('2')">Scheduled</a>
                                <a mat-raised-button class=" c-btn" [ngClass]="{ 'img-wrapper': isCompleted}"
                                    style="margin-left: 1em;" (click)="setActivityStatus('1')">Completed</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>
</div>