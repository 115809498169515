<div class="content-header ptb-10">
    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="dataSource.length > 0">
        <div>
            <h1 class="m-0">
                {{ dataSource[0].legalName }}
                <span class="badge badge-warning c-lead" *ngIf="dataSource[0].stage == 'Lead'">
                    {{ dataSource[0].stage }}
                </span>
                <span class="badge badge-warning c-prospect" *ngIf="dataSource[0].stage == 'Prospect'">
                    {{ dataSource[0].stage }}
                </span>
            </h1>
        </div>
        <div class="c-email-wrap">
            <span class="m-0">
                {{ dataSource[0].email || "Email" }}
                <mat-icon class="custom-icon">fiber_manual_record</mat-icon>
                {{ dataSource[0].phone || "Phone" }}
            </span>
        </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-right">
        <button mat-raised-button color="primary" class="c-btn green-btn" (click)="addNewDetail('editCurrent')">
            <span class="material-icons me-2"> edit </span>Edit
        </button>
        <button mat-raised-button class="theme-bg c-btn" style="margin-left: 10px;" (click)="addNewDetail('addNew')">
            <mat-icon matPrefix class="add-btn">add</mat-icon> &nbsp;Add Company
        </button>
    </div>
</div>
<div class="opportunities-content">
    <div class="row">
        <div class="col-lg-8">
            <mat-card class="w-100 p-0">
                <mat-tab-group mat-align-tabs="start" class="content">
                    <!-- #enddocregion align-start -->
                    <mat-tab label="Primary Information">
                        <table class="mat-elevation-z8 companies-table
                                        w-100">
                            <tr style="font-size: small;color: grey;">
                                <td class="td">
                                    <mat-label>Stage</mat-label>
                                </td>
                                <td>
                                    <mat-label>Company Name</mat-label>
                                </td>
                                <td>
                                    <mat-label>Parent Name</mat-label>
                                </td>
                            </tr>
                            <tr>
                                <td class="td">
                                    <mat-label>{{companymetadata.stage}}</mat-label>
                                </td>
                                <td>
                                    <mat-label>{{companymetadata.legalName}}</mat-label>
                                </td>
                                <td>
                                    <mat-label>{{companymetadata.parentName}}</mat-label>
                                </td>
                            </tr>
                            <br />
                            <tr style="font-size: small;color: grey;">
                                <td class="td">
                                    <mat-label>Trading Name</mat-label>
                                </td>
                                <td>
                                    <mat-label>Lead Source</mat-label>
                                </td>
                                <td>
                                    <mat-label>Lead Status</mat-label>
                                </td>

                            </tr>
                            <tr>
                                <td class="td">
                                    <mat-label>{{companymetadata.tradingName}}</mat-label>
                                </td>
                                <td>
                                    <mat-label>{{companymetadata.leadSource}}</mat-label>
                                </td>
                                <td>
                                    <mat-label>{{companymetadata.leadStatus}}</mat-label>
                                </td>
                            </tr>
                            <br />
                            <tr style="font-size: small;color: grey;">
                                <td class="td">
                                    <mat-label>Sales Rep</mat-label>
                                </td>
                                <td>
                                    <mat-label>Subsidary</mat-label>
                                </td>
                                <td>
                                    <mat-label>Comment</mat-label>
                                </td>

                            </tr>
                            <tr>
                                <td class="td">
                                    <mat-label>{{companymetadata.salesRep}}</mat-label>
                                </td>
                                <td>
                                    <mat-label>{{companymetadata.subsidiary}}</mat-label>
                                </td>
                                <td>
                                    <mat-label>{{companymetadata.comments}}</mat-label>
                                </td>
                            </tr>
                        </table>
                    </mat-tab>
                    <mat-tab label="Communication">
                        <table class="mat-elevation-z8 companies-table
                                        w-100">
                            <tr style="font-size: small;color: grey;">
                                <td class="td">
                                    <mat-label>Phone</mat-label>
                                </td>
                                <td>
                                    <mat-label>Email</mat-label>
                                </td>
                                <!-- <td>
                                                <mat-label>Subsidary</mat-label>
                                            </td> -->
                            </tr>
                            <tr>
                                <td class="td">
                                    <mat-label>{{companymetadata.phone}}</mat-label>
                                </td>
                                <td>
                                    <mat-label>{{companymetadata.email}}</mat-label>
                                </td>
                                <!-- <td>
                                                <mat-label>{{companymetadata.subsidiary}}</mat-label>
                                            </td> -->
                            </tr>

                        </table>
                    </mat-tab>
                    <mat-tab label="Classification">
                        <table class="mat-elevation-z8 companies-table
                                        w-100">
                            <tr style="font-size: small;color: grey;">
                                <td class="td">
                                    <mat-label>Market/Industry</mat-label>
                                </td>
                                <td>
                                    <mat-label>Tiers</mat-label>
                                </td>
                                <td>
                                    <mat-label>Product Catagory</mat-label>
                                </td>
                                <td>
                                    <mat-label>Software</mat-label>
                                </td>
                            </tr>
                            <tr>
                                <td class="td">
                                    <mat-label>{{companymetadata.marketIndustry}}</mat-label>
                                </td>
                                <td>
                                    <mat-label>{{companymetadata.tier}}</mat-label>
                                </td>
                                <td>
                                    <mat-label>{{companymetadata.productCategory}}</mat-label>
                                </td>
                                <td>
                                    <mat-label>{{companymetadata.software}}</mat-label>
                                </td>
                            </tr>

                        </table>
                    </mat-tab>

                    <mat-tab label="Address">
                        <div *ngIf="dataSource.length> 0">
                            <div style="display: flex;align-items:
                                            center;justify-content:center">
                                <h5 style="font-weight: bold">
                                    Billing Address
                                </h5>
                            </div>

                            <table class="companies-table
                                        w-100">
                                <tr style="font-size: small;color: grey;">
                                    <td class="td">
                                        <mat-label>Address Line 1</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Address Line 2</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>City or Suburb</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td">
                                        <mat-label>{{companymetadata.billingAddress?.addressLine1}}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{companymetadata.billingAddress?.addressLine2}}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{companymetadata.billingAddress?.city}}</mat-label>
                                    </td>
                                </tr>
                                <br />
                                <tr style="font-size: small;color: grey;">
                                    <td class="td">
                                        <mat-label>Post Code</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>State</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Country</mat-label>
                                    </td>

                                </tr>
                                <tr>
                                    <td class="td">
                                        <mat-label>{{companymetadata.billingAddress?.postCode}}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{companymetadata.billingAddress?.stateName}}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{companymetadata.billingAddress?.countryname}}</mat-label>
                                    </td>
                                </tr>
                            </table>

                            <div style="display: flex;align-items:
                                            center;justify-content:center">
                                <h5 style="font-weight: bold">
                                    Shipping Address
                                </h5>
                            </div>
                            <!--  -->
                            <table class="companies-table
                                        w-100">
                                <tr style="font-size: small;color: grey;">
                                    <td class="td">
                                        <mat-label>Address Line 1</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Address Line 2</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>City or Suburb</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td">
                                        <mat-label>{{companymetadata.shippingAddress?.addressLine1}}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{companymetadata.shippingAddress?.addressLine2}}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{companymetadata.shippingAddress?.city}}</mat-label>
                                    </td>
                                </tr>
                                <br />
                                <tr style="font-size: small;color: grey;">
                                    <td class="td">
                                        <mat-label>Post Code</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>State</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Country</mat-label>
                                    </td>

                                </tr>
                                <tr>
                                    <td class="td">
                                        <mat-label>{{companymetadata.shippingAddress?.postCode}}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{companymetadata.shippingAddress?.stateName}}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{companymetadata.shippingAddress?.countryname}}</mat-label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </mat-card>
            <mat-card class="mt-4">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="primary-text card-heading m-0">Related Contacts</h2>

                    <div class="d-flex align-items-center justify-content-end
                                flex-wrap">
                        <div *ngIf="companydata && companydata.length" class="me-2">
                            {{companydata && companydata.length ? companydata.length : '0'}} Records Found
                            <!-- Records Found -->
                        </div>
                        <button mat-raised-button class="theme-bg c-btn" (click)="navigateTo('Contacts')">
                            + Add Contact
                        </button>
                    </div>

                </div>
                <div class="no-content1" *ngIf="companydata.length == 0">
                    <h4 class="primary-text c-h2">No Contacts Found!</h4>
                    <!-- <p class="secondry-light-text">Please add data to see the Contacts</p> -->
                </div>
                <div style="margin-top: 10px;" *ngIf="companydata.length> 0">
                    <table mat-table [dataSource]="companydata" class="mat-elevation-z8 companies-table ww-100" style="width:100%">
                        <ng-container matColumnDef="firstName">
                            <th mat-header-cell *matHeaderCellDef>First Name</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.firstName || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="lastName">
                            <th mat-header-cell *matHeaderCellDef>Last Name</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.lastName || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="jobTitle">
                            <th mat-header-cell *matHeaderCellDef>Job Title</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.jobTitle || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef>Role</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.role || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef>E-mail</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.email || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="phone">
                            <th mat-header-cell *matHeaderCellDef>Phone</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.phone || "-" }}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns:
                                    displayedColumns; let element" (click)="gotoContact(element)"></tr>
                    </table>
                </div>
            </mat-card>
        </div>
        <div class="col-lg-4">
            <mat-card class="related_oppo-card">
                <div class="d-flex justify-content-between align-items-center ">
                    <h1 class="primary-text card-heading m-0">
                        Related Opportunities
                    </h1>
                    <div class="d-flex align-items-center justify-content-end flex-wrap">
                        <div *ngIf="oppertunityData && oppertunityData.length" class="me-2">
                            {{oppertunityData.length}} Records Found
                        </div>
                        <button mat-raised-button class="theme-bg c-btn" (click)="navigateTo('Opportunities')">
                            + Add
                        </button>
                    </div>

                </div>
                <div class="no-content" *ngIf="oppertunityData.length == 0">
                    <h4 class="primary-text c-h2">No Related Opportunities found!</h4>
                    <!-- <p class="secondry-light-text">
                        Please add data to see the Opportunities
                    </p> -->
                </div>
                <div style="margin-top: 10px;" *ngIf="oppertunityData.length > 0">
                    <table mat-table [dataSource]="oppertunityData" class="mat-elevation-z8 companies-table ww-100" style="width:100%">
                        <ng-container matColumnDef="firstName">
                            <th mat-header-cell *matHeaderCellDef>First Name</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.firstName || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="lastName">
                            <th mat-header-cell *matHeaderCellDef>Last Name</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.lastName || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="jobTitle">
                            <th mat-header-cell *matHeaderCellDef>Job Title</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.jobTitle || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="role">
                            <th mat-header-cell *matHeaderCellDef>Role</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.role || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef>E-mail</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.email || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="phone">
                            <th mat-header-cell *matHeaderCellDef>Phone</th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.phone || "-" }}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
                <!-- <div class="border-bottom">
                    <div class="summery-content">
                        <p class="secondry-light-text m-0">Subscription</p>
                        <p class="primary-text m-0">1,000.00 AUD</p>
                    </div>
                    <div class="summery-content">
                        <p class="secondry-light-text m-0">Subscription</p>
                        <p class="primary-text m-0">1,000.00 AUD</p>
                    </div>
                    <div class="summery-content">
                        <p class="secondry-light-text m-0">Subscription</p>
                        <p class="primary-text m-0">1,000.00 AUD</p>
                    </div>
                    <div class="summery-content">
                        <p class="secondry-light-text m-0">Subscription</p>
                        <p class="primary-text m-0">1,000.00 AUD</p>
                    </div>
                </div>
                <div class="pt-2 pb-2 ">
                    <div class="summery-content">
                        <p class="primary-text m-0"><b>GRAND TOTAL</b></p>
                        <p class="green-text m-0"><b>2,280.00 AUD</b></p>
                    </div>
                </div> -->
            </mat-card>
            <mat-card class="related_oppo-card mt-4">
                <div class="d-flex justify-content-between align-items-center ">
                    <h1 class="primary-text card-heading m-0">Related Quotes</h1>
                    <div class="d-flex align-items-center justify-content-end flex-wrap">
                        <div *ngIf="quoteData && quoteData.length" class="me-2">
                            {{quoteData.length}} Records Found
                            <!-- Records Found -->
                        </div>
                        <button mat-raised-button class="theme-bg c-btn" (click)="navigateTo('Quotes')">
                            + Add
                        </button>
                    </div>

                </div>
                <div class="no-content" *ngIf="quoteData.length == 0">
                    <h4 class="primary-text c-h2">No Related Quotes found!</h4>
                    <!-- <p class="secondry-light-text">Please add data to see the Quotes</p> -->
                </div>
                <div style="margin-top: 10px;" *ngIf="quoteData.length > 0">
                    <div *ngIf="quoteData.length != 0">
                        <div class="d-flex justify-content-between align-items-center border-bottom pb-2 pt-4" style="cursor: pointer;" *ngFor="let item of quoteData" (click)="gotoQuote(item)">
                            <div>
                                <h2 class="primary-text c-h2 m-0 mb-2">{{item.title}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <mat-card class="mt-4">
        <div class="row mb-4">
            <div class="col-md-12 text-right">
                <div class="d-flex align-items-center justify-content-space-between flex-wrap">
                    <div class="mat-select-wrapper d-flex">
                        <button mat-stroked-button class="me-2 c-event-btn" [ngClass]="{'active-btn': view == 'Agenda','in-active-btn': view != 'Agenda'}">
                            <div (click)="viewAgenda()" [class.active]="view == 'Agenda'">
                                Agenda
                            </div>
                        </button>
                        <button mat-stroked-button class="me-2 c-event-btn" [ngClass]="{'active-btn': view === CalendarView.Day,'in-active-btn': view != CalendarView.Day}">
                            <div (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                                Day
                            </div>
                        </button>
                        <button mat-stroked-button class="me-2 c-event-btn" [ngClass]="{'active-btn': view === CalendarView.Week,'in-active-btn': view != CalendarView.Week}">
                            <div (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                                Week
                            </div>
                        </button>
                        <button mat-stroked-button class="me-2 c-event-btn" [ngClass]="{'active-btn': view === CalendarView.Month,'in-active-btn': view != CalendarView.Month}">
                            <div (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                                Month
                            </div>
                        </button>
                        <mat-form-field appearance="outline" class="w-100 me-2">
                            <mat-icon matPrefix>search</mat-icon>
                            <mat-label>Search</mat-label>
                            <input matInput (keyup)="inputEvent($event)" [(ngModel)]="searchText" />
                            <mat-icon matSuffix (click)="clear()">close</mat-icon>
                        </mat-form-field>
                    </div>
                    <div>
                        {{events && events.length ? events.length : '0' }} Records Found
                        <!-- <button mat-stroked-button class="me-2 c-btn btn-success" (click)="viewFilterField()">
                            <span class="material-icons me-2"> filter_list </span>Filter
                        </button> -->
                        <button mat-raised-button color="primary" class="c-btn green-btn me-2" (click)="viewFilterField()">
                            <span class="material-icons me-2"> filter_list </span>Filter
                        </button>
                        <!-- <button (click)="gotoActivities()" mat-raised-button class="c-btn theme-bg" mwlCalendarPreviousView [(viewDate)]="viewDate">
                            <span class="material-icons add-btn">
                                event
                            </span>
                            &nbsp;New Event
                        </button> -->
                        <app-speed-dial-fab class="speed-dial-container" [ngClass]="speedDialFabPosition" [reverse-column-direction]="speedDialFabPosition===SpeedDialFabPosition.Bottom" (fabClick)="onSpeedDialFabClicked($event)" [buttons]="speedDialFabButtons">
                        </app-speed-dial-fab>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center" *ngIf="showFilter">
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label> Type</mat-label>
                    <mat-select [(ngModel)]="eventType" [ngModelOptions]="{ standalone: true }">
                        <mat-option *ngFor="let obj of eventList" [value]="obj.value" (click)="filterTypeChange(obj)">
                            {{ obj.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select [(ngModel)]="statusType" [ngModelOptions]="{standalone: true}" (change)="statusTypeChange($event)">
                        <mat-option *ngFor="let obj of statusList" [value]=obj.id>
                            {{obj.statusDescription}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-1">
                <button mat-raised-button color="primary" class="c-btn green-btn" (click)="clearSearch()">
                    Clear All
                </button>
            </div>
        </div>
        <div class="row text-center">
            <div class="col-md-12">
                <div class="col-md-4">
                    <div class="d-flex align-items-center pt-2 pb-4 flex-wrap" *ngIf="view != 'Agenda'">
                        <h3 class="m-0 d-flex align-items-center ">
                            <span class="material-icons me-2 secondary-text"> date_range
                            </span>{{ viewDate | calendarDate: view + "ViewTitle":"en" }}
                        </h3>
                        <div class="calendar-btn-group ms-4">
                            <button mat-stroked-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                <div class="material-icons">
                                    chevron_left
                                </div>
                            </button>
                            <button mat-stroked-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                <div class="material-icons">
                                    chevron_right
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
            <!-- <div *ngSwitchCase="'Agenda'" class="agenda-div"> -->
            <div *ngSwitchCase="'Agenda'">
                <div class="no-content" *ngIf="events && events.length == 0">
                    <h4 class="primary-text c-h2">No Related Contacts Found!</h4>
                </div>
                <!-- <div *ngIf="agendaList.length> 0"> -->
                <div *ngIf="events && events.length > 0">
                    <table mat-table [dataSource]="agendaList" class="mat-elevation-z8 companies-table w-100" matSort style="cursor:
                                pointer">
                        <ng-container matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Event Type
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.type || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Event Title
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.title || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="relatedCustomer">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Related Company
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.relatedCustomer || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="relatedTransaction">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Related Transaction
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.relatedTransaction || "-" }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="relatedContact">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Related Contact
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.relatedContact || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="startDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Start Date
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.createDate | date: "dd MMM YYYY" || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="startTime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                Start Time
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.startTime | date: "HH:mm" || "-" }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="endTime">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                End Time
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.endTime | date: "HH:mm" || "-" }}
                            </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="phoneNumber">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        Phone
                                                    </th>
                                                    <td mat-cell *matCellDef="let element">{{ element.statusId }}</td>
                                                </ng-container> -->
                        <tr mat-header-row *matHeaderRowDef="agendaTableColumn"></tr>
                        <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->
                        <tr mat-row *matRowDef="let row; columns: agendaTableColumn; let element"></tr>
                    </table>
                </div>
            </div>
        </div>
    </mat-card>
</div>