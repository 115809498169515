<div class="content-header ptb-10">
    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="ContactId == 0">
        <h1 class="m-0">Add Contact</h1>
        <p class="m-0">Today 10th April 2021</p>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="ContactId != 0">
        <h1 class="m-0">
            {{ companyDetails.firstName }} {{ companyDetails.lastName }}
        </h1>
        <p class="m-0">{{ companyDetails.phone }} . {{ companyDetails.email }}</p>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-right" *ngIf="!IsEditClick">
        <button mat-raised-button class="green-btn   c-btn" (click)="EditClick()">
      <span class="material-icons">
        edit
      </span>
      Edit
    </button>
        <button mat-raised-button class="theme-bg c-btn" style="margin-left: 1em;" (click)="AddNewContact()">
      <span class="material-icons add-btn">
        add
      </span>
      Add Contacts
    </button>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-right" *ngIf="!IsEditClick"></div>
    <div class="col-lg-6 col-md-6 col-sm-12 text-right" *ngIf="IsEditClick">
        <button mat-raised-button class="green-btn c-btn" (click)="submitbtnClick()">
      <span class="material-icons me-2"> save </span>Save
    </button>
        <button mat-raised-button class="green-btn c-btn" style="margin-left: 1em;" (click)="ViewContact()">
      <span class="material-icons me-2"> cancel </span>Cancel
    </button>
    </div>
</div>

<div class="opportunities-content">
    <div class="row" *ngIf="!IsEditClick && companyDetails.length != 0">
        <div class="col-lg-8">
            <div class="w-100">
                <mat-card class="w-100 p-0">
                    <mat-tab-group mat-align-tabs="start" class="content">
                        <mat-tab label="Primary Information">
                            <table class="mat-elevation-z8 companies-table w-100">
                                <tr style="font-size: small;color: grey;">
                                    <td class="td">
                                        <mat-label>First Name</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Last Name</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Company Name</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Phone</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td">
                                        <mat-label>{{ companyDetails.firstName }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ companyDetails.lastName }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ companyDetails.companyName }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ companyDetails.phone }}</mat-label>
                                    </td>
                                </tr>
                                <br />
                                <tr style="font-size: small;color: grey;">
                                    <td class="td">
                                        <mat-label>Email</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Job Title</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td">
                                        <mat-label>{{ companyDetails.email }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ companyDetails.jobTitle }}</mat-label>
                                    </td>
                                </tr>
                            </table>
                        </mat-tab>
                        <mat-tab label="Classification">
                            <table class="mat-elevation-z8 companies-table w-100">
                                <tr style="font-size: small;color: grey;">
                                    <td class="td">
                                        <mat-label>Role</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Subsisdiary</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td">
                                        <mat-label>{{ companyDetails.role }}</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ companyDetails.subsisdiary }}</mat-label>
                                    </td>
                                </tr>
                            </table>
                        </mat-tab>
                        <mat-tab label="Address">
                            <table class="mat-elevation-z8 companies-table w-100">
                                <tr style="font-size: small;color: grey;">
                                    <td class="td">
                                        <mat-label>Address Line 1</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Address Line 2</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>City or Suburb</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>State</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td">
                                        <mat-label>{{ companyDetails.residentialAddress.addressLine1 }}
                                        </mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ companyDetails.residentialAddress.addressLine2 }}
                                        </mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ companyDetails.residentialAddress.city }}
                                        </mat-label>
                                    </td>

                                    <td>
                                        <mat-label>{{ StateName }}</mat-label>
                                    </td>
                                </tr>
                                <br />
                                <tr style="font-size: small;color: grey;">
                                    <td class="td">
                                        <mat-label>Post Code</mat-label>
                                    </td>
                                    <td>
                                        <mat-label>Country</mat-label>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="td">
                                        <mat-label>{{ companyDetails.residentialAddress.postCode }}
                                        </mat-label>
                                    </td>
                                    <td>
                                        <mat-label>{{ CountryName }}</mat-label>
                                    </td>
                                </tr>
                            </table>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card>
            </div>
            <mat-card class="mt-4">
                <div class="row mb-4">
                    <div class="col-md-12 text-right">
                        <div class="d-flex align-items-center justify-content-space-between flex-wrap">
                            <div class="mat-select-wrapper d-flex">
                                <button mat-stroked-button class="me-2 c-event-btn" [ngClass]="{'active-btn': view == 'Agenda','in-active-btn': view != 'Agenda'}">
                                        <div (click)="viewAgenda()" [class.active]="view == 'Agenda'">
                                            Agenda
                                        </div>
                                    </button>
                                <button mat-stroked-button class="me-2 c-event-btn" [ngClass]="{'active-btn': view === CalendarView.Day,'in-active-btn': view != CalendarView.Day}">
                                        <div (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                                            Day
                                        </div>
                                    </button>
                                <button mat-stroked-button class="me-2 c-event-btn" [ngClass]="{'active-btn': view === CalendarView.Week,'in-active-btn': view != CalendarView.Week}">
                                        <div (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                                            Week
                                        </div>
                                    </button>
                                <button mat-stroked-button class="me-2 c-event-btn" [ngClass]="{'active-btn': view === CalendarView.Month,'in-active-btn': view != CalendarView.Month}">
                                        <div (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                                            Month
                                        </div>
                                    </button>
                                <mat-form-field appearance="outline" class="w-100 me-2">
                                    <mat-icon matPrefix>search</mat-icon>
                                    <mat-label>Search</mat-label>
                                    <input matInput (keyup)="inputEvent($event)" [(ngModel)]="searchText" />
                                    <mat-icon matSuffix (click)="clear()">close</mat-icon>
                                </mat-form-field>
                            </div>
                            <div>
                                {{ events && events.length ? events.length : "0" }} Records Found
                                <!-- <button mat-stroked-button class="me-2 c-btn btn-success" (click)="viewFilterField()">
                                        <span class="material-icons me-2"> filter_list </span>Filter
                                    </button> -->
                                <button (click)="gotoActivities()" mat-raised-button class="custom-add-agenda-btn theme-bg me-2" mwlCalendarPreviousView [(viewDate)]="viewDate">
                                            <span class="material-icons add-btn">
                                                add
                                            </span>
                                            &nbsp;Add
                                        </button>
                                <button mat-raised-button color="primary" class="c-btn green-btn me-2" (click)="viewFilterField()">
                                        <!-- <span class="material-icons me-2"> filter_list </span> -->
                                        Filter
                                    </button>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center" *ngIf="showFilter">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Event Type</mat-label>
                            <mat-select [(ngModel)]="eventType" [ngModelOptions]="{
                standalone: true }">
                                <mat-option *ngFor="let obj of eventList" [value]="obj.value" (click)="filterTypeChange(obj)">
                                    {{ obj.value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Company Name</mat-label>
                            <mat-select [(ngModel)]="relatedcompanies" [ngModelOptions]="{
                standalone: true }">
                                <mat-option *ngFor="let obj of relatedCompanyList" [value]="obj.legalName" (click)="filterTypeChange(obj)">
                                    {{ obj.legalName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-md-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Transection Type</mat-label>
                                <mat-select [(ngModel)]="relatedTransaction" [ngModelOptions]="{ standalone: true }">
                                    <mat-option *ngFor="let obj of relatedContactList" [value]="obj.name" (click)="filterTypeChange(obj)">
                                        {{ obj.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Conatact Name</mat-label>
                            <mat-select [(ngModel)]="relatedContact" [ngModelOptions]="{
                standalone: true }" [disabled]="relatedContactList.length == 0">
                                <mat-option *ngFor="let obj of relatedContactList" [value]="obj.name" (click)="filterTypeChange(obj)">
                                    {{ obj.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-5"></div>
                    <div class="col-md-2">
                        <button mat-raised-button color="primary" class="c-btn green-btn
              w-100" (click)="clearSearch()">
              Clear All
            </button>
                    </div>
                    <div class="col-md-5"></div>
                </div>
                <div class="row text-center">
                    <div class="col-md-12">
                        <div class="col-md-4">
                            <div class="d-flex align-items-center pt-2 pb-4 flex-wrap" *ngIf="view != 'Agenda'">
                                <h3 class="m-0 d-flex align-items-center ">
                                    <span class="material-icons me-2 secondary-text"> date_range
                                        </span>{{ viewDate | calendarDate: view + "ViewTitle":"en" }}
                                </h3>
                                <div class="calendar-btn-group ms-4">
                                    <button mat-stroked-button mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                            <div class="material-icons">
                                                chevron_left
                                            </div>
                                        </button>
                                    <button mat-stroked-button mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                            <div class="material-icons">
                                                chevron_right
                                            </div>
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div [ngSwitch]="view">
                    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-month-view>
                    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-week-view>
                    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                    </mwl-calendar-day-view>
                    <!-- <div *ngSwitchCase="'Agenda'" class="agenda-div"> -->
                    <div *ngSwitchCase="'Agenda'">
                        <div class="no-content" *ngIf="events && events.length == 0">
                            <h4 class="primary-text c-h2">No Related Contacts Found!</h4>
                        </div>
                        <!-- <div *ngIf="agendaList.length> 0"> -->
                        <div *ngIf="events && events.length > 0">
                            <table mat-table [dataSource]="agendaList" class="mat-elevation-z8 companies-table w-100" matSort style="cursor:
                                            pointer">
                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Event Type
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.type || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="title">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Event Title
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.title || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="relatedCustomer">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Related Company
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.relatedCustomer || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="relatedTransaction">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Related Transaction
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.relatedTransaction || "-" }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="relatedContact">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Related Contact
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.relatedContact || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="startDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Start Date
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.createDate | date: "dd MMM YYYY" || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="startTime">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Start Time
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.startTime | date: "HH:mm" || "-" }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="endTime">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        End Time
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.endTime | date: "HH:mm" || "-" }}
                                    </td>
                                </ng-container>
                                <!-- <ng-container matColumnDef="phoneNumber">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Phone
                                                                </th>
                                                                <td mat-cell *matCellDef="let element">{{ element.statusId }}</td>
                                                            </ng-container> -->
                                <tr mat-header-row *matHeaderRowDef="agendaTableColumn"></tr>
                                <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->
                                <tr mat-row *matRowDef="let row; columns: agendaTableColumn; let element"></tr>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>

        <div class="col-lg-4">
            <mat-card class="related-contact-card h-100">
                <div class="d-flex justify-content-between align-items-center ">
                    <h1 class="primary-text card-heading m-0">
                        <b>Related Companies</b>
                    </h1>
                    <button mat-raised-button class="theme-bg c-btn" (click)="goToPage()">
            + Add Company
          </button>
                </div>
                <div class="mt-3">
                    <div class="col-lg-12">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-icon matPrefix>search</mat-icon>
                            <mat-label>Add Related Company</mat-label>
                            <input matInput (keyup)="searchParent($event)" [matAutocomplete]="auto" formControlName="parentType" />
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let obj of companyListSearch" [value]="obj.legalName" (click)="setSelectedcompany(obj)">
                                    {{ obj.legalName }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <div *ngIf="relatedCompany.length != 0">
                            <div class="d-flex justify-content-between align-items-center border-bottom pb-2 pt-4" style="cursor: pointer;" *ngFor="let item of relatedCompany" (click)="gotoCompany(item)">
                                <div>
                                    <h2 class="primary-text c-h2 m-0 mb-2">
                                        {{ item.legalName }}
                                    </h2>
                                    <p class="secondry-light-text">{{ item.stage }}</p>
                                </div>
                                <div>
                                    <button class="cc-btn">
                    <p class="secondry-light-text c-label">
                      {{ item.stageType }}
                    </p>
                  </button>
                                </div>
                            </div>
                            <div class="no-content" *ngIf="relatedCompany.length == 0">
                                <h2 class="primary-text c-h2">No Summry data</h2>
                                <p class="secondry-light-text">
                                    Please add data to see the summary
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <mat-card *ngIf="IsEditClick" class="p-0">
        <form [formGroup]="ContactForm" novalidate="novalidate">
            <div class="row">
                <div class="col-lg-12">
                    <div class="w-100" style="width: 100px">
                        <mat-tab-group mat-align-tabs="start" class="content example-headers-align">
                            <!-- #enddocregion align-start -->
                            <mat-tab label="Primary Information">
                                <div class="row" style="margin:10px">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>FirstName<span *ngIf="isRequired('firstName')" style="color: #e30513;">&nbsp;*</span
                        >
                      </mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="First Name"
                        formControlName="firstName"
                        maxlength="400"
                      />
                      <mat-error
                        *ngIf="
                          ContactForm.controls['firstName'].hasError(
                            'pattern'
                          ) || ContactForm.controls['firstName'].touched
                        "
                      >
                        Please enter valid firstname</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label
                        >LastName<span
                          *ngIf="isRequired('lastName')"
                          style="color: #e30513;"
                          >&nbsp;*</span
                        >
                      </mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="last Name"
                        formControlName="lastName"
                        maxlength="400"
                      />
                      <mat-error
                        *ngIf="
                          ContactForm.controls['lastName'].hasError(
                            'pattern'
                          ) || ContactForm.controls['lastName'].touched
                        "
                      >
                        Please enter valid lastname</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label
                        >Company Name
                        <span
                          *ngIf="isRequired('companyId')"
                          style="color: #e30513;"
                          >&nbsp;*</span
                        ></mat-label
                      >
                      <!-- <div
                        class="material-icons"
                        style="position: absolute;right: -7px;color: rgba(0,0,0,.54);"
                      >
                        arrow_drop_down
                      </div> -->
                      <!-- <input
                        matInput
                        (change)="CompanyDDLChange()"
                        (keyup)="searchCompany($event)"
                        [matAutocomplete]="auto"
                        formControlName="companyType"
                      />
                      <mat-autocomplete #auto="matAutocomplete">
                        <mat-option
                          *ngFor="let obj of companyListSearch"
                          [value]="obj.legalName"
                          (click)="setCompany(obj)"
                        >
                          {{ obj.legalName }}
                        </mat-option>
                      </mat-autocomplete> -->

                      <mat-select
                        (click)="clearCompanyDetails()"
                        formControlName="companyType"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <div style="padding:15px">
                          <input
                            type="text"
                            [(ngModel)]="searchdropdown"
                            matInput
                            (keyup)="searchCompany($event)"
                            (keydown)="$event.stopPropagation()"
                            formControlName="companyType"
                            autofocus
                            placeholder="Search"
                            autocomplete="off"
                          />
                        </div>

                        <mat-option
                          *ngFor="let obj of companyListSearch"
                          [value]="obj.legalName"
                          (click)="setCompany(obj)"
                        >
                          {{ obj.legalName }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          ContactForm.controls['companyId'].hasError(
                            'required'
                          ) || ContactForm.controls['companyId'].touched
                        "
                      >
                        This Field is required</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Phone </mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="phone"
                        formControlName="phone"
                        maxlength="10"
                        (keyup)="search($event, 'phone')"
                      />
                      <mat-error
                        *ngIf="
                          ContactForm.controls['phone'].hasError('pattern') ||
                          ContactForm.controls['phone'].touched
                        "
                      >
                        Allows only numbers.</mat-error
                      >
                      <mat-hint align="end"> {{ phoneLength }} / 10</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Email </mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="email"
                        formControlName="email"
                      />
                      <mat-error
                        *ngIf="
                          ContactForm.controls['email'].hasError('pattern') ||
                          ContactForm.controls['email'].touched
                        "
                      >
                        Please Enter valid E-mail</mat-error
                      >
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label> Job title </mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="job title"
                        formControlName="jobTitle"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Classification Information">
                <div class="row" style="margin:10px">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Role</mat-label>
                      <mat-select formControlName="roleId">
                        <mat-option
                          *ngFor="let obj of RoleList"
                          [value]="obj.id"
                        >
                          {{ obj.roleDescription }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label> Subsidiary</mat-label>
                      <mat-select formControlName="subsidiaryId">
                        <mat-option
                          *ngFor="let obj of SubsidiariesList"
                          [value]="obj.id"
                        >
                          {{ obj.subsidiaryDescription }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Address">
                <div class="row" style="margin:10px">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label> Address line1 </mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="addressLine1"
                        formControlName="addressLine1"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Address line2</mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="addressLine2"
                        formControlName="addressLine2"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>City or Suburb</mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="City"
                        formControlName="city"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>state</mat-label>
                      <mat-select formControlName="stateId">
                        <mat-option
                          *ngFor="let obj of StateList"
                          [value]="obj.id"
                        >
                          {{ obj.stateDescription }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Post Code</mat-label>
                      <input
                        type="text"
                        matInput
                        placeholder="postCode"
                        maxlength="5"
                        minlength="5"
                        formControlName="postCode"
                        (keyup)="search($event, 'postCode')"
                      />
                      <mat-error
                        *ngIf="
                          ContactForm.controls['postCode'].hasError(
                            'pattern'
                          ) || ContactForm.controls['lastName'].touched
                        "
                      >
                        Allows only numbers</mat-error
                      >
                      <mat-hint>{{ postCodeLength }} / 5</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label> Country</mat-label>
                      <mat-select formControlName="countryId">
                        <mat-option
                          *ngFor="let obj of CountryList"
                          [value]="obj.id"
                        >
                          {{ obj.countryDescription }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </form>

    <!-- <div align="center">
            <button mat-raised-button class="theme-bg c-btn" type="button" [disabled]="ContactForm.invalid"
                (click)="submitbtnClick()">Save</button>
        </div> -->
  </mat-card>
</div>