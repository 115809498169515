import { SpeedDialFabComponent } from './../../shared/speed-dial-fab/speed-dial-fab.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { AddCompanyComponent } from './../companies/add-company/add-company.component';
import { SubActivityComponent } from './../activity/sub-activity/sub-activity.component';
import { CompaniesDetailsComponent } from './../companies/companies-details/companies-details.component';
import { AddOpportunitiesComponent } from './../opportunities/add-opportunities/add-opportunities.component';
import { ContactlistComponent } from './../contactlist/contactlist.component';
import { AddQuotesComponent } from './../quotes/add-quotes/add-quotes.component';
import { LoginComponent } from './../login/login.component';
import { ActivityComponent } from './../activity/activity.component';
import { QuotesComponent } from './../quotes/quotes.component';
import { OpportunitiesComponent } from './../opportunities/opportunities.component';
import { ConactsComponent } from './../conacts/conacts.component';
import { CompaniesComponent } from './../companies/companies.component';
import { MatSelectModule } from '@angular/material/select';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { AddBookComponent } from './../dashboard/add-book/add-book.component';
import { DeleteContentComponent } from './../dashboard/delete-content/delete-content.component';
import { NgWizardModule } from 'ng-wizard';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { HeaderComponent } from 'src/app/shared/header/header.component';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgGridModule } from 'ag-grid-angular';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CalendarModule } from 'angular-calendar';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatSortModule } from '@angular/material/sort';
import { ActivityDetailsComponent } from '../activity/activity-details/activity-details.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SpeedDialFabComponent,
    LayoutComponent,
    MenuComponent,
    DashboardComponent,
    DeleteContentComponent,
    AddBookComponent,
    CompaniesComponent,
    ConactsComponent,
    OpportunitiesComponent,
    QuotesComponent,
    ActivityComponent,
    LoginComponent,
    AddQuotesComponent,
    ContactlistComponent,
    AddOpportunitiesComponent,
    CompaniesDetailsComponent,
    SubActivityComponent,
    AddCompanyComponent,
    HeaderComponent,
    ActivityDetailsComponent,
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatGridListModule,
    CommonModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatDividerModule,
    MatCardModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    HighchartsChartModule,
    AgGridModule.withComponents([]),
    HttpClientModule,
    NgWizardModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgApexchartsModule,
    CalendarModule,
    SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange' }),
    MatMomentDateModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
    MatToolbarModule,
    MatAutocompleteModule,
    SweetAlert2Module.forRoot(),
    NgxSpinnerModule,
    MatCheckboxModule,
    MatTimepickerModule,
    MatSortModule,
    MatTooltipModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule { }
