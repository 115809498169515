import { LayoutService } from 'src/app/features/layout/layout.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationExtras, Router } from '@angular/router';
import { Apiurl } from 'src/app/shared/route';
import { CommonProvider } from 'src/app/shared/common';
import { Subject } from 'rxjs';
import * as _ from 'underscore';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChartComponent } from 'ng-apexcharts';
import { MatSort } from '@angular/material/sort';

import {
  CalendarView,
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent
} from 'angular-calendar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarSchedulerViewComponent,
  SchedulerDateFormatter
} from 'angular-calendar-scheduler';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
interface eventTypeList {
  value: any;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: SchedulerDateFormatter
    }
  ]
})
export class DashboardComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  companyId: any;
  cardList: any = [];
  TodayDate: any = new Date().toDateString();
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: any;
  public chartOptions1: any;
  public chartOptions2: any;
  filterTypeList: any = [];
  selectedView: any = 'Agenda';
  //scheduler
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: any = 'Agenda';
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();
  showFilter: boolean = false;
  events: CalendarEvent[];
  filterType: any;
  activeDayIsOpen: boolean = true;
  searchInput: any;
  statusList: any = [];
  mainDataSouce: any = [];
  relatedCompanyList: any = [];
  relatedContactList: any = [];

  @ViewChild(CalendarSchedulerViewComponent)
  calendarScheduler: CalendarSchedulerViewComponent;

  displayedColumns: string[] = [
    'type',
    'title',
    'relatedCustomer',
    'relatedTransaction',
    'relatedContact',
    'createDate',
    'startTime',
    'endTime'
    // 'phoneNumber'
  ];
  eventList: eventTypeList[] = [
    { value: 'Event' },
    { value: 'Phone' },
    { value: 'Task' },
    { value: 'String' }
  ];
  dataSource: any;
  searchText: any = '';
  statusType: any;
  eventType: any;
  eventTitle: any;
  relatedCompany: any;
  relatedTransaction: any;
  relatedContact: any;

  constructor(
    private modal: NgbModal,
    public common: CommonProvider,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private layoutService: LayoutService
  ) {
    this.cardList = [
      {
        value: 0,
        name: 'New Leads',
        Image: '../../../assets/img/New_Lead.svg',
        url: 'companies'
      },
      {
        value: 0,
        name: 'New Prospects',
        Image: '../../../assets/img/New_Orispects.svg',
        url: 'contacts-list'
      },
      {
        value: 0,
        name: 'Total opportunities',
        Image: '../../../assets/img/Total_opp.svg',
        url: 'opportunities'
      },
      {
        value: 0,
        name: 'Total Quotes',
        Image: '../../../assets/img/Total_quotes.svg',
        url: 'quotes'
      }
    ];
    this.getCalenderEvent();
    this.loadLeadsLineChart();
    this.loadOpportunitiesChart();
    this.loadNewProspects();
    this.getAllCompanies();
    this.getContactList();
    this.getAllOpportunity();
    this.getAllQuotes();
  }

  getAllCompanies() {
    this.SpinnerService.show();
    this.common
      .GetMethod(Apiurl.getAllCompanies, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.relatedCompanyList = res;

          let Lead = [];
          let Prospect = [];
          this.SpinnerService.hide();
          _.filter(res, data => {
            if (
              data.stage == 'Lead' &&
              data.leadStatus == 'LEAD-Not Contacted'
            ) {
              Lead.push(data);
            } else if (
              data.stage == 'Prospect' &&
              data.leadStatus == 'PROSPECT-Negotiation/Review'
            ) {
              Prospect.push(data);
            }
          });
          this.cardList[0].value = Lead.length;
          this.cardList[1].value = Prospect.length;
        }
      })
      .catch((error: any) => {
        console.log(error);
        this.SpinnerService.hide();
      });
  }

  getContactList() {
    // this.SpinnerService.show();
    // this.common
    //   .GetMethod(Apiurl.getContactList, null, true, 'Loading')
    //   .then((res: any) => {
    //     if (res) {
    //       if (this.showFilter == true) {
    //         for (let i = 0; i < res.length; i++) {
    //           if (res[i].companyId == this.companyId) {
    //             this.relatedContactList.push(res[i]);
    //           }
    //         }
    //       }
    //       console.log(this.relatedContactList);
    //       this.SpinnerService.hide();
    //       // this.cardList[1].value = res.length;
    //     }
    //   });
  }

  getAllOpportunity() {
    this.common
      .GetMethod(Apiurl.getAllOpportunityList, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.SpinnerService.hide();
          this.cardList[2].value = res.length;
        }
      });
  }

  getAllQuotes() {
    this.SpinnerService.show();
    this.common
      .GetMethod(Apiurl.getAllQuotes, null, true, 'Loading')
      .then((res: any) => {
        if (res) {
          this.SpinnerService.hide();
          this.cardList[3].value = res.length;
        }
      });
  }

  ngOnInit(): void {
    this.filterTypeList = [
      {
        value: 1,
        name: 'Type'
      },
      {
        value: 1,
        name: 'Phone'
      },
      {
        value: 2,
        name: 'Event'
      },
      {
        value: 3,
        name: 'Task'
      },
      {
        value: 4,
        name: 'Note'
      }
    ];
    this.getStatusList();
  }

  loadLeadsLineChart() {
    this.chartOptions = {
      series: [
        {
          name: 'Desktops',
          data: [28, 34, 32, 40, 35, 28, 10, 32, 38, 22, 40, 40]
        }
      ],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'Leads',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep'
        ]
      }
    };
  }

  loadOpportunitiesChart() {
    this.chartOptions1 = {
      series: [
        {
          name: 'Desktops',
          data: []
        }
      ],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'Opportunities',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      yaxis: {
        floating: true
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep'
        ]
      }
    };
  }

  loadNewProspects() {
    this.chartOptions2 = {
      series: [
        {
          name: 'Desktops',
          data: [28, 34, 32, 40, 35, 28, 10, 32, 38, 22, 40, 40]
        }
      ],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'New Prospects',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep'
        ]
      }
    };
  }

  //scheduler start
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    this.showFilter = false;
  }
  //scheduler end

  viewAgenda() {
    this.view = 'Agenda';
  }

  viewFilterField() {
    this.showFilter = !this.showFilter;
  }

  filterTypeChange(event: any) {
    console.log(event);
    this.dataSource = new MatTableDataSource(this.mainDataSouce);
    this.dataSource.sort = this.sort;
    // console.log(this.dataSource.sort.matSortChange)
    // this.dataSource.sort.matSortChange;
    console.log(this.dataSource);
    if (this.searchText !== '') {
      this.dataSource.filter = this.searchText.trim().toLowerCase();
    }
    if (this.eventType) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, {
          type: this.eventType
        })
      );
    }
    if (this.relatedCompany) {
      this.companyId = event.id;
      console.log(event);
      this.getContactList();
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, {
          relatedCustomer: this.relatedCompany
        })
      );
    }
    if (this.relatedContact) {
      this.dataSource = new MatTableDataSource(
        _.filter(this.dataSource.filteredData, {
          relatedContact: this.relatedContact
        })
      );
    }
    this.dataSource.sort = this.sort;
  }

  getStatusList() {
    this.statusList = [];
    this.common
      .GetMethod(Apiurl.getAllLookup, null, true, 'Loading..')
      .then((res: any) => {
        if (res.length != 0) {
          this.statusList = res.activity.statuses;
        }
      });
  }
  getCalenderEvent() {
    this.common
      .GetMethod(Apiurl.getAllActivties, null, true, 'Loading..')
      .then((res: any) => {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
        // console.log(this.dataSource.sort.matSortChange)
        // this.dataSource.sort.matSortChange;
        this.mainDataSouce = res;
        this.events = [];
        for (let index = 0; index < res.length; index++) {
          let end: any;
          if (
            new Date(res[index].endDate).setHours(0, 0, 0, 0) ==
            new Date().setHours(0, 0, 0, 0)
          ) {
            end = null;
          } else {
            end = new Date(res[index].endDate);
          }
          this.events.push({
            start: new Date(res[index].startDate),
            end: end,
            title: res[index].title,
            color: colors.red,
            actions: this.actions,
            allDay: true,
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            draggable: true
          });
        }
      });
  }

  statusTypeChange(event: any) {}

  clearSearch() {
    this.searchInput = '';
    this.filterType = null;
    this.statusType = null;
    this.eventType = null;
    this.relatedCompany = null;
    this.relatedTransaction = null;
    this.relatedContact = null;
    this.dataSource = new MatTableDataSource(this.mainDataSouce);
    this.relatedContactList = [];
  }
  gotoActivities() {
    let param: NavigationExtras = {
      state: {
        type: 'Event',
        from: 'dashboard'
      }
    };
    this.router.navigate(['sub-activity'], param);
  }

  gotoPage(item: any) {
    let param: NavigationExtras = {
      state: {
        stage: item.name == 'New Leads' ? 'Lead' : 'Prospect',
        status:
          item.name == 'New Leads'
            ? 'LEAD-Not Contacted'
            : 'PROSPECT-Negotiation/Review',
        from: 'dashboard'
      }
    };
    console.log(item);
    if (item.url == 'contacts-list' || item.url == 'companies') {
      this.router.navigate(['companies'], param);
    } else {
      this.router.navigate([item.url]);
    }
    setTimeout(() => {
      this.layoutService.activeRoute = this.router.url;
      this.layoutService.getcurrentActivateRoute(
        this.layoutService.activeRoute
      );
    }, 50);
  }

  //searching bar
  inputEvent(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterTypeChange(filterValue);
  }

  clear() {
    this.searchText = '';
    this.getCalenderEvent();
  }
}
