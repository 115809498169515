import { LayoutService } from './../../layout/layout.service';
import { of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { STEP_STATE, NgWizardConfig, THEME, NgWizardService, StepChangedArgs, StepValidationArgs } from 'ng-wizard';
import { Route } from '@angular/compiler/src/core';
import { NavigationExtras, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-book',
  templateUrl: './add-book.component.html',
  styleUrls: ['./add-book.component.scss']
})
export class AddBookComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  model: any = {
    id: null,
    authorName: null,
    description: null,
    title: null,
    genres: null,
    synopsis: null,
    release_date: null
  }

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };

  //at the finish of wizard
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        {
          text: 'Finish', class: 'btn btn-info', event: () => {
            console.log(this.form)
            if (this.form.valid) {
              this.submitted = false;
              let bookData = JSON.stringify(this.form.value)
              let newBookAddData: NavigationExtras = {
                state: {
                  newBookAddData: bookData,
                }
              };
              this.router.navigate(['/'], newBookAddData)
              setTimeout(() => {
                this.layoutService.activeRoute = this.router.url
                this.layoutService.getcurrentActivateRoute(this.layoutService.activeRoute)
              }, 100);
            } else {
              this.submitted = true;
              return;
            }
          }
        }
      ],
    }
  };
  constructor(
    private ngWizardService: NgWizardService,
    private router: Router,
    private _formBuilder: FormBuilder,
    private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      authorName: [null, Validators.required],
      title: [null, Validators.required],
      genres: [null, Validators.required],
      synopsis: [null, Validators.required],
      release_date: [null, Validators.required]
    });
  }

  //wizard Options start
  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event) {
    this.ngWizardService.next();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {
    console.log(args.step);
  }

  isValidTypeBoolean: boolean = true;

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }
  //wizard Options end
}
