import { AddCompanyComponent } from './features/companies/add-company/add-company.component';
import { AddQuotesComponent } from './features/quotes/add-quotes/add-quotes.component';
import { LoginComponent } from './features/login/login.component';
import { ActivityComponent } from './features/activity/activity.component';
import { QuotesComponent } from './features/quotes/quotes.component';
import { OpportunitiesComponent } from './features/opportunities/opportunities.component';
import { ConactsComponent } from './features/conacts/conacts.component';
import { CompaniesComponent } from './features/companies/companies.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { LayoutComponent } from './features/layout/layout.component';
import { ContactlistComponent } from './features/contactlist/contactlist.component';
import { AddOpportunitiesComponent } from './features/opportunities/add-opportunities/add-opportunities.component';
import { CompaniesDetailsComponent } from './features/companies/companies-details/companies-details.component';
import { SubActivityComponent } from './features/activity/sub-activity/sub-activity.component';
import { ActivityDetailsComponent } from './features/activity/activity-details/activity-details.component';
const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'companies',
        component: CompaniesComponent
      },
      {
        path: 'contacts',
        component: ConactsComponent
      },
      {
        path: 'opportunities',
        component: OpportunitiesComponent
      },
      {
        path: 'quotes',
        component: QuotesComponent
      },
      {
        path: 'activities',
        component: ActivityComponent
      },
      {
        path: 'add-quotes',
        component: AddQuotesComponent
      },
      {
        path: 'contacts-list',
        component: ContactlistComponent
      },
      {
        path: 'add-opportunities',
        component: AddOpportunitiesComponent
      },
      {
        path: 'companies-details',
        component: CompaniesDetailsComponent
      },
      {
        path: 'add-company',
        component: AddCompanyComponent
      },
      {
        path: 'sub-activity',
        component: SubActivityComponent
      },
      {
        path: 'activity-details',
        component: ActivityDetailsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
